import videojs from 'video.js';

const SRGMenuButton = videojs.getComponent('SRGMenuButton');

/**
 * LetterBox Settings Button component
 *
 * @ignore
 */
class SettingsButton extends SRGMenuButton {
  constructor(player, options) {
    super(player, options);

    this.controlText('Edit player settings');
    this.el().setAttribute('aria-haspopup', 'true');
  }

  buildCSSClass() {
    return `srg-menu__settings-button ${super.buildCSSClass()}`;
  }
}

videojs.registerComponent('SettingsButton', SettingsButton);
export default SettingsButton;
