/* eslint class-methods-use-this: ["error", { "exceptMethods": ["updateState"] }] */
import videojs from 'video.js';
import * as Events from '../../../utils/Events.js';
import { ESCAPE } from '../../../utils/KeyCodes.js';
import Utils from '../../../utils/Utils.js';
import SupportedDevices from '../../../utils/SupportedDevices.js';
import SRGLetterboxConfiguration from '../../../utils/SRGLetterboxConfiguration.js';
import SRGMenuPanel from './srg-menu-panel.js';
import SRGMenuBackButton from './srg-menu-back-button.js';
import PlayerUtils from '../../../utils/PlayerUtils.js';

import './items/srg-menu-toggle-item.js';
import './items/srg-menu-list-item.js';
import './items/srg-menu-panel-list-item.js';

const Component = videojs.getComponent('Component');
const cssMenuOpen = 'srg-menu--open';

const PANEL_COMPONENTS_TYPES = {
  LIST: 'SRGMenuListItem',
  LIST_PANEL_ITEM: 'SRGMenuPanelListItem',
  TOGGLE: 'SRGMenuToggleItem',
};

/**
 * LetterBox SRGMenu component
 *
 * @ignore
 */
class SRGMenu extends Component {
  /**
   * @constructor
   *
   * @param {Object} options
   * @property {Object} options.panelContainerChildren
   *           Children to be injected into the panel container
   *
   */
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      children: SRGMenu.createPanelContainerComponent(options),
    }, options);

    super(player, opt);

    this.handleKeyboard = this.handleKeyboard.bind(this);
    this.userInactive = this.userInactive.bind(this);

    this.hide();
    this.hideFromAccessibility(true);

    this.overlay.on(Events.CLICK, (e) => {
      if (this.isContainerVisible()) {
        e.stopPropagation();
        this.hideContainer();
      }
    });

    this.player().on(Events.PLAY, () => {
      if (SupportedDevices.isTouchDevice
        && this.isContainerVisible()) {
        this.hideContainer();
      }
    });

    this.player().on([Events.CAN_PLAY, Events.CONFIGURATION_CHANGE], () => {
      this.buildMenu();
    });

    this.stack = [];
    this.mainPanel = null;
    this.backButton = null;
    this.widget = null;
  }


  /**
   *
   * @abstract
   */
  updateState() { }

  buildMenu() {
    this.updateState();
    this.state = Utils.removeUndefinedValuesFromObject(this.state);
    this.init(this.state);
  }

  dispose() {
    this.player().off(Events.USER_INACTIVE, this.userInactive);

    super.dispose();
  }

  init(state) {
    const children = {};
    this.stack = [];

    Object.keys(state).forEach((item) => {
      const itemConfiguration = SRGLetterboxConfiguration
        .getSettingConfiguration(this.player(), item);

      if (!state[item].disabled
        && !SRGLetterboxConfiguration.isConfigurationForced(itemConfiguration, item)) {
        children[item] = this.buildChildren(state[item]);
      }
    });

    if (Object.keys(state).length === 0
        || Object.keys(children).length === 0) {
      this.hide();
      return;
    }

    this.mainPanel = new SRGMenuPanel(this.player(), {
      children, isFirstLevelMenu: !!this.isFirstLevelMenu,
    });
    this.backButton = new SRGMenuBackButton(
      this.player(),
      { clickHandler: this.closePanel.bind(this) },
    );

    this.addPanel(this.mainPanel);
    this.show();
  }

  buildChildren(item) {
    const {
      type: componentClass, label, widget, disabled, ...options
    } = item;

    if (widget) {
      options.widget = this.buildChildren(widget);
    }

    const component = {
      componentClass,
      label: this.localize(label),
      handler: this,
      ...options,
    };
    return component;
  }

  /**
   * Adds a panel to the stack
   * @param {SRGMenuPanel} panel
   */
  addPanel(panel) {
    this.stack.push(panel);
    this.setPanel(panel);
  }

  /**
   * Closes the last opened panel
   */
  closePanel() {
    if (this.stack.length <= 1) {
      return;
    }
    // If a widget has previously been added by a subpanel, we need to remove it
    if (this.widget) {
      this.widget = null;
    }
    this.stack.pop();
    this.setPanel(this.stack[this.stack.length - 1]);
    this.setFocusToFirstElement();
  }

  /**
   * Displays the panel to the user
   * @param {SRGMenuPanel} panel
   */
  setPanel(panel) {
    this.removeChildren();

    if (this.stack.length > 1) {
      const header = this.createHeader();

      this.container.panels.addChild(header);

      header.addChild(this.backButton);
      // If a subpanel has a widget menu, we should display it
      if (this.widget) {
        const WidgetComponent = videojs.getComponent(this.widget.componentClass);
        this.widget = new WidgetComponent(this.player(), this.widget);
        header.addChild(this.widget);
      }
    }

    this.container.panels.addChild(panel);
  }

  /**
   * Closes all opened panels
   * Called when the menu is closed
   */
  closePanels() {
    while (this.stack.length > 1) {
      this.closePanel();
    }
  }

  /**
   * Removes all the children from the panel container
   */
  removeChildren() {
    videojs.dom.emptyEl(this.container.panels.el());
    this.container.panels.children().length = 0;
  }

  /**
 * Adds a widget to the menu
 * @param {*} widget component
 */
  addWidget(widget) {
    this.widget = widget;
  }

  buildCSSClass() {
    return `srg-menu vjs-control ${super.buildCSSClass()}`;
  }

  createEl() {
    const el = super.createEl('div', {
      className: this.buildCSSClass(),
    });

    return el;
  }

  createHeader() {
    return new Component(this.player(), {
      name: 'header',
      el: videojs.dom.createEl('div', {
        className: 'srg-menu__panel-header',
      }),
    });
  }

  /**
   * Hides the menu container and resets the inactivity timeout default value.
   */
  hideContainer() {
    this.off(Events.KEY_DOWN, this.handleKeyboard);
    this.player().off(Events.ON_ESC_PRESSED, this.handleKeyboard);
    this.removeClass(SRGMenu.CSS_MENU_OPEN);
    this.hideFromAccessibility(true);
    this.closePanels();

    PlayerUtils.reportUserActivity(this.player(), true);

    const sgrMenuButton = this.$('.srg-menu__button');

    if (sgrMenuButton) {
      sgrMenuButton.setAttribute('aria-expanded', 'false');
    }
  }

  /**
   * Hides or show the container from accessibility features
   * @param {Boolean} isHidden Defines if the container should be hidden from accessibility features
   */
  hideFromAccessibility(isHidden) {
    this.container.el().setAttribute('aria-hidden', isHidden);
  }

  /**
   * @returns {Boolean} true if the menu container is visible false otherwise.
   */
  isContainerVisible() {
    return this.hasClass(SRGMenu.CSS_MENU_OPEN);
  }

  /**
   * Shows the menu container and disable the inactivity timeout.
   * Pauses playback if the media was playing.
   *
   */
  showContainer() {
    this.on(Events.KEY_DOWN, this.handleKeyboard);
    this.player().on(Events.ON_ESC_PRESSED, this.handleKeyboard);

    this.addClass(SRGMenu.CSS_MENU_OPEN);
    this.hideFromAccessibility(false);

    this.player().on(Events.USER_INACTIVE, this.userInactive);
    this.setFocusToFirstElement();
  }

  /**
   * Handles keyboard strokes
   *
   * @param {Object} event
   */
  handleKeyboard(event) {
    if (event.keyCode === ESCAPE || event.type === Events.ON_ESC_PRESSED) {
      event.preventDefault();
      event.stopPropagation();
      if (this.stack.length <= 1) {
        this.hideContainer();
        this.el().querySelector('.srg-menu__button').focus();
      } else {
        this.closePanel();
      }
    }
  }

  /**
   * Sets the focus to thr first item
   */
  setFocusToFirstElement() {
    const nextFocusableItem = Utils.getKeyboardFocusableElements(this.container.panels.el());

    if (nextFocusableItem && nextFocusableItem.length > 0) {
      nextFocusableItem.filter(item => !item.classList.contains('srg-menu__subtitles-size-selector__item'))[0].focus();
    }
  }

  userInactive() {
    if (this.isContainerVisible()) {
      PlayerUtils.reportUserActivity(this.player(), false);
    }
  }

  /**
   * Create the "panel container" component that will include all menu panels
   *
   * @param {Object} options videojs options object
   */
  static createPanelContainerComponent() {
    return {
      container: {
        componentClass: 'Component',
        children: videojs.mergeOptions(
          { closeButton: { componentClass: 'SRGMenuCloseButton' } },
          {
            panels: {
              componentClass: 'Component',
              el: videojs.dom.createEl('div', {
                className: 'srg-menu__container__panels',
              }),
            },
          },
        ),
        el: videojs.dom.createEl('div', {
          className: 'srg-menu__container',
        }),
      },
      overlay: {
        componentClass: 'Component',
        el: videojs.dom.createEl('div', {
          className: 'srg-menu__overlay',
        }),
      },
    };
  }

  static get CSS_MENU_OPEN() {
    return cssMenuOpen;
  }

  static get PANEL_COMPONENTS_TYPES() {
    return PANEL_COMPONENTS_TYPES;
  }
}

videojs.registerComponent('SRGMenu', SRGMenu);
export default SRGMenu;
