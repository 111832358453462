import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 * LetterBox SRG Menu Back Button
 *
 * @ignore
 */
class SRGMenuBackButton extends Button {
  createEl() {
    const el = videojs.dom.createEl('div', {
      className: 'srg-menu__panel-back',
    });
    el.appendChild(super.createEl(undefined, {
      innerHTML: this.localize('Back'),
    }));

    return el;
  }

  buildCSSClass() {
    return `srg-menu__back-button ${super.buildCSSClass()}`;
  }
}

videojs.registerComponent('SRGMenuBackButton', SRGMenuBackButton);
export default SRGMenuBackButton;
