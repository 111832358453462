import videojs from 'video.js';
import SharingContainer from './sharing-container.js';
import SharingButton from './sharing-button.js';
import * as Events from '../../../utils/Events.js';
import SocialSharing from '../../../utils/SocialSharing.json';
import SRGStreamType from '../../../utils/SRGStreamType.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBoxWeb social sharing component
 * @ignore
 */
class SharingModule extends Component {
  constructor(player, options) {
    super(player, options);

    this.hide();

    this.on(player, Events.LOAD_START, () => {
      this.hide();

      if (!this.getChild('sharingContainer')) {
        this.addChild(new SharingButton(player, {
          name: 'sharingButton',
          controlText: this.localize('Share'),
          className: 'sharing-module__link',
          icon: 'share',
          onClick: this.handleClick.bind(this),
        }));

        this.addChild(new SharingContainer(player, {
          name: 'sharingContainer',
          parent: this,
        }));
      }
    });

    this.on(player, Events.FIRST_PLAY, () => {
      const mainChapter = this.player().options().SRGProviders.mediaComposition.getMainChapter();
      const { vendor } = mainChapter;
      const socialSharing = vendor && SocialSharing[vendor.toLowerCase()];
      const { isComponentVisible = true } = this.options();

      if (isComponentVisible && socialSharing) {
        this.show();

        const shouldHideOptions = !SRGStreamType.isOnDemand(
          this.player().currentSource().streamType,
        );

        this.getChild('sharingContainer').reset(socialSharing, shouldHideOptions);
      }
    });
  }

  createEl() {
    const el = super.createEl('div', {
      className: 'sharing-module',
    });

    return el;
  }

  handleClick(event) {
    event.preventDefault();
    const sharingContainer = this.getChild('sharingContainer');

    if (sharingContainer.hasClass('sharing-container--hidden')) {
      sharingContainer.show();
    } else {
      sharingContainer.hide();
    }
  }
}

Component.registerComponent('SharingModule', SharingModule);
export default SharingModule;
