import videojs from 'video.js';
import * as SRGEvents from '../../../../utils/SRGEvents.js';

const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 * LetterBoxWeb sharing button component
 * @ignore
 */
class SharingSocialAction extends ClickableComponent {
  constructor(player, options) {
    super(player, options);

    if (options.controlText) {
      this.controlText(options.controlText);
    }

    if (options.isHidden) {
      this.hide();
    }

    this.flashMessage = null;

    this.createButtonEl();
  }

  buildCSSClass() {
    const className = this.options().className ? `${this.options().className}` : '';
    return `${super.buildCSSClass()} sharing-social__action__link ${className} vjs-icon-${this.options().iconName}`;
  }

  createEl() {
    const el = super.createEl('div', {
      className: 'sharing-social__action',
    });

    return el;
  }

  createButtonEl() {
    const fragment = document.createDocumentFragment();

    fragment.appendChild(videojs.dom.createEl('a', {
      className: this.buildCSSClass(),
    }, {
      tabIndex: -1,
      target: '_blank',
      rel: 'noopener',
      href: '#',
    }));

    fragment.appendChild(videojs.dom.createEl('span', {
      className: 'sharing-social__action__title',
      innerHTML: this.options().caption,
    }));

    this.el().appendChild(fragment);
  }

  triggerAnalytics() {
    const { bu } = this.player().currentSource();
    const {
      eventData,
      id,
      mediaType,
    } = this.options().socialSharingStrategy.getSharedMedia();

    this.player().trigger({
      type: SRGEvents.SOCIAL_SHARING,
      data: {
        socialVendor: this.name(),
        bu,
        eventData,
        id,
        mediaType,
      },
    });
  }

  triggerAction() {
    return window.open(
      this.options().href(),
      '_blank',
      `toolbar=0,
      location = 0,
      status = 0,
      menubar = 0,
      scrollbars = 1,
      resizable = 0,
      width = 550px,
      height = 420px`,
    );
  }

  triggerFlash() {
    this.$('.sharing-social__action__title').textContent = this.localize(this.flashMessage);
    this.$('.sharing-social__action__link').classList.add('sharing-social__action__link--clicked');

    this.setTimeout(() => {
      this.$('.sharing-social__action__title').textContent = this.options().caption;
      this.$('.sharing-social__action__link').classList.remove('sharing-social__action__link--clicked');
    }, 750);
  }

  handleClick(e) {
    e.preventDefault();

    this.triggerAnalytics();

    this.triggerAction();

    if (this.flashMessage) {
      this.triggerFlash();
    }
  }
}

videojs.registerComponent('SharingSocialAction', SharingSocialAction);
export default SharingSocialAction;
