// TODO deserves an improvement, I'm not sure if it's the correct way to handle the error...
import translationKeys from '../lang/en.json';

const ERRORTYPE = {
  ERROR_DRM_NOT_SUPPORTED_MESSAGE: {
    code: 5,
    type: 'ERROR_DRM_NOT_SUPPORTED_MESSAGE',
    hasLink: true,
    message: translationKeys['Unable to display the content. For more information and help, click here...'],
    iconClass: 'vjs-icon-error',
  },
  '360_UNSUPPORTED': {
    code: 7,
    type: '360_UNSUPPORTED',
    message: translationKeys['Unfortunately the 360 playback experience is not compatible with your browser version.'],
    iconClass: 'vjs-icon-error',
  },
  ERROR_INCOMPATIBLE_BROWSER_MESSAGE: {
    code: 8,
    type: 'ERROR_INCOMPATIBLE_BROWSER_MESSAGE',
    message: translationKeys['Unfortunately this format is not compatible with your browser.'],
    iconClass: 'vjs-icon-error',
  },
  ERROR_BLOCKING_REASON_STARTDATE: {
    code: 9,
    type: 'ERROR_BLOCKING_REASON_STARTDATE',
    message: translationKeys['This content is not yet available. Please try again later.'],
    iconClass: 'vjs-icon-clock',
  },
  ERROR_BLOCKING_REASON_ENDDATE: {
    code: 10,
    type: 'ERROR_BLOCKING_REASON_ENDDATE',
    message: translationKeys['This content is not available anymore.'],
    iconClass: 'vjs-icon-clock',
  },
  ERROR_BLOCKING_REASON_LEGAL: {
    code: 11,
    type: 'ERROR_BLOCKING_REASON_LEGAL',
    message: translationKeys['This content is not available due to legal restrictions.'],
    iconClass: 'vjs-icon-legal',
  },
  ERROR_BLOCKING_REASON_COMMERCIAL: {
    code: 12,
    type: 'ERROR_BLOCKING_REASON_COMMERCIAL',
    message: translationKeys['This commercial media is not available.'],
    iconClass: 'vjs-icon-legal',
  },
  ERROR_BLOCKING_REASON_GEOBLOCK: {
    code: 13,
    type: 'ERROR_BLOCKING_REASON_GEOBLOCK',
    message: translationKeys['This content is not available outside Switzerland.'],
    iconClass: 'vjs-icon-out-of-switzerland',
  },
  ERROR_BLOCKING_REASON_AGERATING18: {
    code: 14,
    type: 'ERROR_BLOCKING_REASON_AGERATING18',
    message: translationKeys['To protect children, this content is only available between 11PM and 5AM.'],
    iconClass: 'vjs-icon-age-rating',
  },
  ERROR_BLOCKING_REASON_AGERATING12: {
    code: 15,
    type: 'ERROR_BLOCKING_REASON_AGERATING12',
    message: translationKeys['To protect children, this content is only available between 8PM and 6AM.'],
    iconClass: 'vjs-icon-age-rating',
  },
  ERROR_BLOCKING_REASON_UNKNOWN: {
    code: 16,
    type: 'ERROR_BLOCKING_REASON_UNKNOWN',
    message: translationKeys['This content is not available.'],
    iconClass: 'vjs-icon-error',
  },
  IL_ERROR: {
    code: 17,
    type: 'IL_ERROR',
    message: translationKeys['The content cannot be played.'],
    iconClass: 'vjs-icon-network',
  },
  MEDIACOMPOSITION_CHANGE_KILLSWITCH: {
    code: 18,
    type: 'KILLSWITCH',
    message: translationKeys['Unknown error'],
    iconClass: 'vjs-icon-error',
  },
  ERROR_UNKNOWN: {
    code: 19,
    type: 'ERROR_UNKNOWN',
    message: translationKeys['The content cannot be played.'],
    iconClass: 'vjs-icon-error',
  },
  ERROR_BLOCKING_REASON_JOURNALISTIC: {
    code: 20,
    type: 'ERROR_BLOCKING_REASON_JOURNALISTIC',
    message: translationKeys['This content is temporarily unavailable for journalistic reasons.'],
    iconClass: 'vjs-icon-error',
  },
};

export default ERRORTYPE;
