import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 * LetterBox SRG Menu Close Button
 *
 * @ignore
 */
class SRGMenuCloseButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.controlText('Close');
  }

  buildCSSClass() {
    return `srg-menu__close-button vjs-icon-close ${super.buildCSSClass()}`;
  }

  handleClick() {
    this.SRGMenuComponent.hideContainer();
  }

  get SRGMenuComponent() {
    // eslint-disable-next-line no-underscore-dangle
    if (!this.parentComponent_ || !this.parentComponent_.parentComponent_) {
      return null;
    }

    // eslint-disable-next-line no-underscore-dangle
    return this.parentComponent_.parentComponent_;
  }
}

videojs.registerComponent('SRGMenuCloseButton', SRGMenuCloseButton);
export default SRGMenuCloseButton;
