import videojs from 'video.js';
import './subdivisions-container-arrow-left.js';
import './subdivisions-container-arrow-right.js';
import './subdivisions-list.js';
import * as Events from '../../../utils/Events.js';
import SupportedDevices from '../../../utils/SupportedDevices.js';

const Component = videojs.getComponent('Component');
const SHOW_SUBDIVISIONS_MIN_HEIGHT = 280;

/**
 *
 * @ignore
 */
class SubdivisionsContainer extends Component {
  constructor(player, options) {
    super(player, options);
    this.hide();

    this.init(options);

    this.on(player,
      [
        Events.LOAD_START,
        Events.MEDIACOMPOSITION_LOADED,
      ],
      this.update.bind(this));
    this.on(player, Events.ERROR, this.hide.bind(this));
    this.on(player, Events.PLAYER_RESIZE, this.updateVisibility.bind(this));

    if (SupportedDevices.isTouchDevice) {
      this.on(this.player(), Events.USER_ACTIVE, this.updateVisibility.bind(this));

      this.on(Events.TRANSITION_END, () => {
        if (!this.player().userActive()) {
          this.hide();
        }
      });
    }
  }

  init(options) {
    videojs.dom.emptyEl(this.el());

    this.initNavigation(options);

    this.on(Events.MOUSE_ENTER, () => {
      this.player().getCache().inactivityTimeout = this.player().options().inactivityTimeout;
      this.player().options().inactivityTimeout = 0;

      this.player().addClass('srgssr-subdivisions--hover');
    });

    this.on(Events.MOUSE_LEAVE, () => {
      this.player().options().inactivityTimeout = this.player().getCache().inactivityTimeout;

      this.player().removeClass('srgssr-subdivisions--hover');
    });
  }

  isHidden() {
    return this.hasClass('vjs-hidden');
  }

  updateVisibility() {
    const { isComponentVisible = true } = this.options();

    if (isComponentVisible
      && this.currentSubdivisions
      && this.currentSubdivisions.length
      && !this.player().error()
      && this.player().el().offsetHeight >= SHOW_SUBDIVISIONS_MIN_HEIGHT
      && !this.player().hasClass('chromecast--connected')) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    if (!this.hasSubdivisions) return;

    this.player().addClass('srgssr-has-subdivisions');
    super.show();

    this.triggerVisibilityChanged();
  }

  hide() {
    this.player().removeClass('srgssr-has-subdivisions');
    super.hide();

    this.triggerVisibilityChanged();
  }

  update() {
    const { mediaComposition } = this.player().options().SRGProviders;
    const subdivisions = mediaComposition && mediaComposition.getSubdivisions();

    this.hasSubdivisions = !!subdivisions.length;

    if (!this.subdivisionsChanged(subdivisions)) return;

    this.currentSubdivisions = subdivisions;

    if (!subdivisions.length) {
      this.hide();

      return;
    }

    this.list.setSubdivisions(this.player(), { subdivisions });
    this.updateVisibility();
    this.updateArrows();
  }

  subdivisionsChanged(subdivisions) {
    const s1 = SubdivisionsContainer.subdivisionString(subdivisions);
    const s2 = SubdivisionsContainer.subdivisionString(this.currentSubdivisions);
    return s1 !== s2;
  }

  static subdivisionString(subdivisions) {
    return subdivisions && subdivisions.reduce((str, s) => str + s.urn + s.markIn + s.markOut + s.title + s.imageUrl, '');
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-subdivisions`;
  }

  createEl() {
    return super.createEl('div', {
      className: this.buildCSSClass(),
    },
    {
      'aria-label': this.localize('Chapters'),
      role: 'navigation',
    });
  }

  initNavigation(options) {
    this.list = this.addChild('SubdivisionsList', options);
    this.arrowLeft = this.addChild('SubdivisionsContainerArrowLeft');
    this.arrowRight = this.addChild('SubdivisionsContainerArrowRight');

    this.arrowLeft.on([Events.CLICK, Events.TAP], this.list.scrollLeft.bind(this.list));
    this.arrowRight.on([Events.CLICK, Events.TAP], this.list.scrollRight.bind(this.list));

    this.list.on(Events.SCROLL, this.updateArrows.bind(this));

    this.player().on(
      Events.PLAYER_RESIZE,
      this.updateArrows.bind(this),
    );
  }

  triggerVisibilityChanged() {
    // Avoid triggering the event when subdivisions are displayed above the control bar
    if (this.player().isFullscreen()) { return; }

    this.player().trigger({
      type: Events.SUBDIVISION_VISIBILITY_CHANGED,
      data: {
        hasSubdivisions: this.hasSubdivisions,
        isVisible: !this.isHidden(),
        subdivisionsViewHeight: !this.hasSubdivisions ? 0 : this.currentHeight(),
      },
    });
  }

  updateArrows() {
    if (this.list.canGoLeft()) {
      this.arrowLeft.enable();
    } else {
      this.arrowLeft.disable();
    }

    if (this.list.canGoRight()) {
      this.arrowRight.enable();
    } else {
      this.arrowRight.disable();
    }
  }
}

videojs.registerComponent('SubdivisionsContainer', SubdivisionsContainer);
export default SubdivisionsContainer;
