import videojs from 'video.js';
import * as SRGEvents from '../../utils/SRGEvents.js';

const Component = videojs.getComponent('Component');
const DIGIT_NAME = ['Days', 'Hours', 'Minutes', 'Seconds'];
const PERIOD = 1000;
const DAY = 0;
const HOUR = 1;
const MINUTE = 2;
const SECOND = 3;

/** In the last 5 seconds, display 00:00 instead of the text to prevent text glitch. */
const ALMOST_STARTING = -5000;

/**
 * Countdown UI to be displayed when stream is not yet playable.
 * This can be used as error content child.
 * @ignore
 */
class CountDown extends Component {
  constructor(player, options) {
    super(player, options);

    this.request = {};

    this.setTimeout(this.periodicCall.bind(this), PERIOD);

    player.on(SRGEvents.MEDIACOMPOSITION_LOADED, this.updateStartDate.bind(this));
    this.updateStartDate();
  }

  static formatDigit(number) {
    return number < 10 ? `0${number}` : number;
  }

  static splitNumber(number) {
    return number.toString().split('');
  }

  static bindElement(element, value) {
    const splitedValue = CountDown.splitNumber(CountDown.formatDigit(value));
    element.querySelectorAll('.digit').forEach((digit, i) => {
      // eslint-disable-next-line no-param-reassign
      digit.textContent = splitedValue[i];
    });
  }

  static addClass(array, cssClass) {
    array.forEach((el) => {
      el.classList.add(cssClass);
    });
  }

  static removeClass(array, cssClass) {
    array.forEach((el) => {
      el.classList.remove(cssClass);
    });
  }

  createSeparators() {
    const fragment = document.createDocumentFragment();
    this.separators = [];

    for (let i = 0; i < 3; i++) {
      this.separators[i] = fragment.appendChild(videojs.dom.createEl('div', {
        className: `separator-${i + 1}`,
        innerHTML: ':',
      }));
    }
    return fragment;
  }

  createTexts() {
    const fragment = document.createDocumentFragment();
    this.texts = [];

    for (let i = 0; i < DIGIT_NAME.length; i++) {
      this.texts[i] = fragment.appendChild(videojs.dom.createEl('div', {
        className: `text text-${DIGIT_NAME[i].toLowerCase()} flexCentered`,
        innerHTML: this.localize(`${DIGIT_NAME[i]}`),
      }));
    }
    return fragment;
  }

  createDigits() {
    const fragment = document.createDocumentFragment();
    this.digits = [];

    for (let i = 0; i < DIGIT_NAME.length; i++) {
      this.digits[i] = fragment.appendChild(videojs.dom.createEl('div', {
        className: `${DIGIT_NAME[i].toLowerCase()} flexCentered`,
        innerHTML: '<div class="digit">0</div><div class="digit">0</div>',
      }));
    }
    return fragment;
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-countdown`;
  }

  createEl() {
    const el = super.createEl('div', {
      className: this.buildCSSClass(),
    });

    const container = super.createEl('div', {
      className: 'timer',
    });

    const fragment = document.createDocumentFragment();
    fragment.appendChild(this.createTexts());
    fragment.appendChild(this.createSeparators());
    fragment.appendChild(this.createDigits());
    container.appendChild(fragment);
    el.appendChild(container);
    this.description = el.appendChild(videojs.dom.createEl('div', {
      className: 'description',
    }));

    return el;
  }

  periodicCall() {
    this.updateContent();
    this.setTimeout(this.periodicCall.bind(this), PERIOD);
  }

  showDuration() {
    const duration = this.startDate - Date.now();
    const displayDuration = (duration < 0 && duration >= ALMOST_STARTING) ? 0 : duration;
    const s = displayDuration / 1000;
    const m = s / 60;
    const h = m / 60;
    const d = h / 24;
    if (displayDuration < 0) {
      CountDown.addClass([...this.digits, ...this.texts, ...this.separators], 'invisible');
      CountDown.removeClass([this.description], 'invisible');
      this.description.textContent = this.localize('Playback will begin shortly');
    } else if (d >= 100) {
      CountDown.addClass([...this.digits, ...this.texts, ...this.separators], 'invisible');
      CountDown.removeClass([this.description], 'invisible');
      this.description.textContent = this.localize('Content will be available in {1} days', [d | 0]);
    } else {
      CountDown.removeClass([...this.digits, ...this.texts, ...this.separators], 'invisible');
      CountDown.addClass([this.description], 'invisible');
      if (d >= 1) {
        CountDown.bindElement(this.digits[DAY], d | 0);
        CountDown.removeClass([this.digits[DAY], this.separators[0], this.texts[DAY]], 'invisible');
      } else {
        CountDown.addClass([this.digits[DAY], this.separators[0], this.texts[DAY]], 'invisible');
      }
      if (h >= 1 || d >= 1) {
        CountDown.bindElement(this.digits[HOUR], h % 24);
        CountDown.removeClass([this.digits[HOUR], this.separators[1], this.texts[HOUR]], 'invisible');
      } else {
        CountDown.addClass([this.digits[HOUR], this.separators[1], this.texts[HOUR]], 'invisible');
      }
      CountDown.bindElement(this.digits[MINUTE], m % 60);
      CountDown.bindElement(this.digits[SECOND], s % 60);
    }
  }

  updateContent() {
    this.showDuration();
  }

  updateStartDate() {
    const { mediaComposition } = this.player().options().SRGProviders;

    this.startDate = mediaComposition.getMainValidFromDate().getTime();

    this.updateContent();
  }
}

videojs.registerComponent('CountDown', CountDown);
export default CountDown;
