const SRGLetterboxComponentsPath = {
  audioDescription: ['controlBar', 'subtitlesMenu', 'container', 'panels', 'panel', 'audioDescription'],
  controlBar: ['ControlBar'],
  header: ['HeaderComponent'],
  sharing: ['HeaderComponent', 'SharingModule'],
  title: ['HeaderComponent', 'TitleComponent'],
  playButton: ['ControlBar', 'SRGSSRButtonComponent'],
  skipCredits: ['SkipCredits'],
  subdivisions: ['SubdivisionsContainer'],
  thumbnails: ['ControlBar', 'ProgressControl', 'ThumbnailSeeking'],
};

export default SRGLetterboxComponentsPath;
