export const AUDIO_TRACK_LANGUAGE = 'audioTrackLanguage';
export const AUTOPLAY = 'autoplay';
export const CONTINUOUS_PLAYBACK = 'continuousPlayback';
export const DEBUG = 'debug';
export const HD = 'hd';
export const MUTED = 'muted';
export const PLAYBACK_RATE = 'playbackRate';
export const TAG_COMMANDER_SCRIPT_URL = 'tagCommanderScriptURL';
export const TEXT_TRACK_LANGUAGE = 'textTrackLanguage';
export const TEXT_TRACK_SIZE_LEVEL = 'textTrackSizeLevel';
export const VOLUME = 'volume';
export const WARNING_MESSAGE_TIMEOUT = 'warningMessageTimeout';
