import Bowser from 'bowser';

class SupportedDevices {
  static bowser(userAgent = navigator.userAgent) {
    if (!SupportedDevices.bowserInstance || SupportedDevices.bowserInstance.getUA() !== userAgent) {
      SupportedDevices.bowserInstance = Bowser.getParser(userAgent);
    }

    return SupportedDevices.bowserInstance;
  }

  /**
   * Check if DRM playback is supported on iOS devices.
   *
   * @returns {Boolean}
   */
  static isIOSSupportingDRM() {
    return SupportedDevices.bowser().isOS(Bowser.OS_MAP.iOS)
      && SupportedDevices.bowser().getOSVersion() >= '11.2'
      && SupportedDevices.bowser().satisfies({
        edge: '>=41.1',
        safari: '>=11',
      });
  }

  /**
   * Check if DRM playback is supported.
   *
   * @returns {Boolean}
   */
  static isDRMSupported() {
    const { bowser, isIOSSupportingDRM } = SupportedDevices;
    const browsersSupportingDRM = {
      android: {
        chrome: '>34',
        edge: '>=41.1',
        firefox: '>=90.0',
      },
      [Bowser.OS_MAP.ChromeOS]: {
        chrome: '>34',
      },
      linux: {
        chrome: '>34',
        firefox: '>47',
        opera: '>=77',
      },
      macos: {
        chrome: '>=34',
        edge: '>=79',
        firefox: '>47',
        opera: '>=44',
        safari: '>=8',
      },
      windows: {
        chrome: '>=34',
        edge: '>=17',
        firefox: '>47',
        opera: '>=44',
      },
    };


    return isIOSSupportingDRM()
      || (bowser().satisfies(browsersSupportingDRM) || false);
  }

  static isBrowserOutdated() {
    return !('URLSearchParams' in window);
  }

  static get isChrome() {
    return SupportedDevices.bowser().isBrowser(Bowser.BROWSER_MAP.chrome);
  }

  static get isChromium() {
    return SupportedDevices.bowser().isBrowser(Bowser.BROWSER_MAP.chromium);
  }

  static get isEdge() {
    return SupportedDevices.bowser().isBrowser(Bowser.BROWSER_MAP.edge);
  }

  static get isOpera() {
    return SupportedDevices.bowser().isBrowser(Bowser.BROWSER_MAP.opera);
  }

  static get isSafari() {
    return SupportedDevices.bowser().isBrowser(Bowser.BROWSER_MAP.safari);
  }

  static get isTouchDevice() {
    return SupportedDevices.bowser().getPlatformType() !== Bowser.PLATFORMS_MAP.desktop;
  }
}

export default SupportedDevices;
