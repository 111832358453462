import SupportedDevices from '../../utils/SupportedDevices.js';
import * as SRGEvents from '../../utils/SRGEvents.js';

// https://gist.github.com/guerrerocarlos/3aca64069853d8d24a83b481246f23ca
/*
* https://developers.google.com/cast/docs/chrome_sender/integrate
 * https://developers.google.com/cast/docs/reference/chrome/cast.framework.RemotePlayerController
 * https://developers.google.com/cast/docs/reference/chrome/cast.framework.html#.RemotePlayerEventType
 *
 */
class ChromecastLoader {
  constructor(player, receiverApplicationId = '1AC2931D') {
    if (SupportedDevices.isChrome || SupportedDevices.isChromium) {
      this.localPlayer = player;
      // ? try to reconnect to a previous session if connection is lost
      // chrome.cast.requestSessionById("b344eb7c-6da4-4c0c-b4b0-96974ce0983f")
      this.receiverApplicationId = receiverApplicationId;
      this.loadSenderFramework();
    }
  }

  static getCurrentSession() {
    const { cast } = window;

    return cast.framework.CastContext.getInstance().getCurrentSession();
  }

  initializeCastApi() {
    const { cast: { framework }, chrome } = window;

    this.castFramework = framework;
    this.castContext = this.castFramework.CastContext.getInstance();
    this.castContextEventType = this.castFramework.CastContextEventType;
    this.sessionState = framework.SessionState;

    this.castContext.setOptions({
      receiverApplicationId: this.receiverApplicationId,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
  }

  loadSenderFramework() {
    const scriptUrl = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
    const scriptId = 'chromecastSender';
    const existingScript = document.getElementById(scriptId);

    // eslint-disable-next-line no-underscore-dangle
    window.__onGCastApiAvailable = (isAvailable) => {
      if (isAvailable) {
        this.initializeCastApi();
        this.localPlayer.trigger(SRGEvents.CHROMECAST_SCRIPT_LOADED);
      }
    };

    if (!existingScript) {
      const script = document.createElement('script');

      script.src = scriptUrl;
      script.id = scriptId;
      script.async = true;
      script.defer = 'defer';
      script.type = 'text/javascript';

      document.body.appendChild(script);
    } else {
      // Allows time for the player's component to build up
      setTimeout(() => {
        // Loaded manually when the player has been disposed but the script is already loaded
        // eslint-disable-next-line no-underscore-dangle
        window.__onGCastApiAvailable(true);
      }, 0);
    }
  }
}

export default ChromecastLoader;
