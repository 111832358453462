import videojs from 'video.js';
import * as SRGEvents from '../../../utils/SRGEvents.js';

const Button = videojs.getComponent('Button');

/**
 *
 * @ignore
 */
class CancelButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.show();
  }

  buildCSSClass() {
    return `srg-cancel-button ${super.buildCSSClass()}`;
  }

  /**
   * The click allow to :
   * - Cancel continuous playback
   * - Display recommendation grid
   *
   * @fires SRGEvents.CONTINUOUSPLAYBACK_CANCEL
   */
  handleClick() {
    this.trigger(SRGEvents.CONTINUOUSPLAYBACK_CANCEL);
  }

  createEl() {
    return super.createEl('button', {
      className: this.buildCSSClass(),
      innerHTML: this.localize('Cancel'),
    });
  }
}

videojs.registerComponent('CancelButton', CancelButton);
export default CancelButton;
