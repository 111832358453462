import videojs from 'video.js';
import SRGMenu from '../srg-menu.js';
import * as Events from '../../../../utils/Events.js';
import SRGStreamType from '../../../../utils/SRGStreamType.js';
import './items/list-scroll-click-item.js';

/**
 * LetterBox Playback Rate Menu component
 *
 * @ignore
 */
class PlaybackRateMenu extends SRGMenu {
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      children: {
        playbackRateButton: true,
      },
    }, options);

    super(player, opt);

    this.state = {
      playbackRate: {},
    };
  }

  /**
   *@override
   */
  updateState() {
    this.state.playbackRate = this.buildPlaybackRate();
  }

  buildPlaybackRate() {
    const items = this.player()
      .options()
      .playbackRates
      .map(rate => ({ label: `${rate.toFixed(1)} x`, value: rate }));

    return {
      label: 'Speed',
      type: 'ListScrollClickItem',
      disabled: !SRGStreamType.isOnDemand(this.player().currentSource().streamType),
      items,
      value: () => this.player().playbackRate(),
      changeEvent: Events.RATE_CHANGE,
      scrollClickComponent: this.playbackRateButton,
    };
  }

  buildCSSClass() {
    return `playback-rate-menu ${super.buildCSSClass()}`;
  }
}

videojs.registerComponent('PlaybackRateMenu', PlaybackRateMenu);
export default PlaybackRateMenu;
