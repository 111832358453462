import videojs from 'video.js';
import * as SRGEvents from '../../../utils/SRGEvents.js';

const Button = videojs.getComponent('Button');

/**
 * Circular Countdown UI to be displayed when continuous playback is enabled.
 * @ignore
 */
class CircularCountDown extends Button {
  constructor(player, options) {
    super(player, options);

    this.duration = options.duration;

    this.timeout = null;
  }

  /**
   * Starts the countdown
   * @fires SRGEvents.CONTINUOUSPLAYBACK_DONE
   *
   * @returns {undefined}
   */
  start() {
    const timeoutInMilliSeconds = this.duration * 1000;

    this.timeout = this.setTimeout(() => {
      this.trigger(SRGEvents.CONTINUOUSPLAYBACK_DONE);
    }, timeoutInMilliSeconds);

    this.el()
      .querySelector('.circle-red')
      .style.animation = `countdown ${this.duration}s linear forwards`;
  }

  /**
   * Stops and resets the countdown
   *
   * @returns {undefined}
   */
  reset() {
    this.clearTimeout(this.timeout);

    this.el().querySelector('.circle-red').style.animation = 'none';
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-circular-countdown`;
  }

  createEl() {
    const el = super.createEl('button', {
      className: this.buildCSSClass(),
    });

    el.appendChild(CircularCountDown.createCountDownCircleSVG());

    return el;
  }

  handleClick() {
    this.trigger(SRGEvents.CONTINUOUSPLAYBACK_DONE);
  }

  static createCountDownCircleSVG() {
    const fragment = document.createDocumentFragment();

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.classList.add('circle-countdown');
    svg.setAttribute('viewBox', '0 0 100 100');

    const cir1 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    cir1.classList.add('circle-white');
    cir1.setAttribute('cx', '25');
    cir1.setAttribute('cy', '25');
    cir1.setAttribute('r', '23');

    const cir2 = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    cir2.classList.add('circle-red');
    cir2.setAttribute('cx', '25');
    cir2.setAttribute('cy', '25');
    cir2.setAttribute('r', '23');

    const polyline = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
    polyline.classList.add('circle-play');
    polyline.setAttribute('fill', 'white');
    polyline.setAttribute('points', '14 10, 14 28, 29 19');

    fragment.appendChild(cir1);
    fragment.appendChild(cir2);
    fragment.appendChild(polyline);

    svg.appendChild(fragment);

    return svg;
  }
}

videojs.registerComponent('CircularCountDown', CircularCountDown);
export default CircularCountDown;
