import videojs from 'video.js';
import * as PlayerEvents from '../../utils/PlayerEvents.js';
import MediaShare from '../../utils/MediaShare.js';

const Button = videojs.getComponent('Button');

/**
 * BU Button, give access to a BU website directly
 * with the current media played
 * @ignore
 */
class SRGSSRButtonComponent extends Button {
  constructor(player, options) {
    super(player, options);
    this.update = this.update.bind(this);
    this.update();

    this.player().on(PlayerEvents.LOAD_START, this.update);
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} vjs-srgssr-button vjs-srgssr-${this.selectedBU}`;
  }

  dispose() {
    this.player().off(PlayerEvents.LOAD_START, this.update);
  }

  show({ forceUpdate = true } = {}) {
    super.show();

    if (forceUpdate) {
      this.update();
      return;
    }

    this.el().className = this.buildCSSClass();
  }

  handleClick() {
    window.open(MediaShare.redirectUrl(this.mainChapter), '_blank', 'noopener');
  }

  update() {
    const { mediaComposition } = this.player().options().SRGProviders;
    const { isComponentVisible = true } = this.options();
    const mainChapter = mediaComposition && mediaComposition.getMainChapter();

    this.selectedBU = isComponentVisible && mainChapter && mainChapter.vendor.toLowerCase();

    if (!this.selectedBU || !this.el() || !mainChapter) {
      this.hide();
      return;
    }

    this.mainChapter = mainChapter;

    this.show({ forceUpdate: false });

    this.updateAccessibility();
  }

  updateAccessibility() {
    const el = this.el();

    el.title = this.localize('Open {1} in Play {2}',
      [this.mainChapter.title,
        this.mainChapter.vendor.toUpperCase(),
      ]);

    this.controlText(el.title);
  }

  createEl() {
    return super.createEl('button', {}, {
      role: 'button',
    });
  }
}

videojs.registerComponent('SRGSSRButtonComponent', SRGSSRButtonComponent);
export default SRGSSRButtonComponent;
