import videojs from 'video.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBox SRG Menu Panel
 * @ignore
 */
class SRGMenuPanel extends Component {
  constructor(player, options) {
    const obj = videojs.mergeOptions(options, { name: 'panel' });
    super(player, obj);

    if (!options.isFirstLevelMenu) {
      this.el().setAttribute('role', this.options().role || 'menu');
    }
  }

  createEl() {
    const el = super.createEl('div', {
      className: `${this.options().className ? this.options().className : ''} srg-menu__panel`,
    });

    return el;
  }
}

videojs.registerComponent('SRGMenuPanel', SRGMenuPanel);
export default SRGMenuPanel;
