import videojs from 'video.js';

import Image from '../../../utils/Image.js';
import MediaShare from '../../../utils/MediaShare.js';
import Utils from '../../../utils/Utils.js';
import PlayerUtils from '../../../utils/PlayerUtils.js';
import BlockingReason from '../../../utils/BlockingReason.js';

const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 *
 * @ignore
 */
class Subdivision extends ClickableComponent {
  constructor(player, options) {
    super(player, options);
    this.subdivision = options.subdivision;
    this.updateChildren();
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} subdivision`;
  }

  createDescriptionEl() {
    return videojs.dom.createEl('p', {
      className: 'subdivision__description',
    },
    {},
    this.subdivision.title);
  }

  createDurationEl() {
    return videojs.dom.createEl('div',
      {
        className: 'subdivision__duration',
      }, {
        'aria-hidden': true,
      },
      this.formatDuration());
  }

  createEl() {
    return super.createEl('a', {
      className: this.buildCSSClass(),
    });
  }

  createImageEl() {
    return videojs.dom.createEl('img', {
      alt: this.subdivision.imageTitle,
      className: 'subdivision__img',
      src: Image.scale({
        url: this.subdivision.imageUrl,
        width: Image.WIDTH_240,
      }),
    }, {
      'aria-hidden': true,
    });
  }

  formatDuration() {
    return videojs.formatTime(
      Utils.millisecondsToSeconds(this.subdivision.duration),
    );
  }

  handleClick(e) {
    e.preventDefault();
    PlayerUtils.switchToUrn(this.player(), this.subdivision.urn);
  }

  static getblockReasonIcon(blockReason) {
    return BlockingReason.getIcon(blockReason);
  }

  setSelected(selected) {
    if (selected) {
      this.addClass('subdivision--selected');
    } else {
      this.removeClass('subdivision--selected');
    }
  }

  updateChildren() {
    videojs.setAttributes(this.el(), {
      href: `${MediaShare.redirectUrl(this.subdivision)}`,
    });

    if (this.subdivision.blockReason) {
      const placeHolder = this.el().querySelector('.vjs-icon-placeholder');
      const currentIcon = placeHolder.getAttribute('data-icon');

      if (currentIcon) {
        placeHolder.removeAttribute(currentIcon);
      }

      this.el().setAttribute('data-blockReason', this.subdivision.blockReason);
      placeHolder.classList.add(Subdivision.getblockReasonIcon(this.subdivision.blockReason));
      placeHolder.setAttribute('data-icon', Subdivision.getblockReasonIcon(this.subdivision.blockReason));
    }

    const fragment = document.createDocumentFragment();

    fragment.appendChild(this.createImageEl());
    fragment.appendChild(this.createDurationEl());
    fragment.appendChild(this.createDescriptionEl());
    this.el().appendChild(fragment);
    this.el().title = this.subdivision.title;
  }

  urn() {
    return this.subdivision.urn;
  }

  static get width() { return 142; }
}

videojs.registerComponent('Subdivision', Subdivision);
export default Subdivision;
