import videojs from 'video.js';
import * as Events from '../../utils/Events.js';

const Button = videojs.getComponent('Button');
/**
 * @class AirplayButton
 * @description Create a custom control that adds AirPlay to Safari
 * @ignore
 * @see https://developer.apple.com/documentation/webkitjs/adding_an_airplay_button_to_your_safari_media_controls
 */
class AirplayButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.isPlaying = false;

    this.updateAvailability();
  }

  buildCSSClass() {
    return `srg-airplay-button ${super.buildCSSClass()}`;
  }

  /**
   * Get the audio or video element.
   *
   * @returns audio or video element
   */
  getMediaElement() {
    return this.player().el().querySelector('audio, video');
  }

  /**
   * The click allow to :
   * - Change the playback state.
   * - Show playback target picker.
   */
  handleClick() {
    this.updatePlaybackState();
    this.getMediaElement().webkitShowPlaybackTargetPicker();
  }

  /**
   * Updates the component if it is supported and after the availability status changes.
   */
  updateAvailability() {
    if (!AirplayButton.isAirplaySupported()) {
      this.hide();

      return;
    }

    this.getMediaElement()
      .addEventListener(Events.WEBKIT_PLAYBACK_TARGET_AVAILABILITY_CHANGED, (event) => {
        if (event.availability === 'available') {
          this.show();
        }
      });
  }

  /**
   * Updates the button depending on the playback state.
   */
  updateButtonState() {
    if (!this.isPlaying) {
      this.controlText('Start Airplay');
      this.removeClass('srg-airplay-button--active');

      return;
    }

    this.controlText('Stop Airplay');
    this.addClass('srg-airplay-button--active');
  }

  /**
   * Update the playback state when it changes.
   *
   * @see updateButtonState
   * @fires airplayPlaybackState
   */
  updatePlaybackState() {
    this.isPlaying = !this.isPlaying;

    this.player().trigger({
      type: Events.AIRPLAY_PLAYBACK_STATE,
      data: {
        isAirplayPlaying: this.isPlaying,
      },
    });

    this.updateButtonState();
  }

  /**
   * Check if supported.
   *
   * @return {boolean} true if supported false otherwise.
   */
  static isAirplaySupported() {
    return !!window.WebKitPlaybackTargetAvailabilityEvent;
  }
}

videojs.registerComponent('AirplayButton', AirplayButton);
export default AirplayButton;
