import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 *
 * @ignore
 */
class SubdivisionsContainerArrowRight extends Button {
  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-subdivisions__arrow subdivisions-arrow-next`.trim();
  }

  createEl() {
    return super.createEl('button', {
      className: this.buildCSSClass(),
      tabIndex: -1,
    }, {
      'aria-hidden': true,
    });
  }

  // eslint-disable-next-line
  handleClick(e) {
    e.preventDefault();
  }
}

videojs.registerComponent('SubdivisionsContainerArrowRight', SubdivisionsContainerArrowRight);
export default SubdivisionsContainerArrowRight;
