import videojs from 'video.js';


/**
 * Improve original SeekBar component by seek on drag
 * for vod, aod, live DVR.
 * @ignore
 */
class SeekBar extends videojs.getComponent('SeekBar') {
  getCurrentTime_() { // eslint-disable-line
    return this.player().currentTime();
  }

  handleMouseMove(event, mouseDown = false) {
    super.handleMouseMove(event, mouseDown);
    this.update();
  }
}

videojs.registerComponent('SeekBar', SeekBar);
export default SeekBar;
