function computeSrgKind({ kind, label }) {
  const hasCaptionKind = ['captions', 'descriptions'].includes(kind);
  const hasForcedLabel = Boolean(label.match(/forced/gi)) || kind === 'forced';
  const hasSDH = label && label.toLowerCase().includes('sdh');

  const isCaptionKind = !!(hasCaptionKind || hasSDH);

  if (hasForcedLabel) {
    return 'forced';
  }

  return isCaptionKind ? 'captions' : 'subtitles';
}

function isCaption(srgKind) {
  return srgKind === 'captions';
}

function findActiveTrack(tracks) {
  return Array.from(tracks).find(({ mode }) => mode === 'showing');
}

function format(
  {
    language, srgKind,
  } = {},
) {
  if (language) {
    return {
      captions: isCaption(srgKind),
      language,
    };
  }

  return {
    captions: false,
    language: 'off',
  };
}

function sanitizeParameter(value) {
  if (typeof value !== 'object' && value !== undefined) {
    const language = value;

    return format({ language });
  }

  return value;
}

export default {
  computeSrgKind,
  isCaption,
  findActiveTrack,
  format,
  sanitizeParameter,
};
