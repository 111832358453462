import videojs from 'video.js';
import SRGStreamType from '../../utils/SRGStreamType.js';
import * as Events from '../../utils/Events.js';

const VJSBigPlayButton = videojs.getComponent('BigPlayButton');

/**
 * LetterBoxWeb BigPlayButton component, improving the original component
 * with specific css animation.
 * @ignore
 */
class BigPlayButton extends VJSBigPlayButton {
  constructor(player, options) {
    super(player, options);


    /**
     * Add first-play class to force display block on the overlay and big play button component
     */
    this.player().on(Events.LOADED_DATA, () => {
      if (SRGStreamType.isDvr(this.player().currentSource().streamType)
        || SRGStreamType.isLive(this.player().currentSource().streamType)) {
        this.player().el().classList.add('first-play');
      }
    });


    this.el().firstChild.addEventListener(Events.ANIMATION_END, () => {
      if (!this.player().paused()) {
        this.player().overlayComponent.hide();
        this.player().el().classList.remove('first-play');
        /**
         * if live stream, remove hasStarted class to initialize the view (poster, play logo)
         */
      } else if (SRGStreamType.isLive(this.player().currentSource().streamType)) {
        this.player().hasStarted(false);
      }
    });
  }
}

videojs.registerComponent('BigPlayButton', BigPlayButton);
export default BigPlayButton;
