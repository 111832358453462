import videojs from 'video.js';
import SupportedDevices from '../../../../utils/SupportedDevices.js';
import * as Events from '../../../../utils/Events.js';
import { PLAYER_BAR_CLASS } from '../../../../utils/SRGLetterboxLayout.js';

const SRGMenuButton = videojs.getComponent('SRGMenuButton');

/**
 * LetterBox Settings Button component
 *
 * @ignore
 */
class PlaybackRateButton extends SRGMenuButton {
  constructor(player, options) {
    super(player, options);

    this.controlText('Playback Rate');
    this.el().setAttribute('aria-haspopup', 'true');

    /**
     * Sets the right playback value when the menu button is ready to show up
     */
    player.on([Events.CAN_PLAY, Events.RATE_CHANGE], () => {
      this.updateLabelValue(this.player().playbackRate());
    });

    /**
     * If we are using a touch device, it's useless to show the menu hovering the button
     */
    if (SupportedDevices.isTouchDevice) {
      return;
    }

    /**
     * Opens the menu when hovering the menu button
     */
    this.on(Events.MOUSE_ENTER, () => {
      if (this.isPlayerBarMode()) {
        return;
      }

      this.addClass('vjs-hover');
      if (!this.SRGMenuComponent.isContainerVisible()) {
        this.SRGMenuComponent.showContainer();
        this.el().setAttribute('aria-expanded', 'true');
        /**
         * We want to hide it when the mouse is outside the menu container
         */
        this.SRGMenuComponent.overlay.one(Events.MOUSE_ENTER, () => {
          this.SRGMenuComponent.hideContainer();
          this.el().setAttribute('aria-expanded', 'false');
        });
      }
    });
  }

  /**
   * Create the `Component`'s DOM element
   *
   * @return {Element}
   *         The element that was created.
   */
  createEl() {
    const el = super.createEl();

    this.labelEl = super.createEl('div', {
      className: 'vjs-playback-rate-value',
    });

    el.appendChild(this.labelEl);

    return el;
  }

  /**
   * Updates the menu button label
   * @param {String} value Playback rate
   */
  updateLabelValue(value) {
    this.labelEl.innerText = `${value.toFixed(1)} x`;
  }

  handleClick() {
    if (!SupportedDevices.isTouchDevice && !this.isPlayerBarMode()) {
      super.handleClick();
      return;
    }
    this.trigger(Events.MENU_VALUE_SCROLL);
  }

  buildCSSClass() {
    return `srg-menu__playback-rate-button ${super.buildCSSClass()}`;
  }

  isPlayerBarMode() {
    return this.player().hasClass(PLAYER_BAR_CLASS);
  }
}

videojs.registerComponent('PlaybackRateButton', PlaybackRateButton);
export default PlaybackRateButton;
