import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 * LetterBoxWeb sharing button component
 * @ignore
 */
class SharingButton extends Button {
  constructor(player, options = {}) {
    super(player, options);

    if (options.controlText) {
      this.controlText(options.controlText);
    }
  }

  buildCSSClass() {
    const className = this.options().className ? `${this.options().className}` : '';
    return `${super.buildCSSClass()} ${className} vjs-icon-${this.options().icon}`;
  }

  handleClick(event) {
    if (this.options().onClick) {
      this.options().onClick(event);
    }
  }
}

videojs.registerComponent('SharingButton', SharingButton);
export default SharingButton;
