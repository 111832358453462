import videojs from 'video.js';
import Subdivision from './subdivision.js';
import SegmentService from '../../../dataProvider/services/SegmentService.js';
import * as PlayerEvents from '../../../utils/PlayerEvents.js';
import * as Events from '../../../utils/Events.js';

const Component = videojs.getComponent('Component');
const SCROLL_DURATION = 200;

/**
 *
 * @ignore
 */
class SubdivisionsList extends Component {
  constructor(player, options) {
    super(player, options);

    this.on(player, SegmentService.SEGMENT_CHANGE, this.updateSelectionForUrn.bind(this));
    this.on(player, PlayerEvents.LOAD_START, this.updateSelectionForCurrentPlayer.bind(this));
    this.on(player, PlayerEvents.SEEKED, this.disablePreviousSelectionAfterSeeking.bind(this));

    this.on(Events.WHEEL, (e) => {
      if (e.deltaY > 0) {
        this.scrollRight();
      } else {
        this.scrollLeft();
      }

      e.preventDefault();
    });
  }

  disablePreviousSelectionAfterSeeking() {
    if (!this.player().scrubbing()) {
      const currentTime = this.player().currentTime();

      this.children().forEach((item) => {
        const { subdivision } = item.options();
        const selected = SegmentService.doesSegmentContainTime(subdivision, currentTime);

        if (!selected) {
          item.setSelected(selected);
        }
      });
    }
  }

  updateSelectionForUrn(e) {
    const segmentUrn = e.data;
    const selectedIndex = this.selectByUrn(segmentUrn);

    if (selectedIndex >= 0) {
      this.scrollToIndex(selectedIndex);
    }
  }

  updateSelectionForCurrentPlayer() {
    const player = this.player();
    const { pendingSegment } = player.currentSource();

    let urn;
    if (pendingSegment) {
      // Used only during initial seek, the urn + time check below will take over
      // once pending seek has been performed
      // eslint-disable-next-line prefer-destructuring
      urn = pendingSegment.urn;
    } else {
      urn = player.options().SRGProviders.mediaComposition.chapterUrn;
    }

    const currentTime = player.currentTime();

    this.el().scrollLeft = 0;

    this.children().forEach((item, i) => {
      const { subdivision } = item.options();
      const selected = SegmentService.doesSegmentContainTime(subdivision, currentTime)
        || subdivision.urn === urn;
      item.setSelected(selected);

      if (selected) {
        this.scrollToIndex(i);
      }
    });
  }

  selectByUrn(segmentUrn) {
    let selectedIndex = -1;

    this.children().forEach((subdivision, i) => {
      const selected = subdivision.urn() === segmentUrn;
      subdivision.setSelected(selected);

      if (selected) {
        selectedIndex = i;
      }
    });

    return selectedIndex;
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} subdivisions__list`;
  }

  setSubdivisions(player, options) {
    // Remove all subdivisions before adding new ones
    this.removeChildren();

    options.subdivisions.filter(s => s.displayable).forEach((subdivision) => {
      const item = this.addChild(new Subdivision(player, { subdivision, name: 'subdivision' }));
      item.addClass('subdivision--show');
    });

    this.updateSelectionForCurrentPlayer();
  }

  canGoLeft() {
    return this.el().scrollLeft > 0;
  }

  canGoRight() {
    const el = this.el();
    return el.scrollLeft < el.scrollWidth - el.clientWidth;
  }

  createEl() {
    return super.createEl('div', {
      className: this.buildCSSClass(),
    });
  }

  getSubdivisionWidth() {
    const subdivision = this.getChild('subdivision');

    if (subdivision) {
      return subdivision.width();
    }

    return Subdivision.width;
  }

  removeChildren() {
    // remove all children at once
    this.children().length = 0;
    videojs.dom.emptyEl(this.el());
  }

  scrollLeft() {
    if (!this.canGoLeft()) return;

    const leftPosition = this.el().scrollLeft - this.getSubdivisionWidth();

    this.scrollTo(leftPosition, SCROLL_DURATION);
  }

  scrollRight() {
    if (!this.canGoRight()) return;

    const leftPosition = this.el().scrollLeft + this.getSubdivisionWidth();

    this.scrollTo(leftPosition, SCROLL_DURATION);
  }

  scrollTo(leftPosition = 0, duration = 1000, scrollToDone = null) {
    const element = this.el();
    const start = element.scrollLeft;
    const change = leftPosition - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = (() => {
      currentTime += increment;
      const val = SubdivisionsList.easeInOutQuad(currentTime, start, change, duration);
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft = val;

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else if (scrollToDone) {
        scrollToDone();
      }
    });

    animateScroll();
  }

  scrollToIndex(index) {
    const selectedSegmentPosition = index * this.getSubdivisionWidth();

    this.scrollTo(selectedSegmentPosition, SCROLL_DURATION);
  }

  static easeInOutQuad(t, b, c, d) {
    // eslint-disable-next-line no-param-reassign,no-cond-assign
    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    // eslint-disable-next-line no-plusplus,no-param-reassign
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
  }
}

videojs.registerComponent('SubdivisionsList', SubdivisionsList);
export default SubdivisionsList;
