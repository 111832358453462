import videojs from 'video.js';
import playerUtils from '../utils/playerUtils.js';

const Button = videojs.getComponent('Button');
/**
 * @class BackwardButton
 * @description Create a custom control that allow to quiclky rewind the media
 * @ignore
 */
class BackwardButton extends Button {
  constructor(player, options) {
    super(player, options);
    this.controlText(this.localize('10 seconds backward'));
  }

  buildCSSClass() {
    return `vjs-icon-rewind vjs-control-seek ${super.buildCSSClass()}`;
  }

  handleClick() {
    playerUtils.seekFromNow(this.player(), -10);
  }
}

videojs.registerComponent('BackwardButton', BackwardButton);
export default BackwardButton;
