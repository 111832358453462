import videojs from 'video.js';

const Component = videojs.getComponent('Component');

const CLASS_SHOWN = 'srgssr-end-screen-feature--show';

/**
 *
 * @ignore
 */
class EndScreenFeature extends Component {
  /**
   * Mounts the component
   *
   * @returns {undefined}
   */
  mount() {
    this.show();
  }

  /**
   * Unmounts the component
   *
   * @returns {undefined}
   */
  unmount() {
    this.hide();
  }

  /**
   * Shows the component
   *
   * @returns {undefined}
   */
  show() {
    this.addClass(CLASS_SHOWN);
  }

  /**
   * Hides the component
   *
   * @returns {undefined}
   */
  hide() {
    this.removeClass(CLASS_SHOWN);
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-end-screen-feature`;
  }
}

videojs.registerComponent('EndScreenFeature', EndScreenFeature);
export default EndScreenFeature;
