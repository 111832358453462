class AkamaiTokenService {
  /**
   * Get the acl path
   *
   * @param {String} streamUrl
   * @returns the acl path
   */
  static aclPath(streamUrl) {
    let acl;

    if (streamUrl.href.includes('/hls/playingLive/')) {
      acl = '/hls/playingLive/*';
    } else {
      const path = streamUrl.pathname;
      acl = `${path.substring(0, path.lastIndexOf('/') + 1)}*`;
    }

    return acl;
  }

  /**
   * Generate the stream URL with the akamai token
   *
   * @param {String} source
   * @param {String} tokenServerUrl
   */
  static tokenize(source, tokenServerUrl) {
    const streamUrlToTokenize = new URL(`${source.src}`);
    const acl = AkamaiTokenService.aclPath(streamUrlToTokenize);
    const url = `${tokenServerUrl}${encodeURIComponent(acl)}`;

    const diagnostic = { url };

    return fetch(url)
      .then((response) => {
        diagnostic.httpStatusCode = response.status;
        diagnostic.httpStatusText = response.statusText;

        if (response.ok) {
          return response.json()
            .then((data) => {
              new URLSearchParams(data.token.authparams)
                .forEach((v, k) => streamUrlToTokenize.searchParams.set(k, v));

              return Object.assign({}, source, {
                src: streamUrlToTokenize.toString(),
                tokenDiagnostic: diagnostic,
              });
            });
        }

        return Promise.reject(diagnostic);
      })
      .catch((reason) => {
        diagnostic.httpStatusText = reason.message;

        return Promise.reject(diagnostic);
      });
  }

  /**
   * Generate a token for each source
   *
   * @param {Array} sources
   * @param {String} tokenServerUrl
   */
  static tokenizeSources(sources, tokenServerUrl = 'https://tp.srgssr.ch/akahd/token?acl=') {
    const tokenizedSources = [];

    sources.forEach((source) => {
      const tokenizedSource = AkamaiTokenService.tokenize(source, tokenServerUrl);
      tokenizedSources.push(tokenizedSource);
    });

    return Promise.all(tokenizedSources)
      .then(values => values)
      .catch(reason => Promise.reject(reason));
  }
}

export default AkamaiTokenService;
