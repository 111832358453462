import videojs from 'video.js';

const SRGMenuButton = videojs.getComponent('SRGMenuButton');

/**
 * LetterBox Subtitles Button component
 *
 * @ignore
 */
class SubtitlesButton extends SRGMenuButton {
  constructor(player, options) {
    super(player, options);

    this.controlText('Audio and subtitles');
    this.el().setAttribute('aria-haspopup', 'true');
  }

  buildCSSClass() {
    return `srg-menu__subtitles-button ${super.buildCSSClass()}`;
  }
}

videojs.registerComponent('SubtitlesButton', SubtitlesButton);
export default SubtitlesButton;
