import videojs from 'video.js';
import './big-play-button-component.js';
import * as PlayerEvents from '../../utils/PlayerEvents.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBoxWeb overlay component with big center play button,
 * Title component, sharing icons
 * @ignore
 */
class OverlayComponent extends Component {
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      children: [
        'BigPlayButton',
      ],
    }, options);

    super(player, opt);

    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.seekedHandler = () => {
      if (!this.player().scrubbing()) {
        this.show();
      }
    };

    this.initListener();
  }

  /**
   * Show / Hide overlay during seek action
   */
  initListener() {
    this.player().on(PlayerEvents.SEEKING, this.hide);
    this.player().on(PlayerEvents.SEEKED, this.seekedHandler);
    this.player().on([PlayerEvents.LOAD_START, PlayerEvents.PAUSE], this.show);
  }

  createEl() {
    const el = super.createEl('div', {
      className: this.buildCSSClass(),
    });

    return el;
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} vjs-srgssr-overlay`.trim();
  }

  dispose() {
    this.player().off(PlayerEvents.SEEKING, this.hide);
    this.player().off(PlayerEvents.SEEKED, this.seekedHandler);
    this.player().off([PlayerEvents.LOAD_START, PlayerEvents.PAUSE], this.show);

    super.dispose();
  }
}

Component.registerComponent('OverlayComponent', OverlayComponent);
export default OverlayComponent;
