const TOKEN_TYPES = {
  AKAMAI: 'AKAMAI',
  NONE: 'NONE',
};

class SRGTokenType {
  /**
   * AKAMAI
   */
  static get AKAMAI() {
    return TOKEN_TYPES.AKAMAI;
  }

  /**
   * Check if the resources are protected by an Akamai token.
   * Keep in mind, as we are using a some,
   * if the resources have at least one resource protected by a token it returns true!
   * @param {Array} resources
   */
  static isProtected(resources) {
    return resources.some(resource => SRGTokenType.isAkamai(resource.tokenType));
  }

  /**
   * Check if the token type is AKAMAI
   * @param {String} tokentype
   */
  static isAkamai(tokentype) {
    return TOKEN_TYPES.AKAMAI === tokentype;
  }

  /**
   * Check if the token type is NONE
   * @param {String} tokentype
   */
  static isNone(tokentype) {
    return TOKEN_TYPES.NONE === tokentype;
  }

  /**
   * NONE
   */
  static get NONE() {
    return TOKEN_TYPES.NONE;
  }
}

export default SRGTokenType;
