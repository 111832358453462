import videojs from 'video.js';
import EndScreenFeature from './end-screen-feature.js';
import CircularCountdown from './circular-count-down.js';
import Image from '../../../utils/Image.js';
import CancelButton from './cancel-button.js';
import PlayerUtils from '../../../utils/PlayerUtils.js';
import Utils from '../../../utils/Utils.js';
import * as SRGEvents from '../../../utils/SRGEvents.js';
import * as VideojsEvents from '../../../utils/VideojsEvents.js';

const HAS_CONTINOUS_PLAYBACK = 'srgssr-has-continuous-playback';
const CLASS_SMALL_SIZE = 'continuous-playback--small';

const COUNTDOWN_TIMER = 10;

/**
 *
 * @ignore
 */
class ContinuousPlayback extends EndScreenFeature {
  constructor(player, options = {}) {
    super(player, options);

    this.recommendationId = null;
    this.nextMedia = null;

    this.cancelContinuousPlayback = this.cancelContinuousPlayback.bind(this);
    this.checkResize = this.checkResize.bind(this);
    this.redirectToMedia = this.redirectToMedia.bind(this);
  }

  /**
   * Shows and build the recommendation grid
   *
   * @param {Number} options - mediaList and recommendationId
   *
   * @returns {undefined}
   */
  mount({ mediaList, recommendationId }) {
    if (!mediaList) {
      return;
    }

    this.recommendationId = recommendationId;
    this.nextMedia = ContinuousPlayback.getNextMedia(PlayerUtils.getUrn(this.player()), mediaList);
    this.updateShowInformation(
      this.nextMedia,
    );
    this.triggerContinuousPlaybackDisplayed();
    super.mount();
    this.player().addClass(HAS_CONTINOUS_PLAYBACK);
    this.timer.reset();
    this.timer.start();

    this.checkResize();

    this.on(
      this.timer,
      SRGEvents.CONTINUOUSPLAYBACK_DONE,
      this.redirectToMedia,
    );
    this.on(
      this.cancelBtn,
      SRGEvents.CONTINUOUSPLAYBACK_CANCEL,
      this.cancelContinuousPlayback,
    );
    this.on(this.player(), VideojsEvents.PLAYER_RESIZE, this.checkResize);
  }

  /**
   * Hides and remove all active listeners
   *
   * @returns {undefined}
   */
  unmount() {
    super.unmount();
    this.player().removeClass(HAS_CONTINOUS_PLAYBACK);
    this.reset();
  }

  /**
   * Resets timer and remove all active listeners
   *
   * @returns {undefined}
   */
  reset() {
    this.timer.reset();
    this.off(
      this.timer,
      SRGEvents.CONTINUOUSPLAYBACK_DONE,
      this.redirectToMedia,
    );
    this.off(
      this.cancelBtn,
      SRGEvents.CONTINUOUSPLAYBACK_CANCEL,
      this.cancelContinuousPlayback,
    );
    this.off(this.player(), VideojsEvents.PLAYER_RESIZE, this.checkResize);
  }

  /**
   * @event srgssr/recommendationDisplayed
   */
  triggerContinuousPlaybackDisplayed() {
    this.player().trigger(SRGEvents.CONTINUOUS_PLAYBACK_DISPLAYED);
  }

  /**
   * Dispatch cancel event to end screen
   *
   * @returns {undefined}
   */
  cancelContinuousPlayback() {
    this.trigger(SRGEvents.CONTINUOUSPLAYBACK_CANCEL);
  }

  /**
   * Checks the screen size to correctly fit components in a responsive way
   *
   * @returns {undefined}
   */
  checkResize() {
    if (this.height() <= 279) {
      this.addClass(CLASS_SMALL_SIZE);
    } else {
      this.removeClass(CLASS_SMALL_SIZE);
    }
  }

  /**
   * Plays the next URN when countdown is over
   *
   * @returns {undefined}
   */
  redirectToMedia() {
    const { urn, standalone = undefined, pendingSeek = undefined } = this.nextMedia;
    this.triggerContinuousPlaybackHit(urn);

    PlayerUtils.loadMedia(
      this.player(), {
        autoplay: true,
        pendingSeek,
        playbackSettings: {
          forcePlay: true,
          recommendationId: this.recommendationId,
        },
        standalone,
        urn,
      },
    );
  }

  /**
   * Updates titles and image cover
   *
   * @returns {undefined}
   */
  updateShowInformation({
    showTitle,
    title,
    imageUrl,
    duration,
  }) {
    const backgroundImageUrl = `url(${Image.scale({
      url: imageUrl,
    })})`;
    this.el().style.backgroundImage = backgroundImageUrl;

    this.title.textContent = showTitle;
    this.description.textContent = title;
    this.duration.textContent = this.localize(
      'Duration: {1}',
      [videojs.formatTime(Utils.millisecondsToSeconds(duration), '600')],
    );
  }

  /**
   * @event srgssr/continuousPlaybackHit
   */
  triggerContinuousPlaybackHit(urn) {
    this.player().trigger({ type: SRGEvents.CONTINUOUS_PLAYBACK_HIT, data: { urn } });
  }

  /**
   * Overrided methods
   */

  buildCSSClass() {
    return `${super.buildCSSClass()} vjs-poster srgssr-continuous-playback`;
  }

  createEl() {
    const fragment = document.createDocumentFragment();
    const className = this.buildCSSClass();
    const el = super.createEl('div', {
      className,
    });

    const container = ContinuousPlayback.createContainerEl();
    const comingNext = this.createComingNextEl();

    this.title = ContinuousPlayback.createTitleEl();
    this.description = ContinuousPlayback.createDescriptionEl();
    this.duration = ContinuousPlayback.createDurationEl();
    this.timer = new CircularCountdown(this.player(), { duration: COUNTDOWN_TIMER });
    this.cancelBtn = new CancelButton(this.player());

    fragment.appendChild(comingNext);
    fragment.appendChild(this.title);
    fragment.appendChild(this.description);
    fragment.appendChild(this.duration);
    fragment.appendChild(this.timer.contentEl());
    fragment.appendChild(this.cancelBtn.contentEl());

    container.appendChild(fragment);

    el.appendChild(container);

    return el;
  }

  static createContainerEl() {
    return videojs.dom.createEl('div', {
      className: 'continuous-playback__container',
    });
  }

  static createTitleEl() {
    return videojs.dom.createEl('h1', {
      className: 'continuous-playback__title',
    });
  }

  static createDescriptionEl() {
    return videojs.dom.createEl('p', {
      className: 'continuous-playback__description',
    });
  }

  static createDurationEl() {
    return videojs.dom.createEl('span', {
      className: 'continuous-playback__duration',
    });
  }

  /**
   * Get the next media
   *
   * @param {String} currentUrn
   * @param {Array} mediaList array of media object
   *
   * @returns {Object} media
   */
  static getNextMedia(currentUrn, mediaList = []) {
    if (!mediaList.length) return undefined;

    const currentIndex = mediaList.findIndex(media => media.urn === currentUrn);
    const nextIndex = (currentIndex + 1) % mediaList.length;

    return mediaList[nextIndex];
  }

  createComingNextEl() {
    return videojs.dom.createEl('span', {
      className: 'continuous-playback__coming-next',
    },
    {},
    this.localize('Coming next'));
  }
}

videojs.registerComponent('ContinuousPlayback', ContinuousPlayback);
export default ContinuousPlayback;
