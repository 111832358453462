/**
 * Exposes all events properties.
 *
 * @namespace {Object} Events
 *
 * @see PlayerEvents
 * @see VideojsEvents
 * @see SRGEvents
 */


export * from './PlayerEvents.js';
export * from './VideojsEvents.js';
export * from './SRGEvents.js';

/**
 *
 * The addtrack event is fired when a track is added to a TextTrackList.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/TextTrackList/addtrack_event
 *
 * The addtrack event is fired when a track is added to an AudioTrackList.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/AudioTrackList/addtrack_event
 *
 *
 * @memberof Events
 * @member ADD_TRACK
 * @static
 * @const
 * @type {String}
 */
export const ADD_TRACK = 'addtrack';

/**
 * The animationend event is fired when a CSS Animation has completed.
 * If the animation aborts before reaching completion,
 * such as if the element is removed from the DOM or the animation is removed from the element,
 * the animationend event is not fired.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/animationend_event
 *
 * @memberof Events
 * @member ANIMATION_END
 * @static
 * @const
 * @type {String}
 */
export const ANIMATION_END = 'animationend';

/**
 * The beforeunload event is fired when the window,
 * the document and its resources are about to be unloaded.
 * The document is still visible and the event is still cancelable at this point.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 *
 * @memberof Events
 * @member BEFOREUNLOAD
 * @static
 * @const
 * @type {String}
 */
export const BEFOREUNLOAD = 'beforeunload';

/**
 * The change event is fired when a text track is made active or inactive,
 * or a textTrackList is otherwise changed.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/TextTrackList/change_event
 *
 * The change event is fired when an audio track is enabled or disabled,
 * for example by changing the track's enabled property.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/AudioTrackList/change_event
 *
 * @memberof Events
 * @member CHANGE
 * @static
 * @const
 * @type {String}
 */
export const CHANGE = 'change';

/**
 * An element receives a click event when a pointing device button
 * (such as a mouse's primary mouse button)is both pressed and released
 * while the pointer is located inside the element.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event
 *
 * @memberof Events
 * @member CLICK
 * @static
 * @const
 * @type {String}
 */
export const CLICK = 'click';


/**
 * The fullscreenchange event is fired immediately after the browser switches into or out of full-screen mode.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/fullscreenchange_event
 *
 * @memberof Events
 * @member FULL_SCREEN_CHANGE
 * @static
 * @const
 * @type {String}
 */
export const FULL_SCREEN_CHANGE = 'fullscreenchange';

/**
 * The keydown event is fired when a key is pressed.
 * Unlike the keypress event, the keydown event is fired for all keys,
 * regardless of whether they produce a character value.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/keydown_event
 *
 * @memberof Events
 * @member KEY_DOWN
 * @static
 * @const
 * @type {String}
 */
export const KEY_DOWN = 'keydown';

/**
 * The mouseenter event is fired at an Element when a pointing device (usually a mouse)
 * is initially moved so that its hotspot is within the element at which the event was fired.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseenter_event
 *
 * @memberof Events
 * @member MOUSE_ENTER
 * @static
 * @const
 * @type {String}
 */
export const MOUSE_ENTER = 'mouseenter';

/**
 * The mouseleave event is fired at an Element when the cursor of a pointing device (usually a mouse) is moved out of it.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseleave_event
 */
export const MOUSE_LEAVE = 'mouseleave';

/**
 * The mousemove event is fired at an element when a pointing device (usually a mouse) is moved while the cursor's hotspot is inside it.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/mousemove_event
 */
export const MOUSE_MOVE = 'mousemove';

/**
 * The mouseup event is fired at an Element when a button on a pointing device (such as a mouse or trackpad) is released while the pointer is located inside it.
 * mouseup events are the counterpoint to mousedown events.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseup_event
 */
export const MOUSE_UP = 'mouseup';

/**
 * The touchcancel event is fired when one or more touch points have been disrupted in an implementation-specific manner (for example, too many touch points are created).
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/touchcancel_event
 */
export const TOUCH_CANCEL = 'touchcancel';

/**
 * The touchend event fires when one or more touch points are removed from the touch surface.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/touchend_event
 */
export const TOUCH_END = 'touchend';

/**
 * The touchmove event is fired when one or more touch points are moved along the touch surface.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/touchmove_event
 */
export const TOUCH_MOVE = 'touchmove';

/**
 * The touchmove event is fired when one or more touch points are moved along the touch surface.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scroll_event
 */
export const SCROLL = 'scroll';

/**
 * Airplay playback target availability changed.
 *
 * @memberof Events
 * @member WEBKIT_PLAYBACK_TARGET_AVAILABILITY_CHANGED
 * @static
 * @const
 * @type {String}
 */
export const WEBKIT_PLAYBACK_TARGET_AVAILABILITY_CHANGED = 'webkitplaybacktargetavailabilitychanged';

/**
 * Airplay current playback target is wireless changed.
 *
 * @memberof Events
 * @member WEBKIT_CURRENT_PLAYBACK_TARGET_IS_WIRELESS_CHANGED
 * @static
 * @const
 * @type {String}
 */
export const WEBKIT_CURRENT_PLAYBACK_TARGET_IS_WIRELESS_CHANGED = 'webkitcurrentplaybacktargetiswirelesschanged';

/**
 * The wheel event fires when the user rotates a wheel button on a pointing device (typically a mouse).
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/wheel_event
 *
 * @memberof Events
 * @member WHEEL
 * @static
 * @const
 * @type {String}
 */
export const WHEEL = 'wheel';
