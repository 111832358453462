export const ARROW_DOWN = 'ArrowDown';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_RIGHT = 'ArrowRight';
export const ARROW_UP = 'ArrowUp';
export const D = 68;
export const ENTER = 13;
export const ESCAPE = 27;
export const HYPHEN = '-';
export const P = 80;
export const PLUS = '+';
export const Q = 81;
export const SPACE = 32;
export const TAB = 9;
