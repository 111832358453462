import videojs from 'video.js';
import SRGMenuItem from './srg-menu-item.js';

const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

const SELECT_ITEM = 'srg-menu__panel-list-item--selected';
const CSS = {
  BUTTON: 'srg-menu__panel-list-item-button',
  HEADER: 'srg-menu__panel-list-header',
  HEADER_TITLE: 'srg-menu__panel-list-header-title',
  ITEM: 'srg-menu__panel-list-item',
  ITEM_LABEL: 'srg-menu__panel-list-item-label',
  ITEM_BADGE: 'srg-menu__panel-list-item-badge',
  SELECT_ITEM: 'srg-menu__panel-list-item--selected',
};
const BADGE_ACCESSIBILITY = {
  captions: 'SDH',
  'audio-description': 'AD',
};

/**
 * LetterBox SRGMenuPanelListItem component
 * @ignore
 */
class SRGMenuPanelListItem extends SRGMenuItem {
  /**
    * Updates the input state when a new value is setted
    * @override
    */
  updateValue() {
    this.items.forEach((item) => {
      if (this.isItemCurrentlySelected(item.options())) {
        item.addClass(SELECT_ITEM);
        item.el().setAttribute('aria-checked', true);
      } else {
        item.removeClass(SELECT_ITEM);
        item.el().setAttribute('aria-checked', false);
      }
    });
  }

  createButtonsFragment(items) {
    const fragment = document.createDocumentFragment();

    items.forEach((item) => {
      const { badge, label: itemLabel, value } = item;

      const itemLabelEl = videojs.dom.createEl('span', {
        className: CSS.ITEM_LABEL,
      }, {}, itemLabel);

      const isCurrentItemSelected = this.isItemCurrentlySelected(item) ? CSS.SELECT_ITEM : '';
      const buttonEl = videojs.dom.createEl(
        'button',
        {
          className: `${CSS.BUTTON} ${isCurrentItemSelected}`,
        },
        {
          'aria-label': badge && BADGE_ACCESSIBILITY[badge] ? `${itemLabel} ${BADGE_ACCESSIBILITY[badge]}` : itemLabel,
          'aria-checked': !!isCurrentItemSelected,
          role: 'menuitemradio',
        },
        itemLabelEl,
      );

      const button = new Button(this.player(), {
        value,
        clickHandler: () => {
          this.dispatchValue(value);
        },
        el: buttonEl,
      });

      if (badge) {
        const badgeEl = videojs.dom.createEl('span', { className: `vjs-icon-${badge} ${CSS.ITEM_BADGE}` });
        button.el().appendChild(badgeEl);
      }

      this.items.push(button);

      fragment.appendChild(button.el());
    });

    return fragment;
  }

  createHeader(label) {
    const panelListHeaderTitle = videojs.dom.createEl('div', {
      className: CSS.HEADER_TITLE,
    }, undefined, label);

    const panelListHeaderEl = videojs.dom.createEl('div', {
      className: CSS.HEADER,
    }, undefined, panelListHeaderTitle);

    const header = new Component(this.player(), {
      name: 'header',
      el: panelListHeaderEl,
    });

    const { widget } = this.options();

    if (widget) {
      const WidgetComponent = videojs.getComponent(widget.componentClass);

      header.addChild(new WidgetComponent(this.player(), widget));
    }

    return header;
  }

  createEl() {
    this.items = [];

    const fragment = document.createDocumentFragment();
    const { label, items = [] } = this.options();
    const el = super.createEl('div', {
      className: CSS.ITEM,
    }, {
      'aria-label': this.localize(label),
      role: 'menu',
    });
    const header = this.createHeader(label).el();

    fragment.appendChild(header);

    if (items.length > 0) {
      fragment.appendChild(this.createButtonsFragment(items));
    }

    el.appendChild(fragment);

    return el;
  }
}

videojs.registerComponent('SRGMenuPanelListItem', SRGMenuPanelListItem);
export default SRGMenuPanelListItem;
