import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 * LetterBox SRG Menu Button
 *
 * @ignore
 */
class SRGMenuButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.el().setAttribute('aria-expanded', 'false');
  }

  buildCSSClass() {
    return `srg-menu__button ${super.buildCSSClass()}`;
  }

  handleClick() {
    if (this.SRGMenuComponent.isContainerVisible()) {
      this.SRGMenuComponent.hideContainer();
      this.el().setAttribute('aria-expanded', 'false');
    } else {
      this.SRGMenuComponent.showContainer();
      this.el().setAttribute('aria-expanded', 'true');
    }
  }

  get SRGMenuComponent() {
    // eslint-disable-next-line no-underscore-dangle
    return this.parentComponent_;
  }
}

videojs.registerComponent('SRGMenuButton', SRGMenuButton);
export default SRGMenuButton;
