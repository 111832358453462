import videojs from 'video.js';
import SRGMenuPanel from '../srg-menu-panel.js';
import SRGMenuItem from './srg-menu-item.js';

const Button = videojs.getComponent('Button');

/**
 * LetterBox SRGMenu item list component
 * @ignore
 */
class SRGMenuListItem extends SRGMenuItem {
  constructor(player, options) {
    super(player, options);

    this.renderPanel();
  }

  /**
   * Displays a list of possible values for the item
   */
  renderPanel() {
    this.listPanel = new SRGMenuPanel(this.player(), { className: 'srg-menu__panel-item--list' });
    this.options().items.forEach((item) => {
      const listEl = new Button(this.player(), {
        clickHandler: () => {
          this.dispatchValue(item.value);
        },
        el: videojs.dom.createEl(
          'button',
          {
            className: this.isItemCurrentlySelected(item) ? 'srg-menu__panel-item--selected' : '',
          },
          {
            'aria-label': item.label,
            role: 'menuitem',
          },
          videojs.dom.createEl('span', {
            className: 'srg-menu__panel-item-label',
          }, {}, item.label),
        ),
      }).el();

      if (item.badge) {
        listEl.appendChild(videojs.dom.createEl('span', { className: `vjs-icon-${item.badge} srg-menu__panel-item-badge` }));
      }
      this.listPanel.el().appendChild(listEl);
    });
  }

  /**
   * Updates the new value setted
   * @override
   */
  updateValue() {
    this.button.innerText = this.getLabelValue();
    this.button.setAttribute('aria-label', `${this.localize(this.options().label)} ${this.getLabelValue()}`);
    this.renderPanel();
    if (this.valueDispatched) {
      this.valueDispatched = false;
      this.button.focus();
    }
  }

  /**
   * Dispatches the new value to the handler ans closes the list panel
   * @param {*} value
   * @override
   */
  dispatchValue(value) {
    super.dispatchValue(value);
    this.closePanel();
    this.valueDispatched = true;
  }

  createEl() {
    const fragment = document.createDocumentFragment();
    const labelEl = this.createLabelEl();
    const linkEl = this.createLinkEl();


    fragment.appendChild(labelEl);
    fragment.appendChild(linkEl);


    const el = super.createEl('div', {
      className: 'srg-menu__panel-item',
    });

    el.appendChild(fragment);

    return el;
  }


  createLabelEl() {
    return videojs.dom.createEl('span',
      { className: 'srg-menu__panel-item__label' },
      {},
      this.localize(this.options().label));
  }

  getLabelValue() {
    const { items } = this.options();
    const selectedItem = items.filter(item => this.isItemCurrentlySelected(item));
    if (selectedItem && selectedItem.length) {
      return selectedItem.pop().label;
    }
    return null;
  }

  createLinkEl() {
    const selectedValue = this.getLabelValue();

    this.button = new Button(this.player(), {
      clickHandler: () => {
        this.addPanel(this.listPanel);

        const firstElementAccessibility = this.listPanel.el().firstChild;

        if (firstElementAccessibility) {
          firstElementAccessibility.focus();
        }
      },
      el: videojs.dom.createEl('button',
        { className: 'srg-menu__panel-item__link' },
        {
          tabindex: 0,
          role: 'button',
          'aria-label': `${this.localize(this.options().label)} ${this.localize(selectedValue)}`,
        },
        this.localize(selectedValue)),
    }).el();

    return this.button;
  }
}

videojs.registerComponent('SRGMenuListItem', SRGMenuListItem);
export default SRGMenuListItem;
