import videojs from 'video.js';
import SharingSocialAction from './sharing-social-action.js';
import Utils from '../../../../utils/Utils.js';

/**
 * LetterBoxWeb sharing button component to copy iframe code to clipboard
 * @ignore
 */
class SharingEmbedAction extends SharingSocialAction {
  constructor(player, options) {
    super(player, options);
    this.flashMessage = 'Copied';
  }

  triggerAction() {
    Utils.copyTextToClipboard(`<iframe width="560" height="315" src="${this.options().href()}&subdivisions=false" allowfullscreen allow="geolocation *; autoplay; encrypted-media"></iframe>`);
  }
}

videojs.registerComponent('SharingEmbedAction', SharingEmbedAction);
export default SharingEmbedAction;
