import * as Events from './Events.js';
import * as SRGSettings from './SRGSettings.js';
import { PLAYER_BAR_CLASS } from './SRGLetterboxLayout.js';
import PlayerUtils from './PlayerUtils.js';
import TextTracksUtils from './TextTracksUtils.js';

const DEFAULT_PLAYBACK_RATE = 1;
const PLAYER_SMALLEST_SIZES = ['tiny', 'xsmall'];
class SRGPlaybackSettings {
  constructor(player, ConfigurationHandler) {
    this.player = player;
    this.ConfigurationHandler = ConfigurationHandler;

    this.player.textTracks()
      .on(Events.ADD_TRACK, this.textTrackAddListener.bind(this));
    this.player.on(Events.LOADED_DATA, this.loadedDataListener.bind(this));
    this.player.on(Events.CAN_PLAY, this.canPlayListener.bind(this));
    this.player.on(Events.FIRST_PLAY, this.firstPlayListener.bind(this));
    this.player.on(Events.LAYOUT_BREAKPOINT_CHANGE, this.layoutBreakpointChangeListener.bind(this));
  }

  layoutBreakpointChangeListener() {
    this.restorePlaybackRate();
  }

  textTrackAddListener({ track = {} } = {}) {
    if (this.player && track.kind === 'metadata') return;

    const {
      kind, label,
    } = track;

    // eslint-disable-next-line
    track.srgKind = TextTracksUtils.computeSrgKind({ kind, label });
  }

  loadedDataListener() {
    const selectedAudioTrack = this.ConfigurationHandler
      .getSetting(this.player, SRGSettings.AUDIO_TRACK_LANGUAGE);
    const subtitleSetting = TextTracksUtils.sanitizeParameter(this.ConfigurationHandler
      .getSetting(this.player, SRGSettings.TEXT_TRACK_LANGUAGE));

    PlayerUtils.audioTrackLanguage(selectedAudioTrack, this.player);
    PlayerUtils.textTrackLanguage(subtitleSetting, this.player);
  }

  canPlayListener() {
    const volume = this.ConfigurationHandler.getSetting(this.player, SRGSettings.VOLUME);
    const muted = this.ConfigurationHandler.getSetting(this.player, SRGSettings.MUTED);
    const textTrackSizeLevel = this.ConfigurationHandler
      .getSetting(this.player, SRGSettings.TEXT_TRACK_SIZE_LEVEL);

    // Allows to show the right value before playing in player bar mode
    this.restorePlaybackRate();

    this.player.muted(muted);
    this.player.volume(volume);
    PlayerUtils.textTrackSizeLevel(textTrackSizeLevel, this.player);
  }

  firstPlayListener() {
    this.restorePlaybackRate();
  }

  restorePlaybackRate() {
    if (!PlayerUtils.isStreamOnDemand(this.player)) {
      return;
    }
    const size = this.player.options().currentBreakpoint;
    const isXSmallOrTinySize = PLAYER_SMALLEST_SIZES.includes(size);
    const isPlayerBar = this.player.hasClass(PLAYER_BAR_CLASS);

    const isReducedPlayerBar = isXSmallOrTinySize && isPlayerBar;

    const playbackRate = isReducedPlayerBar ? DEFAULT_PLAYBACK_RATE : this.ConfigurationHandler
      .getSetting(this.player, SRGSettings.PLAYBACK_RATE);

    this.player.playbackRate(playbackRate);
  }
}

export default SRGPlaybackSettings;
