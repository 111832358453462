import videojs from 'video.js';
import SRGStreamType from '../../utils/SRGStreamType.js';
import * as PlayerEvents from '../../utils/PlayerEvents.js';
import * as Events from '../../utils/Events.js';
import SegmentService from '../../dataProvider/services/SegmentService.js';
import BlockingReason from '../../utils/BlockingReason.js';
import Utils from '../../utils/Utils.js';

const Component = videojs.getComponent('Component');
const HEIGHT_THRESHOLD = 395;

const COEF = {
  huge: 1.7,
  xlarge: 1.7,
  large: 1.4,
  medium: 1.3,
  small: 1.2,
  xsmall: 1,
  tiny: 1,
};

class ThumbnailSeeking extends Component {
  constructor(player, options) {
    super(player, options);

    this.moveCancel = this.moveCancel.bind(this);
    this.moveListener = this.moveListener.bind(this);
    this.playerResize = this.playerResize.bind(this);
    this.thumbnailsAvailable = false;
    this.init();
  }

  init() {
    this.player().on(PlayerEvents.LOAD_START, () => {
      const mainChapter = this.player()
        .options()
        .SRGProviders.mediaComposition.getMainChapter();

      if (
        !mainChapter.spriteSheet
        || SRGStreamType.isLive(this.player().currentSource().streamType)
      ) {
        this.hide();
        if (this.thumbnailsAvailable) {
          this.removeListeners();
          this.thumbnailsAvailable = false;
        }
      } else {
        this.show();
        const {
          spriteSheet: {
            urn, rows, columns, thumbnailHeight, thumbnailWidth, interval, url,
          },
        } = mainChapter;

        this.globalSpriteSheet = {
          urn,
          rows,
          columns,
          thumbnailHeight,
          thumbnailWidth,
          interval,
          url,
          coef: 1,
        };

        this.thumbnail.src = this.globalSpriteSheet.url;
        this.playerResize();

        if (!this.thumbnailsAvailable) {
          this.initListeners();
          this.thumbnailsAvailable = true;
        }
      }
    });
  }

  resizeThumbnail() {
    this.globalSpriteSheet.coef = COEF[this.player().breakpoint_] || 1; // eslint-disable-line

    const {
      thumbnailWidth, thumbnailHeight, columns, coef,
    } = this.globalSpriteSheet;

    this.el().style.width = `${thumbnailWidth * coef}px`;
    this.el().style.height = `${thumbnailHeight * coef}px`;
    this.thumbnail.width = `${thumbnailWidth * columns * coef}`;
  }

  playerResize() {
    const height = this.player().currentDimension('height');
    const { isComponentVisible = true } = this.options();

    this.resizeThumbnail();

    if (height <= HEIGHT_THRESHOLD || !isComponentVisible) {
      this.hide();
      return;
    }

    this.show();
  }

  initListeners() {
    const { progressControl } = this.player().controlBar;

    progressControl.on(Events.MOUSE_MOVE, this.moveListener);
    progressControl.on(Events.TOUCH_MOVE, this.moveListener);

    progressControl.on(Events.MOUSE_LEAVE, this.moveCancel);
    progressControl.on(Events.TOUCH_CANCEL, this.moveCancel);
    progressControl.on(Events.TOUCH_END, this.moveCancel);
    this.player().on(Events.USER_INACTIVE, this.moveCancel);
    this.player().on(Events.PLAYER_RESIZE, this.playerResize);
  }

  removeListeners() {
    const { progressControl } = this.player().controlBar;

    progressControl.off(Events.MOUSE_MOVE, this.moveListener);
    progressControl.off(Events.TOUCH_MOVE, this.moveListener);

    progressControl.off(Events.MOUSE_LEAVE, this.moveCancel);
    progressControl.off(Events.TOUCH_CANCEL, this.moveCancel);
    progressControl.off(Events.TOUCH_END, this.moveCancel);
    this.player().off(Events.USER_INACTIVE, this.moveCancel);
    this.player().off(Events.PLAYER_RESIZE, this.playerResize);
  }

  moveListener(event) {
    if (!this.thumbnailsAvailable || !this.globalSpriteSheet) {
      return;
    }

    const { progressControl } = this.player().controlBar;
    const rect = videojs.dom.getBoundingClientRect(progressControl.el());
    const point = videojs.dom.getPointerPosition(progressControl.el(), event).x;

    this.calculateThumbnailHolderPosition(rect, point);
  }

  static calculatePointBoundary(xPoint) {
    if (xPoint > 1) {
      return 1;
    }
    if (xPoint < 0) {
      return 0;
    }

    return xPoint;
  }

  calculateThumbnailHolderPosition(rect, xPoint) {
    const point = ThumbnailSeeking.calculatePointBoundary(xPoint);

    let position = rect.width * point - this.width() / 2;

    const compoundMargin = 28; // keeps the thumbnail align with the progress holder
    const rightBoundary = rect.right - this.width() - compoundMargin;
    const leftBoundary = rect.left * -1 + compoundMargin;

    if (position > rightBoundary) {
      position = rightBoundary;
    }

    if (position < leftBoundary) {
      position = leftBoundary;
    }

    this.el().style.transform = `translateX(${position}px)`;

    const seekingTime = Math.floor(this.player().duration() * point);

    this.calculateFrame(seekingTime);
    this.hideBlockSegments(seekingTime);
  }

  calculateFrame(seekingTime) {
    const sprite = Math.floor(
      seekingTime / Utils.millisecondsToSeconds(
        this.globalSpriteSheet.interval,
      ),
    );
    const row = Math.floor(sprite / this.globalSpriteSheet.columns);
    const col = (sprite % this.globalSpriteSheet.columns);

    const yPosition = row * this.globalSpriteSheet.thumbnailHeight * this.globalSpriteSheet.coef;
    const xPosition = col * this.globalSpriteSheet.thumbnailWidth * this.globalSpriteSheet.coef;

    const thumb = this.el().querySelector('.vjs-thumbnail');

    thumb.style.left = `${-xPosition}px`;
    thumb.style.top = `${-yPosition}px`;
  }

  moveCancel() {
    setTimeout(() => {
      this.el().style.transform = 'translateX(-1000px)';
    }, 300);
  }

  hideBlockSegments(seekingTime) {
    const blockedSegment = SegmentService.isIntervalBlocked(this.player().options()
      .SRGProviders.mediaComposition.getMainSegments(), seekingTime);
    const placeHolder = this.el().querySelector('.vjs-icon-placeholder');

    if (blockedSegment && !placeHolder) {
      this.appendBlockingIcon(blockedSegment);
    }

    if (!blockedSegment && placeHolder) {
      this.removeBlockingIcon();
    }
  }

  appendBlockingIcon(blockedSegment) {
    const thumb = this.el().querySelector('.vjs-thumbnail');
    const blockedEl = ThumbnailSeeking.createBlockingIcon(blockedSegment);

    thumb.classList.add('vjs-thumbnail--blocked');
    this.el().appendChild(blockedEl);
  }

  removeBlockingIcon() {
    const thumb = this.el().querySelector('.vjs-thumbnail');

    thumb.classList.remove('vjs-thumbnail--blocked');
    this.el().removeChild(this.el().querySelector('.vjs-icon-placeholder'));
  }

  static createBlockingIcon({ blockReason }) {
    return videojs.dom.createEl('span', {
      className: `vjs-icon-placeholder ${BlockingReason.getIcon(blockReason)}`,
    });
  }

  createEl() {
    const div = super.createEl('div', {
      className: 'vjs-thumbnail-holder',
    });
    this.thumbnail = videojs.dom.createEl('img', {
      className: 'vjs-thumbnail',
    });
    div.appendChild(this.thumbnail);

    return div;
  }
}

videojs.registerComponent('ThumbnailSeeking', ThumbnailSeeking);
export default ThumbnailSeeking;
