import videojs from 'video.js';
import SRGMenuItem from '../../items/srg-menu-item.js';

const Button = videojs.getComponent('Button');
const SELECTED_ITEM = 'srg-menu__subtitles-size-selector__item--selected';
/**
 * LetterBox SRGMenu Subtitles Size Selector Component
 * @ignore
 */
class SRGSubtitlesMenuSizeItem extends SRGMenuItem {
  init() {
    this.items = [];
  }

  /**
    * Updates the input state when a new value is setted
    * @override
    */
  updateValue() {
    const fontLevel = this.getValue();
    this.items.forEach((item, index) => {
      if (index === fontLevel) {
        item.classList.add(SELECTED_ITEM);
      } else {
        item.classList.remove(SELECTED_ITEM);
      }
    });
  }

  createEl() {
    this.init();
    const fragment = document.createDocumentFragment();

    const { srgTextTrackSizes } = this.player().options();
    this.items = srgTextTrackSizes.map((_value, index) => {
      const item = this.createFontSizeEl(
        index,
        this.getValue() === index,
        ([e]) => {
          e.stopPropagation();
          this.dispatchValue(index);
        },
      );
      fragment.appendChild(item);
      return item;
    });

    const el = super.createEl('div', {
      className: 'srg-menu__subtitles-size-selector',
    });

    el.appendChild(fragment);

    return el;
  }

  createFontSizeEl(fontLevel, isSelected = false, listener) {
    const localizedText = this.localize('Font size level {1}', [fontLevel + 1]);
    const button = new Button(this.player(), {
      clickHandler: listener,
      el: videojs.dom.createEl('button', {
        className: `srg-menu__subtitles-size-selector__item size-selector-item--${fontLevel} ${isSelected ? 'srg-menu__subtitles-size-selector__item--selected' : ''}`,
      },
      {
        'aria-label': localizedText,
      },
      'A'),
    });

    button.createControlTextEl(button.el());
    button.controlText(localizedText);

    return button.el();
  }
}

videojs.registerComponent('SRGSubtitlesMenuSizeItem', SRGSubtitlesMenuSizeItem);
export default SRGSubtitlesMenuSizeItem;
