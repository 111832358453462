import videojs from 'video.js';
import Image from '../../utils/Image.js';
import MediaShare from '../../utils/MediaShare.js';
import Utils from '../../utils/Utils.js';

const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 *
 * @ignore
 */
class Item extends ClickableComponent {
  constructor(player, options) {
    super(player, options);

    this.itemInfo = options.itemInfo;
    this.updateChildren();
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} item`;
  }

  /**
   * Represents the description element that contains :
   * - The shows title.
   * - The shows name.
   * - The shows duration.
   * @return {HTMLDivElement}
   */
  static createDescriptionEl() {
    return videojs.dom.createEl('div',
      {
        className: 'item__description',
      });
  }

  /**
   * Represents the duration element
   * @return {HTMLDivElement}
   */
  createDurationEl() {
    return videojs.dom.createEl('div',
      {
        className: 'item__duration',
      },
      {},
      this.formatDuration());
  }

  createEl() {
    return super.createEl('a', {
      className: this.buildCSSClass(),
    });
  }

  /**
   * Represents the image element
   * @return {HTMLImageElement}
   */
  createImageEl() {
    return videojs.dom.createEl('img', {
      alt: this.itemInfo.imageTitle || this.itemInfo.title,
      className: 'item__img',
      src: Image.scale({
        url: this.itemInfo.imageUrl,
        width: Image.WIDTH_320,
      }),
    });
  }

  /**
   * Represents the shows name element
   * @return {HTMLParagraphElement}
   */
  createShowEl() {
    return videojs.dom.createEl('p', {
      className: 'item__show',
    },
    {},
    this.itemInfo.showTitle);
  }

  /**
   * Represents the title element
   * @return {HTMLParagraphElement}
   */
  createTitleEl() {
    return videojs.dom.createEl('p', {
      className: 'item__title',
    },
    {},
    this.itemInfo.title);
  }

  /**
   * Format the duration
   * @return {String} Like 0:47
   */
  formatDuration() {
    return videojs.formatTime(
      Utils.millisecondsToSeconds(this.itemInfo.duration),
    );
  }

  /**
   * Append children to the item component
   */
  updateChildren() {
    videojs.dom.setAttributes(this.el(), {
      'aria-label': this.itemInfo.title,
      href: `${MediaShare.redirectUrl(this.itemInfo)}`,
      title: this.itemInfo.title,
    });

    const fragment = document.createDocumentFragment();
    const descriptionEl = Item.createDescriptionEl();

    descriptionEl.appendChild(this.createTitleEl());
    descriptionEl.appendChild(this.createShowEl());
    descriptionEl.appendChild(this.createDurationEl());

    fragment.appendChild(this.createImageEl());
    fragment.appendChild(descriptionEl);

    this.el().appendChild(fragment);
    // Random animation delay
    this.el().style.transitionDelay = `${Math.random().toString()}s`;
  }
}

videojs.registerComponent('Item', Item);
export default Item;
