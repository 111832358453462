import videojs from 'video.js';
import SharingButtons from './sharing-buttons.js';
import SharingButton from './sharing-button.js';
import SharingOptions from './sharing-options.js';
import SRGSocialSharing from '../../../utils/SRGSocialSharing.js';
import Utils from '../../../utils/Utils.js';
import PlayerUtils from '../../../utils/PlayerUtils.js';
import * as Events from '../../../utils/Events.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBoxWeb social sharing component
 * @ignore
 */
class SharingContainer extends Component {
  constructor(player, options) {
    super(player, options);

    this.socialSharingStrategy = new SRGSocialSharing(
      player,
    );
    this.hide();

    this.createChildren();

    this.userInactive = this.userInactive.bind(this);
  }

  dispose() {
    this.player().off(Events.USER_INACTIVE, this.userInactive);

    super.dispose();
  }

  reset(socialSharing, shouldHideOptions) {
    this.socialSharing = socialSharing;

    if (shouldHideOptions) {
      this.getChild('sharingOptions').hide();
    } else {
      this.getChild('sharingOptions').show();
    }

    this.getChild('sharingOptions')
      .children()
      .forEach((option) => {
        if (option.name() === SRGSocialSharing.SHARING_MODES.FULL_LENGTH) {
          option.handleClick();
        }

        option.update();
      });
  }

  show() {
    this.removeClass('sharing-container--hidden');
    this.options().parent.addClass('sharing-module--opened');
    Utils.hideFromAccessibility(false, this.el());
    this.setFocusToFirstElement();

    this.player().on(Events.USER_INACTIVE, this.userInactive);
  }

  hide() {
    this.addClass('sharing-container--hidden');
    Utils.hideFromAccessibility(true, this.el());
    this.options().parent.removeClass('sharing-module--opened');

    PlayerUtils.reportUserActivity(this.player(), true);
  }

  /**
   * Sets the focus to thr first item
   */
  setFocusToFirstElement() {
    const nextFocusableItem = Utils.getKeyboardFocusableElements(this.el());
    if (nextFocusableItem && nextFocusableItem.length > 0) {
      nextFocusableItem[0].focus();
    }
  }

  createEl() {
    const el = super.createEl('div', {
      className: 'sharing-container sharing-container--hidden',
    });

    return el;
  }

  createChildren() {
    this.addChild(
      new SharingButton(
        this.player(),
        {
          name: 'close',
          controlText: this.localize('Close'),
          className: 'sharing-container__close',
          icon: 'close',
          onClick: () => {
            this.hide();
            this.options().parent.getChild('sharingButton').focus();
          },
        },
      ),
    );

    this.addChild(new Component(
      this.player(),
      {
        name: 'containerTitle',
        el: videojs.dom.createEl('h1', {
          className: 'sharing-container__title',
          innerHTML: this.localize('Share'),
        }),
      },
    ));

    this.addChild(new SharingOptions(
      this.player(),
      {
        name: 'sharingOptions',
        socialSharingStrategy: this.socialSharingStrategy,
      },
    ));

    this.addChild(new SharingButtons(
      this.player(),
      {
        socialSharing: () => this.socialSharing,
        socialSharingStrategy: this.socialSharingStrategy,
      },
    ));
  }

  userInactive() {
    if (!this.hasClass('sharing-container--hidden')) {
      PlayerUtils.reportUserActivity(this.player(), false);
    }
  }
}

Component.registerComponent('SharingContainer', SharingContainer);
export default SharingContainer;
