import videojs from 'video.js';
import * as PlayerEvents from '../../utils/PlayerEvents.js';
import * as SRGEvents from '../../utils/SRGEvents.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBoxWeb component for displayed
 * the current media title
 * @ignore
 */
class TitleComponent extends Component {
  constructor(player, options) {
    super(player, options);

    this.initListener();
  }

  initListener() {
    this.player()
      .on([
        PlayerEvents.LOAD_START,
        SRGEvents.MEDIACOMPOSITION_LOADED,
      ], this.updateTitle.bind(this));
  }

  updateTitle() {
    this.changeTitle(this.player()
      .options()
      .SRGProviders.mediaComposition
      .getMainChapter()
      .title);
  }

  createEl() {
    const el = super.createEl('h1', {
      className: this.buildCSSClass(),
    });

    return el;
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srg-title`.trim();
  }

  changeTitle(title) {
    videojs.dom.textContent(this.el(), title);
  }
}

Component.registerComponent('TitleComponent', TitleComponent);
export default TitleComponent;
