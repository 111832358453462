/* eslint no-param-reassign: ["error", { "props": false }] */
import * as Events from './Events.js';
import * as SRGSettings from './SRGSettings.js';
import PlayerUtils from './PlayerUtils.js';
import AudioTracksUtils from './AudioTracksUtils.js';

const ITEMS_UPDATE_STRATEGIES = {
  continuousPlayback: (value, { player, ConfigurationHandler }) => ConfigurationHandler
    .setSetting(player, SRGSettings.CONTINUOUS_PLAYBACK, value),

  playbackRate: (value, { player, ConfigurationHandler }) => {
    ConfigurationHandler.setSetting(player, SRGSettings.PLAYBACK_RATE, value);
  },

  audioDescription: (value, { player, ConfigurationHandler }) => {
    const audioTracks = Array.from(player.audioTracks());
    const currentTrack = PlayerUtils.audioTrackLanguage(undefined, player);
    let target;

    if (value) {
      const ADTracks = audioTracks.filter(({ kind }) => AudioTracksUtils.isAudioDescription(kind));
      target = ADTracks
        .find(({ language }) => language === currentTrack.language) || ADTracks.shift();
    } else {
      const regularTracks = audioTracks
        .filter(({ kind }) => !AudioTracksUtils.isAudioDescription(kind));
      target = regularTracks
        .find(({ language }) => language === currentTrack.language) || regularTracks.shift();
    }

    ConfigurationHandler.setSetting(
      player,
      SRGSettings.AUDIO_TRACK_LANGUAGE,
      PlayerUtils.audioTrackLanguage({
        language: target.language,
        description: AudioTracksUtils.isAudioDescription(target.kind),
      }, player),
    );
  },

  audioTrackLanguage: (value, { player, ConfigurationHandler }) => {
    ConfigurationHandler.setSetting(
      player,
      SRGSettings.AUDIO_TRACK_LANGUAGE,
      PlayerUtils.audioTrackLanguage(value, player),
    );
  },

  autoplay: (value, { player, ConfigurationHandler }) => {
    player.autoplay(value);
    ConfigurationHandler.setSetting(player, SRGSettings.AUTOPLAY, value);
  },

  textTrackLanguage: (value, { player, ConfigurationHandler }) => {
    ConfigurationHandler.setSetting(player, SRGSettings.TEXT_TRACK_LANGUAGE, value);
  },

  textTrackSizeLevel: (value, { player, ConfigurationHandler }) => {
    PlayerUtils.textTrackSizeLevel(value, player);
    ConfigurationHandler.setSetting(player, SRGSettings.TEXT_TRACK_SIZE_LEVEL, value);
  },

  hd: (value, { player, ConfigurationHandler }) => ConfigurationHandler
    .setSetting(player, SRGSettings.HD, value),
};

class SRGMenuStrategy {
  constructor(player, ConfigurationHandler) {
    player.on(Events.MENU_VALUE_UPDATED, ({ data }) => {
      const [[property, value]] = Object.entries(data);
      SRGMenuStrategy.updateProperty({ property, value }, player, ConfigurationHandler);
    });

    player.one(Events.FIRST_PLAY, () => {
      player.on(Events.VOLUME_CHANGE, () => {
        ConfigurationHandler.setSetting(player, SRGSettings.VOLUME, player.volume());
        ConfigurationHandler.setSetting(player, SRGSettings.MUTED, player.muted());
      });
    });
  }

  static updateProperty({ property, value }, player, ConfigurationHandler) {
    const updateStrategy = ITEMS_UPDATE_STRATEGIES[property];

    if (updateStrategy) {
      updateStrategy(value, { ConfigurationHandler, player });
    }
  }
}

export default SRGMenuStrategy;
