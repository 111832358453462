import { CLICK, ON_ESC_PRESSED, MENU_VALUE_UPDATED } from './Events.js';
import * as KeyCodes from './KeyCodes.js';
import PlayerUtils from './PlayerUtils.js';
import { containQualities, isSourceHD } from './SRGQuality.js';
import SRGStreamType from './SRGStreamType.js';
import Utils from './Utils.js';

const KEYBOARD_NAVIGATION_CLASS = 'lbw-keyboard-navigation';
const IS_NUMBER = ({ key }) => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(key, 10));
const seek = (player, time) => {
  const { streamType } = player.currentSource();

  if (!(SRGStreamType.isDvr(streamType)
    || SRGStreamType.isOnDemand(streamType))) {
    return;
  }

  // to be analytics compliant
  player.scrubbing(true);
  player.trigger('seeking');

  player.currentTime(time);

  player.scrubbing(false);
  player.trigger('seeked');
};

const setVolume = (player, vol) => {
  const volume = player.volume() + vol;

  player.volume(volume);
  player.muted(player.volume() <= 0);
};
const switchQuality = (player) => {
  const { mediaComposition } = player.options().SRGProviders;

  if (containQualities(mediaComposition.getMainResources())) {
    player.trigger({
      type: MENU_VALUE_UPDATED,
      data: {
        hd: !isSourceHD(player.currentSource()),
      },
    });
  }
};
const togglePlay = (player) => {
  if (player.paused()) {
    player.play();
  } else {
    player.pause();
  }
};

export default function (event) {
  this.handleHotkeys(event);

  if (event.which !== KeyCodes.TAB) {
    event.preventDefault();
    event.stopPropagation();
  }

  // ESC: go back in menu or close menu
  if (event.which === KeyCodes.ESCAPE) {
    this.trigger(ON_ESC_PRESSED);
    return;
  }
  // Arrow up, + key : volume up
  if ([KeyCodes.ARROW_UP, KeyCodes.PLUS].includes(event.key)) {
    setVolume(this, 0.1);
    return;
  }
  // Arrow down, - key : volume down
  if ([KeyCodes.ARROW_DOWN, KeyCodes.HYPHEN].includes(event.key)) {
    setVolume(this, -0.1);
    return;
  }

  // Arrow left : backwards
  if (event.key === KeyCodes.ARROW_LEFT && this.currentTime() > 0) {
    seek(this, this.currentTime() - 10);
    return;
  }

  // Arrow right : forwards
  if (event.key === KeyCodes.ARROW_RIGHT && this.currentTime() < this.duration()) {
    seek(this, this.currentTime() + 30);
    return;
  }

  // Tab : enable keyboard navigation
  if (event.which === KeyCodes.TAB && !this.hasClass(KEYBOARD_NAVIGATION_CLASS)) {
    const handleMouseClickNavigation = ({ screenX = 0, screenY = 0 }) => {
      if (screenX !== 0 && screenY !== 0) {
        this.removeClass(KEYBOARD_NAVIGATION_CLASS);
        this.off(CLICK, handleMouseClickNavigation);
      }
    };

    this.addClass(KEYBOARD_NAVIGATION_CLASS);
    this.on(CLICK, handleMouseClickNavigation);
    return;
  }

  // Numbers : seek in the media
  if (IS_NUMBER(event)) {
    const key = (event.key | 0) / 10;
    seek(this, this.duration() * key);
    return;
  }

  // Q : switch beetween SD and HD
  if (event.which === KeyCodes.Q) {
    switchQuality(this);
    return;
  }

  // p : toggle play/pause
  if (event.which === KeyCodes.P) {
    togglePlay(this);
  }

  // Shift + D : debug
  if (event.which === KeyCodes.D && event.shiftKey === true) {
    const debugInfo = PlayerUtils.getDebugInformation(this);
    Utils.copyTextToClipboard(Utils.prettyStringify(debugInfo));
  }
}
