import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 *
 * @ignore
 */
class ReplayButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.show();
  }

  buildCSSClass() {
    return `srg-replay-button vjs-play-control vjs-control vjs-button vjs-paused vjs-ended ${super.buildCSSClass()}`;
  }

  handleClick() {
    this.player().play();
  }

  createEl() {
    return super.createEl('button', {
      className: this.buildCSSClass(),
    });
  }
}

videojs.registerComponent('ReplayButton', ReplayButton);
export default ReplayButton;
