/**
 * Videojs events
 * @see https://github.com/videojs/video.js/blob/v7.7.4/src/js/player.js#L1139
 *
 * videojs-contrib-quality-levels
 * @see https://github.com/videojs/videojs-contrib-quality-levels
 *
 * @namespace {Object} VideojsEvents
 */

export const BEFOREMODALCLOSE = 'beforemodalclose';
export const DISPOSE = 'dispose';
export const FIRST_PLAY = 'firstplay';
export const MODALCLOSE = 'modalclose';
export const MODALOPEN = 'modalopen';
export const MODE_CHANGE = 'modechange';
export const PLAYER_RESIZE = 'playerresize';
export const READY = 'ready';
export const TAP = 'TAP';
export const USER_ACTIVE = 'useractive';
export const USER_INACTIVE = 'userinactive';
export const QUALITY_CHANGE = 'qualityChange';
