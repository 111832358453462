import Utils from '../../utils/Utils.js';
import * as SRGEvents from '../../utils/SRGEvents.js';

const CONFIDENCE_INTERVAL = 1000;

/**
 * Handle segment change check and blocked segment skipping.
 *
 * Does not register to player events,
 * must be called by a setter / mediator such as a videojs middleware.
 *
 * Three public APIs:
 *  - skipIfInBlockedSegment
 *  - getValidTime
 *  - checkSegmentChange
 *
 * @ignore
 */
class SegmentService {
  constructor(player, segments = []) {
    this.blockedSegments = [];
    this.currentSegment = undefined;
    this.playableSegments = [];
    this.player = player;
    this.segments = segments;

    this.extractBlockedSegments();
    this.extractPlayableSegments();
  }

  /**
   * Compare a segment markIn / markOut with a time
   *
   * @param segment segment item with markIn / markOut in milliseconds
   * @param time time in seconds
   * @returns {boolean}
   */
  static doesSegmentContainTime(segment, time) {
    return time >= Utils.millisecondsToSeconds(segment.markIn)
      && time < Utils.millisecondsToSeconds(segment.markOut);
  }

  /**
   * Determine if the inverval is blocked.
   *
   * @param {Array} segments
   * @param {Number} time  in seconds
   * @returns {Object}
   */
  static isIntervalBlocked(segments = [], time) {
    const [blockedSegment] = segments
      .filter(segment => segment.blockReason)
      .filter(
        segment => SegmentService.doesSegmentContainTime(segment, time),
      );

    return blockedSegment;
  }

  /**
   * Set blocked segments property.
   */
  extractBlockedSegments() {
    this.blockedSegments = this.segments.filter(segment => segment.blockReason);
  }

  /**
   * Set playable segments property.
   */
  extractPlayableSegments() {
    this.playableSegments = this.segments
      .filter(segment => !segment.blockReason);
  }

  /**
   * Find a blocked segment by time.
   *
   * @param {Number} time in seconds
   * @returns {Object} blocked segment
   */
  getBlockedSegment(time) {
    const [blockedSegments] = this.blockedSegments.filter(
      segment => SegmentService.doesSegmentContainTime(segment, time),
    );

    return blockedSegments;
  }

  /**
   * Find a playable segment by time.
   *
   * @param {Number} time in seconds
   * @returns {Object} playable segment
   */
  getPlayableSegment(time) {
    const playableSegment = this.playableSegments.filter(
      segment => SegmentService.doesSegmentContainTime(segment, time),
    ).pop();

    return playableSegment;
  }

  /**
   * Determine if a blocked segment has been reached and skip over if necessary.
   *
   * @param {Number} time
   * @returns {Number} player time, updated if necessary
   */
  skipIfInBlockedSegment(time) {
    let updatedTime = time;
    const blockedSegment = this.getBlockedSegment(time);

    if (blockedSegment) {
      updatedTime = SegmentService.endOfSegmentInSeconds(blockedSegment);

      this.player.currentTime(updatedTime);
      this.triggerBlockedSegmentSkipped(blockedSegment);
    }

    return updatedTime;
  }

  /**
   * Get a valid time to seek to (a time not included in any blocked segment).
   *
   * @param {Number} time time in seconds
   */
  getValidTime(time) {
    const blockedSegment = this.getBlockedSegment(time);

    if (blockedSegment) {
      return this.getValidTime(SegmentService.endOfSegmentInSeconds(blockedSegment));
    }

    return time;
  }

  /**
   * Position to seek to in order to skip over the segment.
   *
   * @param {Object} segment
   * @returns {Number} seconds
   */
  static endOfSegmentInSeconds(segment) {
    return Utils.millisecondsToSeconds(segment.markOut + CONFIDENCE_INTERVAL);
  }

  /**
   * Check current segment and trigger a segment change event if necessary.
   *
   * @param {Number} currentTime
   */
  checkSegmentChange(currentTime) {
    if (this.currentSegment !== this.getPlayableSegment(currentTime)) {
      this.currentSegment = this.getPlayableSegment(currentTime);

      this.triggerSegmentChange();
    }
  }

  /**
   * Trigger a block reason event.
   *
   * @param {Object} currentSegment
   */
  triggerBlockedSegmentSkipped(currentSegment) {
    if (currentSegment) {
      this.player.trigger({
        type: SegmentService.BLOCKED_SEGMENT_SKIPPED,
        data: currentSegment.blockReason,
      });
    }
  }

  /**
   * Trigger a segment change event.
   */
  triggerSegmentChange() {
    if (this.currentSegment) {
      this.player.trigger({
        type: SRGEvents.SEGMENT_CHANGE,
        data: this.currentSegment.urn,
      });
    }
  }

  static get BLOCKED_SEGMENT_SKIPPED() {
    return SRGEvents.BLOCKED_SEGMENT_SKIPPED;
  }

  static get SEGMENT_CHANGE() {
    return SRGEvents.SEGMENT_CHANGE;
  }
}

export default SegmentService;
