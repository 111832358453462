import videojs from 'video.js';
import SharingSocialAction from './sharing-social-action.js';
import Utils from '../../../../utils/Utils.js';

/**
 * LetterBoxWeb sharing button component to copy link
 * @ignore
 */
class SharingCopyLinkAction extends SharingSocialAction {
  constructor(player, options) {
    super(player, options);
    this.flashMessage = 'Copied';
  }

  triggerAction() {
    Utils.copyTextToClipboard(this.options().href());
  }
}

videojs.registerComponent('SharingCopyLinkAction', SharingCopyLinkAction);
export default SharingCopyLinkAction;
