import videojs from 'video.js';
import Utils from '../../../../../utils/Utils.js';
import PlayerUtils from '../../../../../utils/PlayerUtils.js';

const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');
const FLASH_DURATION = 1500;

/**
 * LetterBox debug menu item component
 * @ignore
 */
class SettingDebugMenuItem extends Component {
  createEl() {
    const fragment = document.createDocumentFragment();
    const labelEl = this.createLabelEl();
    const linkEl = this.createLinkEl();


    fragment.appendChild(labelEl);
    fragment.appendChild(linkEl);


    const el = super.createEl('div', {
      className: 'srg-menu__panel-item',
    });

    el.appendChild(fragment);

    return el;
  }


  createLabelEl() {
    return videojs.dom.createEl('span',
      { className: 'srg-menu__panel-item__label' },
      {},
      this.localize(this.options().label));
  }

  createLinkEl() {
    const innerText = Utils.capitalizeFirstLetter(this.localize('copy'));
    const setTarget = (target, value) => {
      const element = target;

      element.innerText = value;
      element.setAttribute('aria-label', value);
    };

    return new Button(this.player(), {
      name: 'settingDebugButton',
      clickHandler([e]) {
        e.preventDefault();

        setTarget(e.target, this.localize('Copied'));

        Utils.copyTextToClipboard(
          Utils.prettyStringify(PlayerUtils.getDebugInformation(this.player())),
        );

        this.setTimeout(() => {
          setTarget(e.target, innerText);
        }, FLASH_DURATION);
      },
      el: videojs.dom.createEl('button', {
        className: 'srg-menu__panel-item__link',
        innerText,
      },
      {
        tabindex: 0,
        role: 'button',
        'aria-label': innerText,
      }),
    }).el();
  }
}

videojs.registerComponent('SettingDebugMenuItem', SettingDebugMenuItem);
export default SettingDebugMenuItem;
