import videojs from 'video.js';

class Utils {
  static capitalizeFirstLetter(string) {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  }

  static getEnvironment() {
    return !window.location.origin
      .match(/localhost|test|stage|github/) ? 'prod' : 'preprod';
  }

  /**
   * Elapsed time in seconds since midnight
   *
   * @returns {number}
   */
  static getSecondsSinceMidnight() {
    const date = new Date();

    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  }


  /**
   * Return the date between the range startDate ... endDate.
   *
   * @param {Date} date
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {Date}
   */
  static dateWithinRange(date, startDate, endDate) {
    if (date >= startDate && date <= endDate) {
      return date;
    }

    return date < startDate ? startDate : endDate;
  }

  /**
   * Return the date within the range of the live window.
   *
   * @param {Date} date
   * @param {Number} liveWindow
   * @returns {Date}
   */
  static dateWithinLiveWindow(date, liveWindow) {
    const startDate = new Date(
      Date.now() - Utils.secondsToMilliseconds(liveWindow),
    );
    const endDate = new Date();

    return Utils.dateWithinRange(date, startDate, endDate);
  }

  /**
   * Formats a string from camel case to kebab case.
   *
   * @example
   * //return, an-nice-example
   * formatKebab('anNiceExample');
   *
   * @param {String} value A camel case string.
   *
   * @returns {string} Kebab case formatted value
   */
  static formatKebab(value) {
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase();
  }

  /**
   * Formats seconds as a time string (H:MM:SS or M:SS). Supplying a guide (in seconds) will force a number of leading zeros to cover the length of the guide.
   *
   * @example
   * //return, 0:10
   * formatTime(10);
   *
   * @example
   * //return, 0:00:10
   * formatTime(10, 6000);
   *
   * @see https://docs.videojs.com/module-format-time.html
   *
   * @param {Number} time Number of seconds to be turned into a string
   * @param {Number} guide Number (in seconds) to model the string after, default value 600
   *
   * @returns {string} Time formatted as H:MM:SS or M:SS
   */
  static formatTime(time, guide = 600) {
    return videojs.formatTime(time, guide);
  }

  /**
   * Convert milliseconds to seconds.
   *
   * @param {Number} milliseconds
   * @returns {Number} seconds
   */
  static millisecondsToSeconds(milliseconds) {
    return milliseconds === 0 ? 0 : (milliseconds / 1000);
  }

  /**
   * Gives the number of seconds elapsed since midnight for a maximum period of 24 hours.
   *
   * @param {Number} secondsBehindLiveEdge
   *
   * @returns {Number}
   */
  static normalizeElapsedTimeSinceMidnight(secondsBehindLiveEdge) {
    const NUMBER_OF_SECONDS_IN_A_DAY = 86400;
    const secondesSinceMidnight = Utils.getSecondsSinceMidnight();
    const time = secondesSinceMidnight - secondsBehindLiveEdge;

    return time < 0 ? NUMBER_OF_SECONDS_IN_A_DAY + time : time;
  }

  /**
   * Convert seconds to milliseconds.
   *
   * @param {Number} seconds
   * @returns {Number} milliseconds
   */
  static secondsToMilliseconds(seconds) {
    return seconds * 1000;
  }

  /**
   * Copy a text to the clipboard
   * We are using this hack because navigator.clipboard.writeText is not
   * available in most of all supported browsers
   *
   * @param {String} text to copy in the clipboard
   */
  static copyTextToClipboard(text) {
    const currentFocus = document.activeElement;
    const tmpInput = document.createElement('textarea');
    tmpInput.value = text;
    document.body.appendChild(tmpInput);
    tmpInput.select();
    document.execCommand('copy');
    document.body.removeChild(tmpInput);
    currentFocus.focus();
  }

  /**
   * Remove undefined values from an object.
   *
   * @param {Object} obj - to be cleaned
   */
  static removeUndefinedValuesFromObject(obj) {
    const cleanedObject = videojs.mergeOptions({}, obj);

    Object
      .keys(cleanedObject)
      .forEach(key => cleanedObject[key] === undefined && delete cleanedObject[key]);

    return cleanedObject;
  }

  /**
 * Gets keyboard-focusable elements within a specified element
 * @param {HTMLElement} [element=document] element
 * @returns {Array}
 */
  static getKeyboardFocusableElements(element = document) {
    return [...element.querySelectorAll(
      'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    )]
      .filter(el => !el.hasAttribute('disabled'))
      .filter(el => !el.hasAttribute('hidden'));
  }

  /**
   * Pretty stringify a JSON object.
   *
   * @param {*} obj
   * @returns {string} Formatted JSON string
   */
  static prettyStringify(obj) {
    return JSON.stringify(
      obj,
      null,
      2,
    );
  }

  /**
   * Truncate text to a specific length
   *
   * @param {String} text
   * @param {Int} length default length is set to 50
   */
  static truncateText(text, length = 50) {
    const trimedText = text.trim();

    if (text.length < length) {
      return trimedText;
    }

    const truncateText = trimedText.substring(0, length).trim();

    return `${truncateText}...`;
  }

  /**
   * Hides or show the element from accessibility features
   * @param {Boolean} isHidden Defines if the element should be hidden from accessibility features
   * @param {HTMLElement} el
   */
  static hideFromAccessibility(isHidden, el) {
    el.setAttribute('aria-hidden', isHidden);
    if (isHidden) {
      el.setAttribute('hidden', isHidden);
    } else {
      el.removeAttribute('hidden');
    }
  }

  /**
   * Allows to listen to only one event within a list of multiple event types.
   *
   * @param {Videojs.Component} target
   * @param {Array} evtArray
   * @param {Function} fn
   */
  static listenAtLeastOnce(target, evtArray, fn) {
    const callback = (e) => {
      fn(e);
      target.off(evtArray, callback);
    };
    target.on(evtArray, callback);
  }

  /**
   * Format a number by rounding to a specified number of decimals.
   *
   * @param {Number} number The number to format.
   * @param {Int} digits The number of digits to appear after the decimal point.
   * @returns {Number}
   */
  static numberFormat(number, digits) {
    return Number.parseFloat(number.toFixed(digits));
  }
}

export default Utils;
