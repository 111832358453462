import videojs from 'video.js';
import ERRORTYPE from '../../errors/errors.js';
import SegmentService from '../../dataProvider/services/SegmentService.js';
import SRGLetterboxConfiguration from '../../utils/SRGLetterboxConfiguration.js';
import * as SRGSettings from '../../utils/SRGSettings.js';
import * as Events from '../../utils/Events.js';

const ModalDialog = videojs.getComponent('ModalDialog');
/**
 * Show a Warning message in top of the player
 * @ignore
 */
class WarningMessageComponent extends ModalDialog {
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      pauseOnOpen: false,
      uncloseable: true,
      fillAlways: true,
      temporary: false,
    }, options);

    super(player, opt);

    this.initListeners();
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-warning-message`;
  }

  /* eslint-disable */
  close() {
    if (!this.opened()) {
      return;
    }

    this.trigger(Events.BEFOREMODALCLOSE);
    this.opened_ = false;

    this.hide();
    this.el().setAttribute('aria-hidden', 'true');

    this.trigger(Events.MODALCLOSE);
    this.conditionalBlur_();

    if (this.options_.temporary) {
      this.dispose();
    }
  }
  /* eslint-enable */

  initListeners() {
    this.on(this.player(), SegmentService.BLOCKED_SEGMENT_SKIPPED, ({ data: reason }) => {
      const errorType = ERRORTYPE[`ERROR_BLOCKING_REASON_${reason}`];
      if (errorType) {
        this.display(
          this.localize(errorType.message),
          undefined,
          SRGLetterboxConfiguration.getSetting(this.player(), SRGSettings.WARNING_MESSAGE_TIMEOUT),
        );
      }
    });
  }

  /* eslint-disable */
  open() {
    if (!this.opened()) {
      this.opened_ = true;

      this.show();
      this.conditionalFocus_();
      this.el().setAttribute('aria-hidden', 'false');

      this.trigger(Events.MODALOPEN);
      this.hasBeenOpened_ = true;
    }
  }
  /* eslint-enable */

  /**
   * Show the warning message
   *
   * @param {String} content
   * @param {String} className
   * @param {Int} timeout
   */
  display(content = '', className = 'warning-message__text', timeout = 6000) {
    videojs.dom.addClass(this.contentEl(), className);

    this.fillWith(content);
    this.open();

    setTimeout(this.close.bind(this), timeout);
  }
}

videojs.registerComponent('WarningMessageComponent', WarningMessageComponent);
export default WarningMessageComponent;
