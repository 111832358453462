import videojs from 'video.js';

const VJSPictureInPictureToggle = videojs.getComponent('PictureInPictureToggle');

/**
 * LetterBoxWeb player component, improving the original component
 * by hiding it when disabled
 * @ignore
 */
class PictureInPictureToggle extends VJSPictureInPictureToggle {
  enable() {
    super.enable();
    this.show();
  }

  disable() {
    super.disable();
    this.hide();
  }
}

videojs.registerComponent('PictureInPictureToggle', PictureInPictureToggle);
export default PictureInPictureToggle;
