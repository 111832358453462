import BlockingReason from '../../utils/BlockingReason.js';
import * as Events from '../../utils/Events.js';
import PlayerUtils from '../../utils/PlayerUtils.js';

/** Update period used while playing or waiting for content to be valid. */
const PERIOD = 15000;
/** Update period used when content is supposed to be valid. */
const SOON_VALID_PERIOD = 3000;

/**
 * Periodic Listener monitoring changes in media composition.
 * @ignore
 */
export default class MediaCompositionPeriodicListener {
  constructor(urn, standalone, player) {
    this.chapterUrn = urn;
    this.standalone = standalone;
    this.player = player;
    this.log = player.log;
    player.on([Events.ENDED, Events.DISPOSE], this.stop.bind(this));
  }

  start() {
    this.scheduleCall();
  }

  stop() {
    this.chapterUrn = null;
    window.clearTimeout(this.timeoutId);
  }

  periodicCall() {
    const { SRGProviders: { mediaComposition, dataService } = {} } = this.player.options();

    if (mediaComposition.chapterUrn !== this.chapterUrn) {
      this.stop();
      return;
    }

    dataService
      .getMediaCompositionByUrn(
        this.chapterUrn,
        this.standalone,
      )
      .then(({ mediaComposition: fetchedMediaComposition }) => {
        this.log.debug('Handle new mediacomposition');
        this.handleMediaComposition(fetchedMediaComposition);
      })
      .catch(() => {
        // We ignore network and server errors
        this.scheduleCall();
      });
  }

  scheduleCall() {
    const { mediaComposition } = this.player.options().SRGProviders;

    const timeUntilValid = mediaComposition.getMainValidFromDate() - new Date();
    let period;
    if (mediaComposition.getMainBlockReason() === BlockingReason.STARTDATE && timeUntilValid < 0) {
      period = SOON_VALID_PERIOD;
    } else if (timeUntilValid > 0 && timeUntilValid < PERIOD) {
      period = timeUntilValid;
    } else {
      period = PERIOD;
    }
    this.timeoutId = window.setTimeout(this.periodicCall.bind(this), period);
  }

  handleMediaComposition(fetchedMediaComposition) {
    const { mediaComposition: currentMediaComposition } = this.player.options().SRGProviders;

    this.player.options({
      SRGProviders: {
        mediaComposition: fetchedMediaComposition,
      },
    });

    this.player.trigger({
      type: Events.MEDIACOMPOSITION_LOADED,
      data: { mediaComposition: fetchedMediaComposition },
    });

    const currentBlockReason = currentMediaComposition.getMainBlockReason();
    const fetchedBlockReason = fetchedMediaComposition.getMainBlockReason();

    if (!currentBlockReason && fetchedBlockReason) {
      this.log.warn('Stop because of a new block reason');
      this.errorAndStopPlayback(BlockingReason.toError(fetchedBlockReason));
      return;
    }

    if (currentBlockReason !== fetchedBlockReason) {
      this.log.warn('Reload because of block reason change');
      this.reload(fetchedMediaComposition);
      return;
    }

    this.scheduleCall();
  }

  reload(mediaComposition) {
    const { playbackSettings } = this.player.currentSource();
    const urn = mediaComposition.segmentUrn || mediaComposition.chapterUrn;

    PlayerUtils.loadMedia(this.player, {
      autoplay: true,
      mediaComposition,
      periodicReload: true,
      playbackSettings,
      urn,
    });
  }

  errorAndStopPlayback(error) {
    this.player.reset();
    this.player.error(error);
    this.stop();

    PlayerUtils.loadMedia(this.player, { urn: PlayerUtils.getUrn(this.player) });
  }
}
