const VENDOR_TO_HOSTNAME = {
  srf: 'srf',
  rsi: 'rsi',
  rtr: 'rtr',
  rts: 'rts',
  swi: 'play.swissinfo',
};

class MediaShare {
  /**
   * Generate the embed URL.
   *
   * @param {Object} subdivision
   * @param {String} subdivision.vendor
   * @param {String} subdivision.urn
   * @param {Number|undefined} startTime
   *
   * @returns {String}
   */
  static embedUrl({ urn, vendor }, startTime = undefined) {
    const baseOriginUrl = MediaShare.getBaseOriginByVendor(vendor);

    if (!baseOriginUrl) {
      return undefined;
    }

    const url = new URL('play/embed', baseOriginUrl);

    url.searchParams.set('urn', urn);

    if (startTime) {
      url.searchParams.set('startTime', startTime);
    }

    return decodeURIComponent(url.href);
  }

  /**
   * Generate the redirect URL for radio or tv media.
   *
   * @param {Object} subdivision
   * @param {String} subdivision.id
   * @param {String} subdivision.vendor
   * @param {String} subdivision.mediaType
   * @param {String} subdivision.urn
   * @param {Number} startTime
   *
   * @returns {String}
   */
  static redirectUrl({
    id, vendor, mediaType, urn,
  }, startTime = undefined) {
    const baseOriginUrl = MediaShare.getBaseOriginByVendor(vendor);

    if (!baseOriginUrl) {
      return undefined;
    }

    const {
      radioUrl,
      videoUrl,
    } = MediaShare;
    const isAudio = mediaType === 'AUDIO';
    const url = isAudio ? radioUrl({ id, mediaType, baseOriginUrl })
      : videoUrl({ urn, mediaType, baseOriginUrl });

    if (startTime) {
      url.searchParams.set('startTime', startTime);
    }

    return decodeURIComponent(url.href);
  }

  /**
   * Get BU's hostname by vendor
   *
   * @param {String} vendor
   *
   * @returns {String|undefined}
   */
  static getBaseOriginByVendor(vendor) {
    if (!vendor) {
      return undefined;
    }

    const bu = VENDOR_TO_HOSTNAME[vendor.trim().toLowerCase()];

    if (bu) {
      const subdomain = !bu.includes('.') ? 'www.' : '';

      return new URL(`https://${subdomain}${bu}.ch`);
    }

    return undefined;
  }

  /**
   * Get Play media type
   *
   * @param {String} mediaType
   *
   * @returns {String|undefined}
   */
  static getPlayMediaType(mediaType) {
    if (mediaType === 'VIDEO') {
      return 'tv';
    }

    if (mediaType === 'AUDIO') {
      return 'radio';
    }

    return undefined;
  }

  /**
   * Generate the media URL.
   *
   * @param {Object} urlOptions
   * @param {String} urlOptions.mediaType
   * @param {String} urlOptions.pathName
   * @param {String} urlOptions.baseOriginUrl
   *
   * @returns {URL}
   */
  static mediaUrl({ mediaType, pathName, baseOriginUrl }) {
    const playMediaType = MediaShare.getPlayMediaType(mediaType);

    return new URL(`play/${playMediaType}/${pathName}`, baseOriginUrl);
  }

  /**
   * Generate the radio URL.
   *
   * @param {Object} urlOptions
   * @param {String} urlOptions.id
   * @param {String} urlOptions.mediaType
   * @param {String} urlOptions.baseOriginUrl
   *
   * @returns {URL}
   */
  static radioUrl({ id, mediaType, baseOriginUrl }) {
    return MediaShare.mediaUrl({
      mediaType,
      pathName: `redirect/detail/${id}`,
      baseOriginUrl,
    });
  }

  /**
   * Generate the video URL.
   *
   * @param {Object} urlOptions
   * @param {String} urlOptions.urn
   * @param {String} urlOptions.mediaType
   * @param {String} urlOptions.baseOriginUrl
   *
   * @returns {URL}
   */
  static videoUrl({ urn, mediaType, baseOriginUrl }) {
    const url = MediaShare.mediaUrl({
      mediaType,
      pathName: `-/${mediaType.toLowerCase()}/-`,
      baseOriginUrl,
    });

    url.searchParams.set('urn', urn);

    return url;
  }
}

export default MediaShare;
