import videojs from 'video.js';
import EndScreenFeature from './end-screen-feature.js';
import ReplayButton from './replay-button.js';

/**
 *
 * @ignore
 */
class Replay extends EndScreenFeature {
  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-replay`;
  }

  createEl() {
    const container = document.createDocumentFragment();
    const className = this.buildCSSClass();
    const el = super.createEl('div', {
      className,
    });

    this.replayBtn = new ReplayButton(this.player());

    container.appendChild(this.replayBtn.contentEl());

    el.appendChild(container);

    return el;
  }
}

videojs.registerComponent('Replay', Replay);
export default Replay;
