/**
 *
 * Performance report can be used to:
 * - monitor performance of playback start phase from the user point of view
 * - monitor performance of each part (servers or client side process) of the playback experience
 * - monitor error levels that could come from content or integration
 * - debug some errors coming from servers (IL, Token, DRM license provider)
 *
 * We only consider the starting phase of the playback. Akamai media streaming takes care of the monitoring during stream playback for.
 *
 * ```
 *
 *                                 ┌─────────────────┐
 *                                 │                 │
 *       ┌─────────────────┐       │  The media URL  │
 *       │  Letterbox is   │       │received from the│                              ┌──────────────────┐
 *       │ asked to play a │       │  IL is played   │                              │  Media playback  │
 *       │      media      │       │                 │                              │      starts      │
 *       └─────────────────┘       └─────────────────┘                              └──────────────────┘
 *                │                         │                                                 │
 *                │                         │                                                 │
 *                │                         │                                                 │
 *                │                         │    ┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─        │
 *                │                         │            playerResult.duration        │┌──────┘
 *                │                         │    └ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ │
 *                │                         │    ┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─               │
 *                │   ┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┐ │      tokenResult.duration │              │
 *  ──────────────▼──▲─ ilResult.duration ──▼───▲┤  drmResult.duration  ───────▲───────▼──────────────────▶
 *                │  │└ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┘     │ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┘ ┌────┘       │                 time
 *                   │                          │                         │
 *                │  │                          │                         │            │
 *                   └────────────┐             └──────────┐     ┌─────────────────┐
 *                │               │                        │     │ Media buffering │   │
 *                                │                        │     │     starts      │
 *                │               │                        │     └─────────────────┘   │
 *                                │                        │
 *                │      ┌─────────────────┐       ┌───────────────┐                   │
 *                       │  The media is   │       │  Token / DRM  │
 *                │      │ requested from  │       │keys retrieval │                   │
 *                       │     the IL      │       │    starts     │
 *                │      └─────────────────┘       └───────────────┘                   │
 *
 *                │                                                                    │
 *
 *                │                                                                    │
 *                 ◀──────────────────────────────────────────────────────────────────▶
 *                                         duration in case of
 *                                         successful playback
 *
 * ```
 *
 * @class PerformanceReport
 * @ignore
 *
 * Measure the player loading time
 *
 * __Performance report reference schema__
 * ```
 * performanceReport: {
 *         browser: platformResolver.browser().name + "/" + platformResolver.browser().version,
 *         //Client first request time
 *         clientTime: new Date().toISOString(),
 *         device: window.navigator.userAgent,
 *         drmResult: {
 *             duration: null,
 *             errorMessage: null,
 *             url: null,
 *             httpStatusCode: null
 *         },
 *         duration: null,
 *         //Pre-prod, prod
 *         environment: null,
 *         ilResult: {
 *             duration: null,
 *             errorMessage: null,
 *             url: null,
 *             blockReason: null,
 *             varnish: null,
 *             playableAbroad: null,
 *             noPlayableResourceFound: null,
 *             httpStatusCode: null
 *         },
 *         networkType: null,
 *         platform: navigator.platform,
 *         player: null,
 *         playerResult: {
 *             duration: null,
 *             errorMessage: null,
 *             url: null
 *         },
 *         referrer: document.referrer || null,
 *         screenType: "local", //Represents the local player.
 *         tokenResult: {
 *             duration: null,
 *             errorMessage: null,
 *             url: null, //Generated URL
 *             httpStatusCode: null
 *         },
 *         urn: null,
 *         version: 1
 * }
 * ```
 */
class PerformanceReport {
  constructor(urn, environment, playerVersion) {
    this.browser = navigator.userAgent;
    this.clientTime = new Date().toISOString();
    this.duration = 0;
    this.environment = environment;
    // See : https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
    this.networkType = null;
    this.platform = navigator.platform;
    this.player = `Letterbox/web/${playerVersion}`;
    this.playerResult = {};
    this.referrer = document.referrer || null;
    this.screenType = 'local';
    this.urn = urn || null;
    this.version = 1;
  }

  /**
   * Get request duration from performance resource object.
   * @param {Object} resource
   * @returns {Number}
   */
  static getDuration(resource) {
    return resource && resource.duration > 0 ? resource.duration | 0 : undefined;
  }

  /**
   * Get performance resource object from URL.
   * @param {String} url
   */
  static getResourceFromUrl(url) {
    // See browser's compatibility
    // https://developer.mozilla.org/en-US/docs/Web/API/Performance#Browser_compatibility
    if (typeof performance
      .getEntriesByType !== 'function') {
      return undefined;
    }

    // See https://github.com/w3c/resource-timing/issues/12
    const resources = performance.getEntriesByType('resource');

    let index = resources.length;
    // eslint-disable-next-line no-plusplus
    while (index--) {
      const e = resources[index];

      if ((e.name === url || e.name.includes(url)) && e.duration > 0) {
        return e;
      }
    }

    return undefined;
  }

  /**
   * Create the response object.
   * @param {Object} response
   * @returns {Object}
   */
  static createResult(response) {
    const report = {};
    const { url } = response;
    const resource = PerformanceReport.getResourceFromUrl(url);
    const resourceName = resource ? resource.name : url;

    if (response.duration !== false) {
      const duration = PerformanceReport.getDuration(resource);

      if (duration) {
        report.duration = duration;
      }
    }

    if (resourceName && typeof resourceName === 'string') {
      report.url = resourceName;
    }

    if (response.httpStatusCode) {
      report.httpStatusCode = response.httpStatusCode;
    }

    if (response.httpStatusCode > 399 && response.httpStatusText) {
      report.errorMessage = response.httpStatusText;
    }

    return report;
  }

  /**
   * Set the ilResult response object.
   * @param {Object} ilResult
   */
  setIlResult(ilResult) {
    this.ilResult = PerformanceReport.createResult(ilResult);
  }

  /**
   * Set the drmResult response object.
   * @param {*} drmResult
   */
  setDrmResult(drmResult = 'irdeto') {
    this.drmResult = PerformanceReport.createResult({ url: drmResult });
  }

  /**
   * Set the mediaComposition response object.
   * @param {Object} mediaComposition
   */
  setIlMediaComposition(mediaComposition) {
    if (!this.ilResult) {
      return;
    }

    if (mediaComposition.blockReason) {
      this.ilResult.blockingReason = mediaComposition.blockReason;
    }

    this.ilResult.playableAbroad = mediaComposition.playableAbroad;
    this.ilResult.noPlayableResourceFound = mediaComposition.noPlayableResourceFound;
  }

  /**
   * Set the playerResult object.
   * @param {Object} response
   */
  setPlayerResult(response) {
    this.playerResult = PerformanceReport.createResult(response);
  }

  /**
   * Set the tokenResult object.
   * @param {Object} tokenResult
   */
  setTokenResult(tokenResult) {
    this.tokenResult = PerformanceReport.createResult(tokenResult);
  }
}

export default PerformanceReport;
