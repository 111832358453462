/**
 * @ignore
 */
class PerformanceService {
  constructor(url = 'https://srgsnitch.herokuapp.com/report', debug = false) {
    this.url = url;
    this.debug = debug;
  }

  send(performanceReport) {
    if (performanceReport === undefined || performanceReport === null) {
      return Promise.reject();
    }

    const report = performanceReport;
    report.duration = (Date.now() - new Date(report.clientTime).getTime()) | 0;

    if (report.playerResult) {
      let d = report.duration;

      if (report.ilResult && report.ilResult.duration) {
        d -= report.ilResult.duration;
      }
      report.playerResult.duration = d;
    }

    return fetch(this.url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(report),
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }

        return response;
      });
  }
}

export default PerformanceService;
