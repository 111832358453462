import * as Events from './Events.js';

const PLAYER_BAR_CLASS = 'srgssr-player-bar';
const AUDIO_SOURCE_CLASS = 'srgssr-audio-source';

const SRGLetterboxLayout = (player) => {
  const AUDIO_TYPE = 'AUDIO';
  const HEIGHT_THRESHOLD = 50;

  /**
   * Returns true if the current media is an Audio
   */
  function isAudioSource(mainChapter) {
    const { mediaType } = mainChapter;

    return mediaType === AUDIO_TYPE;
  }

  /**
   * Updates letterbox source layout
   * Adds the AUDIO_SOURCE_CLASS when the current source is an audio
   * Removes it otherwise
   */
  function updateLayout({ data: { mediaComposition = {} } = {} }) {
    if (isAudioSource(mediaComposition.getMainChapter())) {
      player.addClass(AUDIO_SOURCE_CLASS);
      return;
    }
    player.removeClass(AUDIO_SOURCE_CLASS);
  }

  /**
   * Checks which breakpoint is used according to the size of the player
   * Dispatches the a LAYOUT_BREAKPOINT_CHANGE with the current breakpoint if there is a change
   */
  function checkBreakpointChange() {
    const width = player.currentDimension('width');
    const { breakpoints, currentBreakpoint: lastBreakpoint } = player.options();
    const points = Object.keys(breakpoints).reverse();

    const currentBreakpoint = points
      .find(point => breakpoints[point] <= width) || points[points.length - 1];

    if (currentBreakpoint !== lastBreakpoint) {
      player.options({
        currentBreakpoint,
      });

      player.trigger({
        type: Events.LAYOUT_BREAKPOINT_CHANGE,
        data: currentBreakpoint,
      });
    }
  }

  /**
   * Updates letterbox size layout
   * adds the PLAYER_BAR_CLASS when the current height is equal or under the threshold
   * Removes it otherwise
   */
  function responsiveLayout() {
    // the setTimeout 0 is mainly for iOS during the transition back
    // from full screen to allow the player container to reset its height
    setTimeout(() => {
      const height = player.currentDimension('height');

      checkBreakpointChange();

      if (height <= HEIGHT_THRESHOLD) {
        player.addClass(PLAYER_BAR_CLASS);
        return;
      }

      player.removeClass(PLAYER_BAR_CLASS);
    }, 0);
  }


  player.on([Events.PLAYER_RESIZE, Events.FULL_SCREEN_CHANGE], responsiveLayout);
  player.on(Events.MEDIACOMPOSITION_LOADED, updateLayout);

  responsiveLayout();
};

export default SRGLetterboxLayout;
export { PLAYER_BAR_CLASS, AUDIO_SOURCE_CLASS };
