import videojs from 'video.js';
import './title-component.js';
import './socialSharing/sharing-module.js';

const Component = videojs.getComponent('Component');

/**
 * Global container on top of the player showing title
 * and sharing icons
 * @ignore
 */
class HeaderComponent extends Component {
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      children: [
        'TitleComponent',
        'SharingModule',
      ],
    }, options);

    super(player, opt);
  }

  createEl() {
    const el = super.createEl('div', {
      className: 'vjs-container-header',
      dir: 'ltr',
    });
    return el;
  }
}

Component.registerComponent('HeaderComponent', HeaderComponent);
export default HeaderComponent;
