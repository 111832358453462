import videojs from 'video.js';
import SRGMenu from '../srg-menu.js';
import './items/setting-debug-menu-item.js';
import * as Events from '../../../../utils/Events.js';
import * as SRGQuality from '../../../../utils/SRGQuality.js';
import SRGStreamType from '../../../../utils/SRGStreamType.js';
import SRGLetterboxConfiguration from '../../../../utils/SRGLetterboxConfiguration.js';

/**
 * LetterBox Settings Menu component
 *
 * @ignore
 */
class SettingsMenu extends SRGMenu {
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      children: {
        settingsButton: true,
      },
    }, options);

    super(player, opt);

    this.state = {
      autoplay: {},
      continuousPlayback: {},
      hd: {},
      playbackRate: {},
      debugMenu: {},
    };
  }

  /**
   *@override
   */
  updateState() {
    this.state.autoplay = this.buildAutoplay();
    this.state.continuousPlayback = this.buildContinuousPlayback();
    this.state.hd = this.buildHd();
    this.state.playbackRate = this.buildPlaybackRate();
    this.state.debugMenu = {
      label: 'Debug',
      type: 'SettingDebugMenuItem',
    };
  }

  buildAutoplay() {
    return {
      label: 'Auto play',
      type: SRGMenu.PANEL_COMPONENTS_TYPES.TOGGLE,
      value: () => SRGLetterboxConfiguration.getSetting(this.player(), 'autoplay'),
      changeEvent: Events.AUTOPLAY_CHANGE,
    };
  }

  buildContinuousPlayback() {
    return {
      label: 'Continuous Playback',
      type: SRGMenu.PANEL_COMPONENTS_TYPES.TOGGLE,
      disabled: !SRGStreamType.isOnDemand(this.player().currentSource().streamType),
      value: () => SRGLetterboxConfiguration.getSetting(this.player(), 'continuousPlayback'),
      changeEvent: Events.CONTINUOUS_PLAYBACK_CHANGE,
    };
  }

  buildHd() {
    const { mediaComposition } = this.player().options().SRGProviders;

    return {
      label: 'HD',
      type: SRGMenu.PANEL_COMPONENTS_TYPES.TOGGLE,
      value: () => SRGLetterboxConfiguration.getSetting(this.player(), 'hd'),
      changeEvent: Events.QUALITY_CHANGE,
      disabled: !SRGQuality.containQualities(mediaComposition.getMainResources()),
    };
  }

  buildPlaybackRate() {
    const items = this.player()
      .options()
      .playbackRates
      .map(rate => ({ label: (rate === 1 ? 'Normal' : `${rate} x`), value: rate }));

    return {
      label: 'Speed',
      type: SRGMenu.PANEL_COMPONENTS_TYPES.LIST,
      disabled: !SRGStreamType.isOnDemand(this.player().currentSource().streamType),
      items,
      value: () => this.player().playbackRate(),
      changeEvent: Events.RATE_CHANGE,
    };
  }

  buildCSSClass() {
    return `settings-menu ${super.buildCSSClass()}`;
  }
}

videojs.registerComponent('SettingsMenu', SettingsMenu);
export default SettingsMenu;
