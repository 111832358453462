import videojs from 'video.js';
import * as Events from '../../utils/Events.js';
import Utils from '../../utils/Utils.js';

const Button = videojs.getComponent('Button');

const DISPLAY_DURATION = Utils.secondsToMilliseconds(8);
const DISAPPEARENCE_DELAY = Utils.secondsToMilliseconds(0.55);
const APPEARENCE_DELAY = Utils.secondsToMilliseconds(0.1);
const FADED_CLASS = 'srg-skip-button--faded';
const CREDITS_LABELS = {
  OPENING_CREDITS: 'Skip intro',
  CLOSING_CREDITS: 'Skip credits',
};

class SkipCredits extends Button {
  constructor(player, options) {
    super(player, options);

    this.timeUpdateListener = this.onTimeUpdate.bind(this);

    this.userActiveListener = this.onUserActive.bind(this);
    this.userInactiveListener = this.onUserInactive.bind(this);
    this.updateListener = this.update.bind(this);

    this.displayTimeOut = null;

    this.controlText(this.localize('Skip intro'));
    this.update();

    this.initListener();
  }

  initListener() {
    this.player().on(Events.LOAD_START, this.updateListener);
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srg-skip-button`;
  }

  dispose() {
    this.player().off(Events.LOAD_START, this.updateListener);
    this.player().off(Events.USER_ACTIVE, this.userActiveListener);
    this.player().off(Events.USER_INACTIVE, this.userInactiveListener);

    super.dispose(this);
  }

  hide() {
    if (this.isHidden()) {
      return;
    }

    this.addClass(FADED_CLASS);
    this.player().off(Events.USER_ACTIVE, this.userActiveListener);
    this.player().off(Events.USER_INACTIVE, this.userInactiveListener);

    setTimeout(() => {
      super.hide();
    }, DISAPPEARENCE_DELAY);
  }

  show() {
    super.show();

    this.setDisplayTimeOut();

    this.addClass(FADED_CLASS);

    setTimeout(() => {
      this.removeClass(FADED_CLASS);
    }, APPEARENCE_DELAY);

    this.player().on(Events.USER_ACTIVE, this.userActiveListener);
    this.player().on(Events.USER_INACTIVE, this.userInactiveListener);
  }

  update() {
    const { isComponentVisible = true } = this.options();
    const { SRGProviders: { mediaComposition } = {} } = this.player().options();

    this.hide();

    if (SkipCredits.hasCredits(mediaComposition) && isComponentVisible) {
      this.creditsIntervals = SkipCredits.getIntervals(mediaComposition);

      this.player().on(Events.TIME_UPDATE, this.timeUpdateListener);
    } else {
      this.hide();

      this.player().off(Events.TIME_UPDATE, this.timeUpdateListener);
    }
  }

  createEl() {
    return super.createEl('button', {
      className: this.buildCSSClass(),
    });
  }

  handleClick(e) {
    e.preventDefault();

    const { markOut } = this.currentCredit;

    if (markOut) {
      this.player().currentTime(SkipCredits.getIntervalEndPosition(markOut));
    }
  }

  onTimeUpdate() {
    const displayedCredit = SkipCredits.displayedCredit(
      this.player().currentTime(),
      this.creditsIntervals,
    );

    if (displayedCredit) {
      this.currentCredit = displayedCredit;
      this.el().innerHTML = this.localize(CREDITS_LABELS[this.currentCredit.type]);

      if (this.isHidden()) {
        this.show();
      }
    } else {
      this.hide();
    }
  }

  onUserInactive() {
    this.setDisplayTimeOut();
  }

  onUserActive() {
    if (this.displayTimeOut) {
      clearTimeout(this.displayTimeOut);
    }

    if (this.hasClass(FADED_CLASS)) {
      this.removeClass(FADED_CLASS);
    }
  }

  setDisplayTimeOut() {
    if (this.player().paused()
      || !SkipCredits.shouldDisappearAfterInactivity(
        this.player().currentTime(),
        this.markOut,
      )) {
      return;
    }

    this.displayTimeOut = setTimeout(() => {
      this.displayTimeOut = null;
      this.addClass(FADED_CLASS);
    }, DISPLAY_DURATION);
  }

  isHidden() {
    return this.hasClass('vjs-hidden');
  }

  static shouldDisappearAfterInactivity(markIn, markOut) {
    const openingDuration = Math.abs(markOut - markIn);

    return openingDuration >= DISPLAY_DURATION;
  }

  static getIntervalEndPosition(markOut) {
    return Utils.millisecondsToSeconds(markOut) + 1;
  }

  static isTimeInInterval(currentTime, markIn, markOut) {
    const currentPosition = Utils.secondsToMilliseconds(currentTime);
    return currentPosition >= markIn && currentPosition <= markOut;
  }

  static displayedCredit(currentTime, creditsIntervals = []) {
    return creditsIntervals
      .find(({ markIn, markOut }) => SkipCredits
        .isTimeInInterval(currentTime, markIn, markOut));
  }

  static hasCredits(mediaComposition) {
    return SkipCredits.getIntervals(mediaComposition).length > 0;
  }

  static getIntervals({
    chapterList: [
      {
        timeIntervalList = [],
      } = {},
    ] = [],
  } = {}) {
    return timeIntervalList.filter(({ type }) => Object.keys(CREDITS_LABELS).includes(type));
  }
}

videojs.registerComponent('SkipCredits', SkipCredits);
export default SkipCredits;
