/* eslint-disable no-param-reassign */
import videojs from 'video.js';
import SegmentService from '../../dataProvider/services/SegmentService.js';
import SRGStreamType from '../../utils/SRGStreamType.js';
import * as Events from '../../utils/Events.js';
import Utils from '../../utils/Utils.js';
import PlayerUtils from '../../utils/PlayerUtils.js';

/**
 * LetterBoxWeb Segment Middleware.
 *
 * A Segment is a portion of a video stream that can be displayed (as subdivision) and / or blocked.
 *
 * Responsibilities:
 * - Blocked segments
 * - Pending seek (used to seek to beginning of segment)
 * - Restart live after a pause
 *
 * @ignore
 */
videojs.use('*', (player) => {
  let segmentService;
  let currentSource;

  function callPause() {
    if (SRGStreamType.isLive(player.currentSource().streamType) && player.hasStarted()) {
      player.trigger(Events.ENDED);
      player.currentSource().needToRestart = true;
    }
  }

  function needToRestart() {
    // Return true if needToRestart is set to true in the current source
    // or if it is a live stream with autoplay set to true that has not started yet
    return player.currentSource().needToRestart || (
      SRGStreamType.isLive(player.currentSource().streamType)
      && player.autoplay()
      && !player.hasStarted()
    );
  }

  function callPlay() {
    if (needToRestart()) {
      PlayerUtils.loadMedia(player, {
        urn: PlayerUtils.getUrn(player),
        autoplay: true,
      });

      return videojs.middleware.TERMINATOR;
    }
    return undefined;
  }

  function triggerPendingSegment(playerCurrentSource, pendingSegment) {
    if (pendingSegment) {
      player.trigger({
        type: Events.SEGMENT_SWITCHED,
        data: {
          analyticsMetadata: pendingSegment.analyticsMetadata,
          origin: 'start',
          isBlocked: !!pendingSegment.blockReason,
        },
      });

      player.one(Events.PLAYING, () => {
        // eslint-disable-next-line no-param-reassign
        playerCurrentSource.pendingSegment = undefined;
      });
    }
  }

  /**
   * Return the number of seconds to seek relative to the context.
   *
   * @param {Object} mediaComposition
   * @param {number} pendingSeek
   *
   * @returns {(number|undefined)} number of seconds to seek or undefined
   */
  function contextAwareSeek(mediaComposition, pendingSeek) {
    const { millisecondsToSeconds } = Utils;
    if (mediaComposition.findMainSegment()
      && pendingSeek
      && pendingSeek !== millisecondsToSeconds(mediaComposition.findMainSegment().markIn)
      && pendingSeek < millisecondsToSeconds(mediaComposition.findMainSegment().duration)) {
      return millisecondsToSeconds(mediaComposition.findMainSegment().markIn) + pendingSeek;
    }

    return pendingSeek;
  }

  function applyPendingSeek(time) {
    let updatedTime = time;

    const playerCurrentSource = player.currentSource();
    const { pendingSeek, pendingSegment } = playerCurrentSource;
    const { mediaComposition } = player.options().SRGProviders;
    const blockedSegment = segmentService.getBlockedSegment(pendingSeek);

    if (pendingSeek) {
      // eslint-disable-next-line no-param-reassign
      playerCurrentSource.pendingSeek = undefined;

      // Workaround for https://bugs.webkit.org/show_bug.cgi?id=261512
      const loadEvent = videojs.browser.IS_ANY_SAFARI ? Events.LOADED_DATA : Events.LOADED_METADATA;

      player.one(loadEvent, () => {
        updatedTime = contextAwareSeek(mediaComposition, pendingSeek);
        player.currentTime(updatedTime);

        segmentService.triggerBlockedSegmentSkipped(blockedSegment);

        triggerPendingSegment(playerCurrentSource, pendingSegment);
      });
    }

    return updatedTime;
  }

  function currentTime(ct) {
    let time = ct;

    // TODO Is this the best place to put a recurring time check ?
    /* (afaics, currentTime is only called periodically as a side effect of something periodically
     checking time). I do not have a better way to do this yet.
     */
    time = applyPendingSeek(time);
    time = segmentService.skipIfInBlockedSegment(time);
    segmentService.checkSegmentChange(time);

    return time;
  }

  function setCurrentTime(time) {
    if (SRGStreamType.isLive(player.currentSource().streamType)) {
      return currentTime;
    }
    return segmentService.getValidTime(time);
  }

  function setSource(srcObj, next) {
    if (currentSource !== srcObj.src) {
      currentSource = player.currentSource().src;
      const { mediaComposition } = player.options().SRGProviders;
      if (mediaComposition != null) {
        segmentService = new SegmentService(
          player,
          mediaComposition.getMainSegments(),
        );
      } else {
        segmentService = null;
      }
    }

    next(null, srcObj);
  }

  return {
    currentTime,
    callPause,
    callPlay,
    setCurrentTime,
    setSource,
  };
});
