import videojs from 'video.js';
import './count-down.js';
import UserReportButton from './user-report-button.js';
import DRMErrorPage from '../../utils/DRMErrorPage.json';
import ERRORTYPE from '../../errors/errors.js';

const VJSErrorDisplay = videojs.getComponent('ErrorDisplay');

/**
 * Improve original Error display component implemented the LetterBoxWeb
 * CSS skin and displayed specific error.
 * @ignore
 */
class ErrorDisplay extends VJSErrorDisplay {
  constructor(player, options) {
    const opt = videojs.mergeOptions(options, {
      pauseOnOpen: true,
      fillAlways: true,
      temporary: false,
      uncloseable: true,
    });

    super(player, opt);
  }

  description() {
    const error = this.player().error();

    return error && error.message;
  }

  close() {
    this.removeChild('countDown');
    super.close();
  }

  iconEl(error) {
    return videojs.dom.createEl('div', {
      className: `${error.iconClass || 'vjs-icon-error'} icon`,
      title: this.localize(error.message),
    });
  }

  errorMessageEl(error) {
    return videojs.dom.createEl('div', {
      className: 'text',
    },
    {},
    this.createHrefEl(error) || this.localize(error.message));
  }

  content() {
    const error = this.overrideVideoJSError(this.player().error());

    if (!error) { return null; }

    if (ERRORTYPE.ERROR_BLOCKING_REASON_STARTDATE.type === error.type) {
      return this.countDownEl();
    }

    return videojs.dom.createEl('div', {
      className: 'content',
    },
    {},
    [this.iconEl(error), this.errorMessageEl(error), this.createMailToEl(error.type)]);
  }

  createMailToEl(type) {
    const userReportButton = new UserReportButton(this.player());

    return userReportButton.update(type);
  }

  countDownEl() {
    const countDownView = this.addChild('countDown');
    const { mediaComposition } = this.player().options().SRGProviders;

    this.player().poster(mediaComposition.getMainChapterImageUrl());

    return countDownView.el();
  }

  createHrefEl(error) {
    if (error.hasLink) {
      const { vendor } = this.player().options().SRGProviders.mediaComposition.getMainChapter();
      const href = error.hasLink ? DRMErrorPage[vendor] : undefined;

      return videojs.dom.createEl('a', {
        className: 'error__href',
      }, {
        target: '_blank',
        href,
      }, this.localize(error.message));
    }
    return undefined;
  }

  overrideVideoJSError(error) {
    if (!error) {
      return undefined;
    }

    if (error.type) {
      return error;
    }

    // mutates the original error object
    // to add extra metadata
    Object.assign(
      error,
      ERRORTYPE.ERROR_UNKNOWN,
      { detailedMessage: error.message },
    );

    return this.player().error();
  }
}

videojs.registerComponent('ErrorDisplay', ErrorDisplay);
export default ErrorDisplay;
