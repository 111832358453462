import videojs from 'video.js';
import SRGStreamType from '../../utils/SRGStreamType.js';
import Utils from '../../utils/Utils.js';
import * as PlayerEvents from '../../utils/PlayerEvents.js';

const VJSCurrentTimeDisplay = videojs.getComponent('CurrentTimeDisplay');

/**
 * Show time display for live DVR if Livetracker
 * or Currentime if not !
 * @ignore
 */
class CurrentTimeDisplay extends VJSCurrentTimeDisplay {
  ready() {
    this.player().on(PlayerEvents.DURATION_CHANGE, () => {
      setTimeout(() => {
        this.updateTextNode_(0); // eslint-disable-line
      }, 1);
    });
    this.on(this.player(), PlayerEvents.SEEKING, (e) => {
      this.updateContent(e);
    });
  }

  updateContent() {
    let time = (this.player().scrubbing())
      ? this.player().getCache().currentTime
      : this.player().currentTime();

    if ((this.player().liveTracker && this.player().liveTracker.isTracking())
      || SRGStreamType.isDvr(this.player().currentSource().streamType)) {
      const secondsBehind = Utils.numberFormat(
        this.player().liveTracker.liveCurrentTime() - this.player().currentTime(),
      );

      time = Utils.normalizeElapsedTimeSinceMidnight(secondsBehind);
      this.updateTextNode_(time); // eslint-disable-line
      // Avoid overriding updateTextNode_ to have the time formatted in hours e.g 0:00:00
      this.formattedTime_ = Utils.formatTime(time, 6000); // eslint-disable-line

      return;
    }

    this.updateTextNode_(time); // eslint-disable-line
  }
}

videojs.registerComponent('CurrentTimeDisplay', CurrentTimeDisplay);
export default CurrentTimeDisplay;
