import videojs from 'video.js';
import './items/srg-subtitles-menu-size-item.js';
import SRGMenu from '../srg-menu.js';
import SRGLetterboxConfiguration from '../../../../utils/SRGLetterboxConfiguration.js';
import * as Events from '../../../../utils/Events.js';
import PlayerUtils from '../../../../utils/PlayerUtils.js';
import TextTracksUtils from '../../../../utils/TextTracksUtils.js';
import AudioTracksUtils from '../../../../utils/AudioTracksUtils.js';
import SRGStreamType from '../../../../utils/SRGStreamType.js';

/**
 * LetterBox Subtitles Menu component
 *
 * @ignore
 */
class SubtitlesMenu extends SRGMenu {
  constructor(player, options) {
    const opt = videojs.mergeOptions({
      children: {
        subtitlesButton: true,
      },
    }, options);

    super(player, opt);

    this.state = {};
    this.setSubtitleButtonState = this.setSubtitleButtonState.bind(this);
    this.isFirstLevelMenu = true;

    this.player().textTracks().on(Events.CHANGE, this.setSubtitleButtonState);
  }

  /**
   *@override
   */
  updateState() {
    this.state.textTrackLanguage = this.buildTextTrackLanguage();
    this.state.audioTrackLanguage = this.buildAudioTrackLanguage();

    this.setSubtitleButtonState();
  }

  /**
   * Set the subtitle button state
   */
  setSubtitleButtonState() {
    const subtitleActive = 'srg-menu__subtitles-button--active';
    const currentSubtitle = this.state.textTrackLanguage && this.state.textTrackLanguage.value();

    if (currentSubtitle && currentSubtitle.language !== 'off') {
      this.player().$('.srg-menu__subtitles-button').classList.add(subtitleActive);

      return;
    }

    this.player().$('.srg-menu__subtitles-button').classList.remove(subtitleActive);
  }

  buildTextTrackLanguage() {
    const tracks = PlayerUtils.filterTextTrack(this.player());
    // TODO remove the lines below once dash.js is replaced by http-streaming for dash playback.
    // Since http-streaming doesn't support TTML subtitles tracks is empty.
    const { streaming, streamType } = this.player().currentSource();
    const isDashLiveStream = streaming === 'DASH'
      && !SRGStreamType.isOnDemand(streamType);

    if (tracks.length < 1 || isDashLiveStream) {
      return undefined;
    }

    const items = tracks
      .map(({
        label, language, srgKind,
      }) => {
        const isCaption = TextTracksUtils.isCaption(srgKind);

        return {
          label: label.split(' (SDH)').join(''),
          value: {
            language,
            captions: isCaption,
          },
          badge: isCaption ? srgKind : false,
        };
      });

    items.unshift({
      label: this.localize('Off'),
      value: SubtitlesMenu.subtitlesOffValue(),
    });

    return {
      label: 'Subtitles',
      type: SRGMenu.PANEL_COMPONENTS_TYPES.LIST_PANEL_ITEM,
      widget: this.buildTextTrackSizeLevel(),
      items,
      changeTarget: () => this.player().tech().textTracks(),
      changeEvent: [Events.ADD_TRACK, Events.CHANGE],
      value: () => {
        const currentTrack = TextTracksUtils.findActiveTrack(
          PlayerUtils.filterTextTrack(this.player()),
        );

        if (currentTrack) {
          const { language, srgKind } = currentTrack;

          return {
            language,
            captions: TextTracksUtils.isCaption(srgKind),
          };
        }

        return SubtitlesMenu.subtitlesOffValue();
      },
    };
  }

  buildTextTrackSizeLevel() {
    if (this.player().tech(true).featuresNativeTextTracks) {
      return undefined;
    }

    return {
      label: 'Size Selector',
      type: 'SRGSubtitlesMenuSizeItem',
      name: 'textTrackSizeLevel',
      changeEvent: Events.TEXT_TRACK_SIZE_CHANGE,
      value: () => SRGLetterboxConfiguration.getSetting(this.player(), 'textTrackSizeLevel'),
    };
  }

  buildAudioTrackLanguage() {
    const tracks = Array.from(this.player().audioTracks());

    if (tracks.length < 2) {
      return undefined;
    }

    const items = tracks.map(({
      label, kind, language,
    }) => {
      const isDescription = AudioTracksUtils.isAudioDescription(kind);
      return {
        label: label.split(' (AD)').join(''),
        value: {
          language,
          description: isDescription,
        },
        badge: AudioTracksUtils.isAudioDescription(kind) ? 'audio-description' : false,
      };
    });

    return {
      label: 'Audio',
      type: SRGMenu.PANEL_COMPONENTS_TYPES.LIST_PANEL_ITEM,
      items,
      changeTarget: () => this.player().tech(true).audioTracks(),
      changeEvent: [Events.ADD_TRACK, Events.CHANGE],
      value: () => {
        const currentTrack = AudioTracksUtils.findActiveTrack(this.player().audioTracks());

        if (currentTrack) {
          return {
            language: currentTrack.language,
            description: AudioTracksUtils.isAudioDescription(currentTrack.kind),
          };
        }

        return {
          language: undefined,
          description: undefined,
        };
      },
    };
  }

  buildCSSClass() {
    return `subtitles-menu ${super.buildCSSClass()}`;
  }

  static subtitlesOffValue() {
    return {
      language: 'off',
      captions: false,
    };
  }
}

videojs.registerComponent('SubtitlesMenu', SubtitlesMenu);
export default SubtitlesMenu;
