import videojs from 'video.js';

const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
   * Creates an instance of SharginOption class.
   *
   * @param {Player} player
   *        The `Player` that this class should be attached to.
   *
   * @param {Object} [options]
   *        The key/value store of player options.
   *
   * @param {Object[]} [options.controlText]
   *        Used to create a control text element on this component.
   *
   * @param {Object[]} [options.labelText]
   *        Used to create a label text element on this component.
   *
   * @param {Object[]} [options.isHidden]
   *        Used to set component visibility. By default isHidden is false.
   *
   * @param {Object[]} [options.clickHandler]
   *        Used to set the clickHandler.
   *
   * @param {Object[]} [options.value]
   *        Used to set the value.
   *
   * @ignore
   */
class SharingOption extends ClickableComponent {
  constructor(player, options = { isHidden: false }) {
    super(player, options);

    const { controlText, isHidden } = options;

    this.controlText(controlText);
    this.disable(this.options().disabled);

    if (isHidden) {
      this.hide();
    }
  }

  buildCSSClass() {
    return `sharing-option__label ${super.buildCSSClass()}`;
  }

  createEl() {
    const label = super.createEl('label', {
      className: this.buildCSSClass(),
    });

    label.appendChild(this.createChildrenFragment());

    return label;
  }

  createChildrenFragment() {
    const fragment = document.createDocumentFragment();
    const inputRadioEl = this.createInputRadioEl();
    const radioButtonEl = SharingOption.createRadioButtonEl();
    const labelEl = this.createLabelEl();

    fragment.appendChild(inputRadioEl);
    fragment.appendChild(radioButtonEl);
    fragment.appendChild(labelEl);

    return fragment;
  }

  createInputRadioEl() {
    return videojs.dom.createEl('input', {
      className: 'sharing-option__input',
      checked: this.options().checked,
      value: this.options().value,
    }, {
      type: 'radio',
      name: 'sharing-option',
      'aria-hidden': 'true',
      tabIndex: '-1',
    });
  }

  createLabelEl() {
    return videojs.dom.createEl('span', {
      innerHTML: this.localize(this.options().labelText),
    }, {
      'aria-hidden': 'true',
    });
  }

  disable(disabled = false) {
    this.$('.sharing-option__input').disabled = disabled;
    this.removeClass('sharing-option--disable');
    this.el().setAttribute('aria-disabled', 'false');
    this.el().setAttribute('tabindex', '0');

    if (disabled) {
      this.$('.sharing-option__input').checked = false;
      this.addClass('sharing-option--disable');
      this.el().setAttribute('aria-disabled', 'true');
      this.el().setAttribute('tabindex', '-1');
    }
  }

  handleClick() {
    if (this.isChecked() || this.$('.sharing-option__input').disabled) {
      return;
    }

    // Allow keyboard control
    this.$('.sharing-option__input').checked = true;

    super.handleClick();
  }

  isChecked() {
    return this.$('.sharing-option__input').checked;
  }

  isHidden() {
    if (this.options().isHidden) {
      return this.options().isHidden();
    }

    return false;
  }

  update() {
    if (this.isHidden()) {
      this.hide();
    } else {
      this.show();
    }
  }

  static createRadioButtonEl() {
    return videojs.dom.createEl('span', {
      className: 'sharing-option__radio-button',
    }, {
      'aria-hidden': 'true',
    });
  }
}

videojs.registerComponent('SharingOption', SharingOption);
export default SharingOption;
