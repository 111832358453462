import videojs from 'video.js';
import SRGStreamType from '../../utils/SRGStreamType.js';

const VJSPlayToggle = videojs.getComponent('PlayToggle');

/**
 * LetterBoxWeb player component, improving the original component
 * with specific play method for live streaming.
 * @ignore
 */
class PlayToggle extends VJSPlayToggle {
  handlePlay() {
    this.removeClass('vjs-ended');
    this.removeClass('vjs-paused');

    const isLive = SRGStreamType.isLive(this.player().currentSource().streamType);
    // Chromecast live DVR is not supported
    const isChromecastLive = !SRGStreamType
      .isOnDemand(this.player().currentSource().streamType)
      && this.player().tech(true).name() === 'Chromecast';

    // condition for trigger seek to live in timeupdate
    if (isLive || isChromecastLive) {
      this.removeClass('vjs-playing');
      this.addClass('vjs-playing-live');
      this.controlText('Stop');
    } else {
      this.addClass('vjs-playing');
      this.controlText('Pause');
    }
  }

  handlePause(event) {
    this.removeClass('vjs-playing-live');
    this.removeClass('vjs-ended');
    super.handlePause(event);
  }

  handleEnded(event) {
    this.removeClass('vjs-playing-live');
    super.handleEnded(event);

    const isLive = SRGStreamType.isLive(this.player().currentSource().streamType);

    if (isLive && this.player().isInPictureInPicture()) {
      this.player().exitPictureInPicture();
    }
  }
}

videojs.registerComponent('PlayToggle', PlayToggle);
export default PlayToggle;
