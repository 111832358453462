import videojs from 'video.js';
import SRGMenuItem from './srg-menu-item.js';
import * as Events from '../../../../utils/Events.js';
import { SPACE, ENTER } from '../../../../utils/KeyCodes.js';

/**
 * LetterBox SRGMenu item toggle component
 * @ignore
 */
class SRGMenuToggleItem extends SRGMenuItem {
  constructor(player, options) {
    super(player, options);

    this.toggleEl.addEventListener(Events.KEY_DOWN, (event) => {
      if ([SPACE, ENTER].includes(event.keyCode)) {
        event.preventDefault();
        event.stopPropagation();
        this.inputEl.checked = !this.inputEl.checked;
        this.onInputValueChange();
      }
    });

    this.inputEl.addEventListener(Events.CLICK, () => this.onInputValueChange());
  }

  onInputValueChange() {
    // Wait for transition to end
    setTimeout(() => {
      this.updateInputState(this.inputEl);
    }, parseFloat(window.getComputedStyle(this.$('.slider')).transitionDuration) * 1000);
  }

  /**
   * Updates the input state when a new value is setted
   * @override
   */
  updateValue() {
    const value = this.getValue() === true;
    this.inputEl.checked = value;
    this.toggleEl.setAttribute('aria-pressed', value);
  }

  /**
   * Dispatches the new input state when its clicked
   * @param {DOMElement} input
   */
  updateInputState(input) {
    this.dispatchValue(input.checked);
  }

  createEl() {
    const fragment = document.createDocumentFragment();
    this.toggleEl = SRGMenuToggleItem.createToggleEl(this.localize(this.options().label));
    this.inputEl = SRGMenuToggleItem.createInputEl();
    const spanEl = SRGMenuToggleItem.createSpanEl();
    const labelEl = this.createLabelEl();

    this.toggleEl.appendChild(this.inputEl);
    this.toggleEl.appendChild(spanEl);

    fragment.appendChild(labelEl);
    fragment.appendChild(this.toggleEl);

    const el = super.createEl('div', {
      className: 'srg-menu__panel-item',
    });

    this.updateValue();

    el.appendChild(fragment);

    return el;
  }

  createLabelEl() {
    return videojs.dom.createEl('span',
      { className: 'srg-toggle-panel__label' },
      {},
      this.localize(this.options().label));
  }

  /* static */
  static createInputEl() {
    return videojs.dom.createEl('input', {
      className: 'checkbox',
    }, {
      type: 'checkbox',
    });
  }

  static createToggleEl(label) {
    return videojs.dom.createEl('label', {
      className: 'switch',
    },
    {
      tabIndex: 0,
      role: 'button',
      'aria-label': label,
    });
  }

  static createSpanEl() {
    return videojs.dom.createEl('span', {
      className: 'slider',
    });
  }
}

videojs.registerComponent('SRGMenuToggleItem', SRGMenuToggleItem);
export default SRGMenuToggleItem;
