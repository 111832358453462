/* eslint-disable no-param-reassign */
import videojs from 'video.js';
import * as Events from '../../../../utils/Events.js';
import Utils from '../../../../utils/Utils.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBox SRGMenu item component
 * Abstracted class representing a child of an SRGMenuPanel component
 * @ignore
 */
class SRGMenuItem extends Component {
  constructor(player, options) {
    super(player, options);

    const { changeEvent, changeTarget = () => player } = this.options();

    this.addClass(Utils.formatKebab(this.name()));

    // Listened to the event from the player telling that the value has been updated
    if (changeEvent) {
      changeTarget().on(changeEvent, () => {
        // updateValue has to be implemented in the child class
        this.updateValue();
      });
    }
  }

  /**
   * Dispatches the new value of the menu item to the global menu (handler)
   * @param {*} value The value can be of any type
   */
  dispatchValue(value) {
    if (value === this.getValue()) {
      return;
    }

    const [firstLetter, ...letters] = this.name();
    this.updateProperty(`${firstLetter.toLowerCase()}${letters.join('')}`, value);
  }

  /**
   * Updates the new value of a property to the player.
   * Called when the user updates the value of a property through a panel item.
   *
   * @param {String} property the property name
   * @param {*} value the new value of the property
   *
   * @fires Events.MENU_VALUE_UPDATED
   */
  updateProperty(property, value) {
    const updatedMenu = { [property]: value };

    this.player().trigger({
      type: Events.MENU_VALUE_UPDATED,
      data: updatedMenu,
    });
  }

  /**
   * @abstract
   *
   * @throws {Error}
   */
  updateValue() {
    throw new Error(`${this.constructor.name} must overwrite the updateValue method.`);
  }

  /**
   * Closes the last panel opened in the menu
   * Use case: when a value is updated, it may be interesting to goback to the previous panel
   */
  closePanel() {
    const { handler } = this.options();
    handler.closePanel();
  }

  /**
   * Adds and displays a new pnael in the menu
   * @param {SRGMenuPanel} panel
   */
  addPanel(panel) {
    const { widget, handler } = this.options();
    if (widget) {
      handler.addWidget(widget);
    }
    handler.addPanel(panel);
  }

  /**
   * Get the current value of the item
   */
  getValue() {
    return this.options().value();
  }

  /**
   * Check if the current item is select.
   *
   * @param {Object|String|Number} item
   *
   * @returns {Boolean} true if the current item is selected
   */
  isItemCurrentlySelected(item) {
    const currentValue = this.getValue();

    if (typeof item.value !== 'object') {
      return currentValue === item.value;
    }

    const currentKeys = Object.keys(currentValue);
    const itemKeys = Object.keys(item.value);

    if (currentKeys.length !== itemKeys.length) {
      return false;
    }

    // eslint-disable-next-line
    for (const key of currentKeys) {
      if (currentValue[key] !== item.value[key]) {
        return false;
      }
    }

    return true;
  }
}

videojs.registerComponent('SRGMenuItem', SRGMenuItem);
export default SRGMenuItem;
