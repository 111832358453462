import videojs from 'video.js';
import SRGMenuPanel from '../../srg-menu-panel.js';
import SRGMenuItem from '../../items/srg-menu-item.js';

const Button = videojs.getComponent('Button');
const SELECTED_ITEM = 'srg-menu__panel-item--selected';

class SimpleListItem extends SRGMenuItem {
  /**
   * Updates the new value setted
   * @override
   */
  updateValue() {
    const currentElement = this.listElements.get(this.getValue());

    this.listElements.forEach((element) => {
      if (element === currentElement) {
        element.classList.add(SELECTED_ITEM);
      } else {
        element.classList.remove(SELECTED_ITEM);
      }
    });
  }

  /**
   * Creates the panel list
   */
  createEl() {
    this.listElements = new Map();
    const panel = new SRGMenuPanel(this.player(), { className: 'srg-menu__panel-item--list' });
    const { items } = this.options();

    items.forEach((item) => {
      this.listElements.set(item.value, this.createListElement(item, panel));
    });

    return panel.el();
  }

  /**
   * Creates and adds a new menu item in the panel
   * @param {Object} param0 Key/value of menu item
   * @param {SRGMenuPanel} panel Panel in which the new element must be added
   */
  createListElement({ label, value }, panel) {
    const isSelected = this.getValue() === value;

    const listElement = new Button(this.player(), {
      clickHandler: () => {
        this.dispatchValue(value);
      },
      el: SimpleListItem.createButtonEl({ label, isSelected }),
    }).el();

    panel.el().appendChild(listElement);
    return listElement;
  }

  /**
   * Creates the button of the menu item
   * @param {Object} param0 Contains button label and isSelected boolean
   */
  static createButtonEl({ label, isSelected }) {
    return videojs.dom.createEl(
      'button',
      {
        className: isSelected ? SELECTED_ITEM : '',
      },
      {
        'aria-label': label,
        role: 'menuitem',
      },
      videojs.dom.createEl(
        'span',
        {
          className: 'srg-menu__panel-item-label',
        },
        {},
        label,
      ),
    );
  }
}

videojs.registerComponent('SimpleListItem', SimpleListItem);
export default SimpleListItem;
