import MediaShare from './MediaShare.js';
import SegmentService from '../dataProvider/services/SegmentService.js';

class SRGSocialSharing {
  constructor(player, defaultMode = SRGSocialSharing.SHARING_MODES.FULL_LENGTH) {
    this.player = player;
    this.mode = defaultMode;
    const { SHARING_MODES } = SRGSocialSharing;
    this.shareStrategies = {
      [SHARING_MODES.FULL_LENGTH]: this.getFullLengthShareURL.bind(this),
      [SHARING_MODES.CURRENT_SUBDIVISION]: this.getCurrentSubdivisionShareURL.bind(this),
      [SHARING_MODES.CURRENT_POSITION]: this.getCurrentPositionShareURL.bind(this),
    };
    this.embedStrategies = {
      [SHARING_MODES.FULL_LENGTH]: this.getFullLengthEmbedURL.bind(this),
      [SHARING_MODES.CURRENT_SUBDIVISION]: this.getCurrentSubdivisionEmbedURL.bind(this),
      [SHARING_MODES.CURRENT_POSITION]: this.getCurrentPositionEmbedURL.bind(this),
    };
    this.titleStrategies = {
      [SHARING_MODES.FULL_LENGTH]: this.getMainChapterTitle.bind(this),
      [SHARING_MODES.CURRENT_SUBDIVISION]: this.getCurrentSubdivisionTitle.bind(this),
      [SHARING_MODES.CURRENT_POSITION]: this.getMainChapterTitle.bind(this),
    };
  }

  isEmbeddable() {
    if (this.player.hasClass('srgssr-touch-enabled')) {
      return false;
    }

    const subdivision = this.getCurrentSubdivision();

    if (subdivision) {
      return !subdivision.noEmbed;
    }

    return !this.getMainChapter().noEmbed;
  }

  setMode(mode) {
    this.mode = mode;
  }

  getShareURL() {
    return this.shareStrategies[this.mode]();
  }

  getEmbedURL() {
    return this.embedStrategies[this.mode]();
  }

  getCurrentPositionEmbedURL() {
    return this.getFullLengthEmbedURL(parseInt(this.player.currentTime(), 10));
  }

  getCurrentSubdivisionEmbedURL() {
    const currentSubdivision = this.getCurrentSubdivision();
    if (currentSubdivision) {
      return MediaShare.embedUrl(currentSubdivision);
    }
    return this.getFullLengthEmbedURL();
  }

  getFullLengthEmbedURL(startTime = undefined) {
    const mainChapter = this.getMainChapter();
    return MediaShare.embedUrl(mainChapter, startTime);
  }

  getFullLengthShareURL(startTime = undefined) {
    const mainChapter = this.getMainChapter();
    return MediaShare.redirectUrl(mainChapter, startTime);
  }

  getCurrentSubdivisionShareURL() {
    const currentSubdivision = this.getCurrentSubdivision();
    if (currentSubdivision) {
      return MediaShare.redirectUrl(currentSubdivision);
    }
    return this.getFullLengthShareURL();
  }

  getCurrentPositionShareURL() {
    return this.getFullLengthShareURL(parseInt(this.player.currentTime(), 10));
  }

  getMainChapter() {
    return this.player
      .options()
      .SRGProviders
      .mediaComposition
      .getMainChapter();
  }

  getCurrentSubdivision() {
    const subdivisions = new SegmentService(
      this.player,
      this.player.options().SRGProviders.mediaComposition.getSubdivisions(),
    );
    const currentSubdivision = subdivisions.getPlayableSegment(this.player.currentTime());
    return currentSubdivision;
  }

  getCurrentSubdivisionTitle() {
    const currentSubdivision = this.getCurrentSubdivision();

    if (currentSubdivision) {
      return currentSubdivision.title;
    }

    return undefined;
  }

  getMainChapterTitle() {
    return this.getMainChapter().title;
  }

  getSharedMedia() {
    if (this.mode === SRGSocialSharing.SHARING_MODES.CURRENT_SUBDIVISION) {
      return this.getCurrentSubdivision();
    }
    return this.player.currentSource();
  }

  getShareTitle() {
    return this.titleStrategies[this.mode]();
  }

  static get SHARING_MODES() {
    return {
      FULL_LENGTH: 'fulllength',
      CURRENT_SUBDIVISION: 'currentSubdivision',
      CURRENT_POSITION: 'currentPosition',
    };
  }
}

export default SRGSocialSharing;
