import videojs from 'video.js';
import ChromecastLoader from '../utils/chromecast-loader.js';
import SupportedDevices from '../../utils/SupportedDevices.js';
import * as Events from '../../utils/Events.js';
import SRGStreamType from '../../utils/SRGStreamType.js';
import SRGLetterboxConfiguration from '../../utils/SRGLetterboxConfiguration.js';
import PlayerUtils from '../../utils/PlayerUtils.js';
import AudioTracksUtils from '../../utils/AudioTracksUtils.js';
import TextTracksUtils from '../../utils/TextTracksUtils.js';

const Button = videojs.getComponent('Button');

/**
 *
 * @ignore
 */
class ChromecastToggleComponent extends Button {
  constructor(player, options) {
    super(player, options);

    this.isCasting = false;
    this.hide();

    if (SupportedDevices.isChrome || SupportedDevices.isChromium) {
      const chromeCastReceiver = SRGLetterboxConfiguration.getSetting(this.player(), 'chromeCastReceiver');

      this.chromecastLoader = new ChromecastLoader(this.player(), chromeCastReceiver);
      this.castStateChanged = this.castStateChanged.bind(this);
      this.sessionStateChanged = this.sessionStateChanged.bind(this);

      this.player().one(Events.CHROMECAST_SCRIPT_LOADED, () => {
        this.initListeners();
        this.updateControlText();
      });
    }
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srg-chromecast-control`;
  }

  castStateChanged({ castState }) {
    const {
      castFramework: { CastState },
    } = this.chromecastLoader;

    switch (castState) {
      case CastState.NO_DEVICES_AVAILABLE:
        this.hide();
        break;
      case CastState.NOT_CONNECTED:
        this.show();
        this.isCasting = false;
        this.removeClass('srg-chromecast-active');
        this.updateControlText();
        break;
      case CastState.CONNECTING:
        this.player().pause();
        this.player().tech(true).trigger('waiting');
        break;
      case CastState.CONNECTED:
        this.onChromecastStarted();
        this.addClass('srg-chromecast-active');
        this.updateControlText();
        this.isCasting = true;
        break;
      default:
        break;
    }
  }

  dispose() {
    if (this.chromecastLoader) {
      const {
        castContext,
        castContextEventType,
      } = this.chromecastLoader;

      castContext.removeEventListener(
        castContextEventType.CAST_STATE_CHANGED,
        this.castStateChanged,
      );

      castContext.removeEventListener(
        castContextEventType.SESSION_STATE_CHANGED,
        this.sessionStateChanged,
      );
    }

    super.dispose();
  }

  handleClick() {
    if (this.isCasting
      && this.chromecastLoader
        .castContext
        .getSessionState() === this.chromecastLoader
        .sessionState
        .SESSION_STARTED) {
      this.chromecastLoader.castContext.endCurrentSession(true);
    } else {
      this.chromecastLoader
        .castContext.requestSession();
    }
  }

  updateControlText() {
    if (this.hasClass('srg-chromecast-active')) {
      this.controlText(this.localize('Stop casting'));
    } else {
      this.controlText(this.localize('Start casting'));
    }
  }

  initListeners() {
    if (this.chromecastLoader.castContext && this.chromecastLoader.castContext.getCastState()) {
      this.castStateChanged({ castState: this.chromecastLoader.castContext.getCastState() });
    }

    const {
      castContext,
      castContextEventType,
    } = this.chromecastLoader;

    castContext.addEventListener(
      castContextEventType.CAST_STATE_CHANGED,
      this.castStateChanged,
    );

    castContext.addEventListener(
      castContextEventType.SESSION_STATE_CHANGED,
      this.sessionStateChanged,
    );
  }

  onChromecastStarted() {
    const { mediaComposition } = this.player().options().SRGProviders;
    const media = {
      audioTrack: AudioTracksUtils.findActiveTrack(this.player().audioTracks()),
      textTrack: TextTracksUtils.findActiveTrack(this.player().textTracks()),
      currentTime: this.player().currentTime(),
      localPlayer: this.player(),
      muted: this.player().muted(),
      streamType: this.player().currentSource().streamType,
      title: mediaComposition.getMainChapter().title,
      urn: mediaComposition.getMainChapter().urn,
    };

    // eslint-disable-next-line
    this.player().loadTech_('Chromecast', media);
  }

  onChromecastEnded() {
    const currentTime = this.player().currentTime();
    const { source } = this.player().tech(true).options();

    if (!source) {
      return;
    }

    const {
      urn,
      streamType,
    } = source;

    const loadMediaReady = () => {
      if (SRGStreamType.isOnDemand(streamType)) {
        this.player().currentTime(currentTime);
      } else {
        this.player().one(Events.PLAYING, () => {
          this.player().liveTracker.seekToLiveEdge();
        });
      }
    };

    PlayerUtils.loadMedia(this.player(), {
      urn,
      mediaComposition: this.player().options().SRGProviders.mediaComposition,
      readyCallback: loadMediaReady,
    });
  }

  sessionStateChanged({ sessionState }) {
    const {
      sessionState: SessionState,
    } = this.chromecastLoader;

    switch (sessionState) {
      case SessionState.SESSION_ENDED:
        this.onChromecastEnded();
        break;
      case SessionState.SESSION_STARTED:
        break;
      default:
        this.isCasting = false;
    }
  }
}

videojs.registerComponent('ChromecastToggleComponent', ChromecastToggleComponent);
export default ChromecastToggleComponent;
