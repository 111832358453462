import videojs from 'video.js';
import EndScreenFeature from './end-screen-feature.js';
import Item from '../item-component.js';
import * as VideojsEvents from '../../../utils/VideojsEvents.js';
import * as SRGEvents from '../../../utils/SRGEvents.js';
import PlayerUtils from '../../../utils/PlayerUtils.js';

/**
 * Maximum items
 * @type {number}
 */
const MAX_ITEMS = 18;
/**
 * Maximum row
 * @type {number}
 */
const MAX_ROWS = 6;
/**
 * Minimum image height
 * @type {number}
 */
const MIN_HEIGHT = 140;
/**
 * Minimum image width
 * @type {number}
 */
const MIN_WIDTH = 240;

/**
 *
 * @ignore
 */
class RecommendationGrid extends EndScreenFeature {
  constructor(player, options = {}) {
    super(player, options);
    this.recommendationId = null;
    this.on(player, VideojsEvents.PLAYER_RESIZE, this.gridTemplate.bind(this));
  }

  /**
   * Shows and build the recommendation grid
   *
   * @param {Number} options - mediaList and recommendationId
   *
   * @returns {undefined}
   */
  mount({ mediaList, recommendationId }) {
    this.recommendationId = recommendationId;
    mediaList.forEach((itemInfo) => {
      const { urn, pendingSeek = undefined, standalone = undefined } = itemInfo;

      const item = new Item(this.player(), {
        itemInfo,
        recommendationId,
        clickHandler: ([e]) => {
          e.preventDefault();
          PlayerUtils.loadMedia(
            this.player(), {
              autoplay: true,
              pendingSeek,
              playbackSettings: {
                recommendationId,
              },
              standalone,
              urn,
            },
          );

          this.player().trigger({ type: SRGEvents.RECOMMENDATION_HIT, data: { urn } });
        },
      });
      this.addChild(item);
    });
    this.gridTemplate();
  }

  /**
   * Hides and remove all recommendation items
   *
   * @returns {undefined}
   */
  unmount() {
    super.unmount();
    this.removeChildren();
  }

  /**
   * Get the number of items to show
   *
   * @param {Number} gridSize - Grid size
   * @param {Number} maxItemsPerRow - Maximum items per row
   *
   * @returns {Number}
   */
  static itemsToShow(gridSize, maxItemsPerRow) {
    if (gridSize > MAX_ITEMS) {
      return MAX_ITEMS - (MAX_ITEMS % maxItemsPerRow) - 1;
    }

    return gridSize - (gridSize % maxItemsPerRow) - 1;
  }

  /**
   * Show and adapt the grid template depending the number of items.
   * - If there is no items to show the grid is hidden.
   * - Show/hide the items depending on the maximum items to show.
   * @returns {undefined}
   */
  gridTemplate() {
    const paddingBottom = parseInt(
      videojs.computedStyle(this.el(), 'paddingBottom'),
      10,
    );
    // As the width and height are fluid a minimum value has been defined
    const itemsPerRow = (this.width() / MIN_WIDTH) | 0;
    const itemsPerCol = ((this.height() - paddingBottom) / MIN_HEIGHT) | 0;
    // Fix the maximum items per row
    const maxItemsPerRow = itemsPerRow > MAX_ROWS ? MAX_ROWS : itemsPerRow;
    const grid = itemsPerRow * itemsPerCol;
    // Avoid empty spaces in the matrix
    this.itemCount = RecommendationGrid.itemsToShow(grid, maxItemsPerRow);

    this.el().style.gridTemplateColumns = '1fr '.repeat(maxItemsPerRow).trim();

    if (!this.itemCount) {
      this.hide();
    } else {
      this.show();
    }

    this.children().forEach((item, i) => {
      if (i <= this.itemCount) {
        item.show();
      } else {
        item.hide();
      }
    });

    this.triggerRecommendationDisplayed({
      recommendationId: this.recommendationId,
    });
  }

  displayedItems() {
    return this.children().filter(item => !item.hasClass('vjs-hidden'));
  }

  /**
   * Empty the endScreen element and remove the children.
   * @returns {undefined}
   */
  removeChildren() {
    videojs.dom.emptyEl(this.el());
    // remove all children at once
    this.children().length = 0;
  }

  /**
   * @event srgssr/recommendationDisplayed
   */
  triggerRecommendationDisplayed() {
    this.player().trigger(SRGEvents.RECOMMENDATION_DISPLAYED);
  }

  /**
   * Overrided methods
   */

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-recommendation-grid`;
  }

  createEl() {
    const className = this.buildCSSClass();
    const el = videojs.dom.createEl('div', {
      className,
    });
    return el;
  }
}

videojs.registerComponent('RecommendationGrid', RecommendationGrid);
export default RecommendationGrid;
