import videojs from 'video.js';
import Utils from '../../utils/Utils.js';

const VJSTimeTooltip = videojs.getComponent('TimeTooltip');

/**
 * Tooltip showing the wished position.
 * @ignore
 */
class TimeTooltip extends VJSTimeTooltip {
  update(seekBarRect, seekBarPoint, content) {
    super.update(seekBarRect, seekBarPoint, content);
  }

  updateTimeOnSeek(time) {
    videojs.dom.textContent(this.el(), time);
  }

  updateTime(seekBarRect, seekBarPoint, time, cb) {
    /* eslint-disable */
    if (this.rafId_) {
      this.cancelAnimationFrame(this.rafId_);
    }

    this.rafId_ = this.requestAnimationFrame(() => {
      let content = Utils.formatTime(time);

      if (this.player_.liveTracker && this.player_.liveTracker.isLive()) {
        const liveWindow = this.player_.liveTracker.liveWindow();
        const secondsBehind = liveWindow - (seekBarPoint * liveWindow);

        time = Utils.normalizeElapsedTimeSinceMidnight(secondsBehind);;
        content = Utils.formatTime(time, 6000);
      }

      this.update(seekBarRect, seekBarPoint, content);
      if (cb) {
        cb();
      }
    });
    /* eslint-enable */
  }
}

videojs.registerComponent('TimeTooltip', TimeTooltip);
export default TimeTooltip;
