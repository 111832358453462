import videojs from 'video.js';
import * as Events from '../../../../../utils/Events.js';
import SimpleListItem from './simple-list-item.js';


class ListScrollClickItem extends SimpleListItem {
  constructor(player, options) {
    super(player, options);

    this.clickComponent = options.scrollClickComponent;
    this.clickComponent.on(Events.MENU_VALUE_SCROLL, () => {
      this.scrollValue();
    });
  }

  scrollValue() {
    const { items } = this.options();
    const currentValue = this.getValue();

    items.every(({ value }, index, elements) => {
      if (value === currentValue) {
        const { value: newValue } = elements[(index + 1) % elements.length];
        this.dispatchValue(newValue);
        return false;
      }
      return true;
    });
  }
}

videojs.registerComponent('ListScrollClickItem', ListScrollClickItem);
export default ListScrollClickItem;
