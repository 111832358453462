import videojs from 'video.js';
import SharingSocialAction from './actions/sharing-social-action.js';
import SharingCopyLinkAction from './actions/sharing-copy-link-action.js';
import SharingEmbedAction from './actions/sharing-embed-action.js';
import SharingMailAction from './actions/sharing-mail-action.js';
import * as Events from '../../../utils/Events.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBoxWeb social sharing component
 * @ignore
 */
class SharingButtons extends Component {
  constructor(player, options) {
    super(player, options);

    const { socialSharing, socialSharingStrategy } = this.options();

    this.addChildren(socialSharingStrategy, socialSharing);

    this.player().on(Events.TIME_UPDATE, () => {
      if (socialSharingStrategy.isEmbeddable()) {
        this.getChild('embed').show();
      } else {
        this.getChild('embed').hide();
      }
    });
  }

  addChildren(socialSharingStrategy, socialSharing) {
    const socialButtons = [
      this.createActionButton(SharingSocialAction, {
        name: 'facebook',
        caption: this.localize('Facebook'),
        controlText: this.localize('Share on Facebook'),
        iconName: 'facebook',
        href: () => `https://www.facebook.com/sharer/sharer.php?u=${socialSharingStrategy.getShareURL()}`,
      }),
      this.createActionButton(SharingSocialAction, {
        name: 'x',
        caption: this.localize('X'),
        controlText: this.localize('Share on X'),
        iconName: 'x-twitter',
        href: () => `https://x.com/intent/tweet?text=${socialSharingStrategy.getShareTitle()}&url=${socialSharingStrategy.getShareURL()}&via=${socialSharing().twitterAccount}`,
      }),
      this.createActionButton(SharingSocialAction, {
        name: 'linkedin',
        caption: this.localize('LinkedIn'),
        controlText: this.localize('Share on Linkedin'),
        iconName: 'linkedin',
        href: () => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(socialSharingStrategy.getShareURL())}`,

      }),
      this.createActionButton(SharingSocialAction, {
        name: 'whatsapp',
        caption: this.localize('WhatsApp'),
        controlText: this.localize('Share on Whatsapp'),
        iconName: 'whatsapp',
        href: () => `https://api.whatsapp.com/send?text=${socialSharingStrategy.getShareURL()}`,
      }),
      this.createActionButton(SharingMailAction, {
        name: 'mail',
        caption: this.localize('Mail'),
        controlText: this.localize('Share by e-mail'),
        iconName: 'mail',
        href: () => `mailto:?Subject=${socialSharingStrategy.getShareTitle()}&body=${socialSharingStrategy.getShareURL()}`,
      }),
      this.createActionButton(SharingCopyLinkAction, {
        name: 'link',
        caption: this.localize('Copy link'),
        controlText: this.localize('Copy link'),
        iconName: 'link',
        href: () => socialSharingStrategy.getShareURL(),
      }),
      this.createActionButton(SharingEmbedAction, {
        name: 'embed',
        caption: this.localize('Embed'),
        controlText: this.localize('Embed'),
        iconName: 'embed',
        isHidden: !socialSharingStrategy.isEmbeddable(),
        href: () => socialSharingStrategy.getEmbedURL(),
      }),
    ];

    socialButtons.forEach((button) => {
      this.addChild(button);
    });
  }

  createActionButton(ActionButton, options) {
    const { socialSharingStrategy } = this.options();
    return new ActionButton(this.player(), { ...options, socialSharingStrategy });
  }

  createEl() {
    const el = super.createEl('div', {
      className: 'sharing-container__buttons',
    });

    return el;
  }
}

Component.registerComponent('SharingButtons', SharingButtons);
export default SharingButtons;
