import videojs from 'video.js';
import * as Events from './Events.js';
import PlayerUtils from './PlayerUtils.js';
import MediaComposition from '../dataProvider/model/MediaComposition.js';

const SRGSettingActions = {
  debug: (value, player) => {
    const debug = value ? 'debug' : 'error';

    player.log.level(debug);
    videojs.log.level(debug);
    videojs.Html5DashJS.hook('beforeinitialize', (_player, mediaPlayer) => {
      mediaPlayer.getProtectionController().setRobustnessLevel('SW_SECURE_CRYPTO');
    });
  },

  fillMode: (value, player) => {
    if (value) {
      player.fill(true);
    } else {
      player.fluid(true);
    }
  },

  playerFocus: (value, player) => {
    if (player
      && value instanceof Object
      && {}.hasOwnProperty
        .call(value, 'preventScroll')) {
      player.el().focus(value);
    }
  },

  hd: (_value, player) => {
    if (!player.hasStarted()) {
      return;
    }

    const { mediaComposition } = player.options().SRGProviders;

    PlayerUtils.loadMedia(player, {
      mediaComposition: MediaComposition.cleanCache(mediaComposition),
      pendingSeek: player.currentTime(),
      playbackSettings: {
        forcePlay: true,
      },
      urn: mediaComposition.segmentUrn || mediaComposition.chapterUrn,
    });
  },

  autoplay: (value, player) => {
    PlayerUtils.autoplay(player, value);
  },

  continuousPlayback: (value, player) => {
    player.trigger(Events.CONTINUOUS_PLAYBACK_CHANGE);
  },

  textTrackLanguage: (value, player) => {
    PlayerUtils.textTrackLanguage(value, player);
  },

  textTrackSizeLevel: (value, player) => {
    player.trigger(Events.TEXT_TRACK_SIZE_CHANGE);
  },

  audioTrackLanguage: (value, player) => {
    PlayerUtils.audioTrackLanguage(value, player);
  },

  playbackRate: (value, player) => {
    if (player.playbackRate() !== value && typeof value !== 'boolean') {
      player.playbackRate(value);
    }
  },
};


export default SRGSettingActions;
