import videojs from 'video.js';
import * as Events from '../../utils/Events.js';

import SupportedDevices from '../../utils/SupportedDevices.js';

const VJSTextTrackDisplay = videojs.getComponent('TextTrackDisplay');

const LINE_STYLE = {
  start: 0,
  center: 50,
  end: 100,
  auto: 100,
};

class TextTrackDisplay extends VJSTextTrackDisplay {
  /**
   *
   * @override
   */
  clearDisplay() {
    if (this.el().firstChild) {
      this.el().removeChild(this.el().firstChild);
    }
  }

  showingTrack() {
    const { tracks_: tracks } = this.player().textTracks();

    return tracks.find(track => track.mode === 'showing');
  }

  /**
   * Sets the text track font size and updates the displayed cue
   * @triggers Events.TEXT_TRACK_SIZE_CHANGE
   * @param {Number} fontPercent the size of the font in percent
   */
  setFontSize(fontPercent) {
    this.fontPercent = fontPercent;
    this.player().trigger({ type: Events.TEXT_TRACK_SIZE_CHANGE, data: fontPercent });
    this.updateDisplay();
  }

  /**
   *
   * @override
   */
  updateDisplay() {
    this.clearDisplay();

    const showingTrack = this.showingTrack();

    if (!showingTrack || !showingTrack.activeCues) {
      return;
    }

    const [cue] = TextTrackDisplay.cues(showingTrack);

    if (cue) {
      this.updateDisplayState(cue);
    }
  }

  /**
   * @param {Object} cue
   * @override
   */
  updateDisplayState(cue) {
    const {
      align, line = LINE_STYLE.end, size, text,
    } = cue;

    const cueWidth = TextTrackDisplay.getWidth(size);
    const cueTop = TextTrackDisplay.getTop(line);

    const [cueEl, cueContainerEl] = this.createStyledElements({
      align, cueTop, cueWidth, text,
    });

    cueContainerEl.appendChild(cueEl);
    this.el().appendChild(cueContainerEl);
  }

  get fontSize() {
    if (this.fontPercent) {
      return this.fontPercent;
    }

    // TODO find a better way to handle fixed font sizes
    return SupportedDevices.isTouchDevice ? 1 : 1.3;
  }

  /**
   * Creates cue styled elements with computed values
   * @param {Object} computedCue
   */
  createStyledElements({
    align, cueTop, cueWidth, text,
  }) {
    const cueEl = TextTrackDisplay.cueEl(text);
    const cueContainerEl = TextTrackDisplay.cueContainerEl(cueEl);

    cueContainerEl.style = `
    text-align: ${align};
    top: ${cueTop};
    transform: translateY(-${cueTop});`;

    cueEl.style = `
    width: ${cueWidth};
    font-size: ${this.fontSize}em;`;

    return [cueEl, cueContainerEl];
  }

  /**
   * Gets the position of the cue in percent
   * When the position is a string, the percent positon is determined with LINE_STYLE
   * When the position is a number, it is considered as the percent position value
   * If the value is incorrect, the default value set is "auto" from LINE_STYLE
   * @param {String|Number} position vertical position of the cue
   */
  static getTop(position) {
    let percentPosition = LINE_STYLE[position] !== undefined ? LINE_STYLE[position] : position;

    if (typeof percentPosition !== 'number' || percentPosition < 0) {
      percentPosition = LINE_STYLE.auto;
    }
    return `${percentPosition}%`;
  }

  static getWidth(size) {
    return size === 100 ? 'auto' : `${size}%`;
  }

  static cueContainerEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-text-track-display__container',
    });
  }

  static cueEl(text) {
    return videojs.dom.createEl('div', {
      innerHTML: text,
      className: 'vjs-text-track-display__cue',
    });
  }

  static cues(showingTrack) {
    const { cues_: cues = [] } = showingTrack.activeCues;

    return cues;
  }
}

videojs.registerComponent('TextTrackDisplay', TextTrackDisplay);
export default TextTrackDisplay;
