/**
 * All SRG Specific events. Those are sent through videojs trigger.
 *
 * @namespace {Object} SRGEvents
 */


const prefix = 'srgssr/';
export const AIRPLAY_PLAYBACK_STATE = `${prefix}airplayPlaybackState`;
export const AUTOPLAY_CHANGE = `${prefix}autoplayChange`;
export const ASPECT_RATIO = `${prefix}aspectRatio`;
export const BLOCKED_SEGMENT_SKIPPED = `${prefix}blockedSegmentSkipped`;
export const CHROMECAST_SCRIPT_LOADED = `${prefix}chromecastScriptLoaded`;
export const CONFIGURATION_CHANGE = `${prefix}configurationChange`;
export const CONTINUOUSPLAYBACK_CANCEL = `${prefix}continuousPlaybackCancel`;
export const CONTINUOUS_PLAYBACK_CHANGE = `${prefix}continuousPlaybackChange`;
export const CONTINUOUS_PLAYBACK_DISPLAYED = `${prefix}continuousPlaybackDisplayed`;
export const CONTINUOUS_PLAYBACK_INTEGRATION_CHANGE = `${prefix}continuousPlaybackIntegrationChange`;
export const CONTINUOUSPLAYBACK_DONE = `${prefix}continuousPlaybackDone`;
export const CONTINUOUS_PLAYBACK_HIT = `${prefix}continuousPlaybackHit`;
export const LAYOUT_BREAKPOINT_CHANGE = `${prefix}layoutBreakpointChange`;
export const MEDIACOMPOSITION_LOADED = `${prefix}mediaCompositionLoaded`;
export const MENU_VALUE_SCROLL = `${prefix}menuValueScroll`;
export const MENU_VALUE_UPDATED = `${prefix}menuValueUpdated`;
export const ON_ESC_PRESSED = `${prefix}onEscPressed`;
export const PREPARE_TO_PLAY = `${prefix}prepareToPlay`;
export const RECOMMENDATION_DISPLAYED = `${prefix}recommendationDisplayed`;
export const RECOMMENDATION_HIT = `${prefix}recommendationHit`;
export const SEGMENT_CHANGE = `${prefix}segmentChange`;
export const SEGMENT_SWITCHED = `${prefix}segmentSwitched`;
export const SETTING_CHANGE = `${prefix}settingChanged`;
export const SOCIAL_SHARING = `${prefix}socialSharing`;
export const SUBDIVISION_VISIBILITY_CHANGED = `${prefix}subdivisionVisibilityChanged`;
export const TEXT_TRACK_SIZE_CHANGE = `${prefix}textTrackSizeChange`;
