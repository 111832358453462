import videojs from 'video.js';
import SharingOption from './sharing-option.js';
import * as Events from '../../../utils/Events.js';
import SRGSocialSharing from '../../../utils/SRGSocialSharing.js';

const Component = videojs.getComponent('Component');

/**
 * LetterBoxWeb social sharing component
 * @ignore
 */
class SharingOptions extends Component {
  constructor(player, options) {
    super(player, options);

    const { SHARING_MODES } = SRGSocialSharing;

    this.addChildren(SHARING_MODES);

    this.player().on(Events.TIME_UPDATE, () => {
      if (this.options().socialSharingStrategy.getCurrentSubdivision()) {
        this.getChild(SHARING_MODES.CURRENT_SUBDIVISION).disable(false);
        return;
      }

      if (this.getChild(SHARING_MODES.CURRENT_SUBDIVISION).isChecked()) {
        this.getChild(SHARING_MODES.FULL_LENGTH).handleClick();
      }

      this.getChild(SHARING_MODES.CURRENT_SUBDIVISION).disable(true);
    });
  }

  addChildren(SHARING_MODES) {
    const socialOptions = [
      new SharingOption(this.player(), {
        name: SHARING_MODES.FULL_LENGTH,
        controlText: 'Share the episode',
        labelText: 'Episode',
        checked: true,
        clickHandler: () => {
          this.options().socialSharingStrategy.setMode(SHARING_MODES.FULL_LENGTH);
        },
      }),
      new SharingOption(this.player(), {
        name: SHARING_MODES.CURRENT_SUBDIVISION,
        controlText: 'Share the current subject',
        labelText: 'Current subject',
        disabled: true,
        isHidden: () => !this.player()
          .options()
          .SRGProviders.mediaComposition.getSubdivisions().length,
        clickHandler: () => {
          this.options()
            .socialSharingStrategy
            .setMode(SHARING_MODES.CURRENT_SUBDIVISION);
        },
      }),
      new SharingOption(this.player(), {
        name: SHARING_MODES.CURRENT_POSITION,
        controlText: 'Share at current position',
        labelText: 'Current position',
        clickHandler: () => {
          this.options()
            .socialSharingStrategy
            .setMode(SHARING_MODES.CURRENT_POSITION);
        },
      }),
    ];

    socialOptions.forEach((option) => { this.addChild(option); });
  }

  createEl() {
    const el = super.createEl('div', {
      className: 'sharing-container__options',
    });

    return el;
  }

  handleClick(event) {
    event.preventDefault();

    this.sharingContainer.show();
  }
}

Component.registerComponent('SharingOptions', SharingOptions);
export default SharingOptions;
