import videojs from 'video.js';
import SharingSocialAction from './sharing-social-action.js';

/**
 * LetterBoxWeb sharing button component to send link by mail
 * @ignore
 */
class SharingMailAction extends SharingSocialAction {
  triggerAction() {
    // No need to let the popup opened
    const popup = super.triggerAction();
    this.setTimeout(() => {
      popup.close();
    }, 500);
  }
}

videojs.registerComponent('SharingMailAction', SharingMailAction);
export default SharingMailAction;
