import videojs from 'video.js';
import { FIRST_PLAY, LOAD_START } from '../../utils/Events.js';

const Component = videojs.getComponent('Component');
const DELAY = 5000;
/**
 * Displays audio's image copyright.
 *
 * @ignore
 */
class ImageCopyright extends Component {
  constructor(player, options) {
    super(player, options);

    this.init();
  }

  /**
   * @override
   */
  buildCSSClass() {
    return `image-copyright ${super.buildCSSClass()}`;
  }

  /**
   * @override
   */
  createEl() {
    return super.createEl('div', {
      className: this.buildCSSClass(),
    });
  }

  /**
   * Check if an audio has an image copyright.
   *
   * @param {String} imageCopyright
   * @param {String} mediaType
   *
   * @returns {Boolean}
   */
  static hasImageCopyright(imageCopyright, mediaType) {
    return !!imageCopyright && mediaType === 'AUDIO';
  }

  /**
   * Hide the component at the first play when the timeout is reached.
   *
   * @listens FIRST_PLAY
   */
  hideAfterTimeout(delay = DELAY) {
    this.player().one(FIRST_PLAY, () => {
      setTimeout(this.hide.bind(this), delay);
    });
  }

  /**
   * Initialize component behavior.
   *
   * @listens LOAD_START
   */
  init() {
    this.player().on(LOAD_START, () => {
      const { imageCopyright, mediaType } = this.player().currentSource();

      this.reset();

      if (ImageCopyright.hasImageCopyright(imageCopyright, mediaType)) {
        this.updateInnerText(imageCopyright);
        this.show();
        this.hideAfterTimeout();
      }
    });
  }

  /**
   * Reset element inner text and hide component.
   */
  reset() {
    this.updateInnerText('');
    this.hide();
  }

  /**
   * Update element inner text.
   */
  updateInnerText(text) {
    this.el().innerText = text;
  }
}

videojs.registerComponent('ImageCopyright', ImageCopyright);
export default ImageCopyright;
