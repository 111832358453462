import videojs from 'video.js';
import playerUtils from '../utils/playerUtils.js';

const Button = videojs.getComponent('Button');
/**
 * @class ForwardButton
 * @description Create a custom control that allow to quiclky forward the media
 * @ignore
 */
class ForwardButton extends Button {
  constructor(player, options) {
    super(player, options);
    this.controlText(this.localize('30 seconds forward'));
  }

  buildCSSClass() {
    return `vjs-icon-forward vjs-control-seek ${super.buildCSSClass()}`;
  }

  handleClick() {
    playerUtils.seekFromNow(this.player(), 30);
  }
}

videojs.registerComponent('ForwardButton', ForwardButton);
export default ForwardButton;
