/**
 * @ignore
 */
class Media {
  constructor({
    show: {
      title: showTitle,
    } = {},
    urn,
    id,
    title,
    description,
    duration,
    imageUrl,
    imageTitle,
    vendor,
    mediaType,
  }) {
    this.urn = urn;
    this.id = id;
    this.title = title;
    this.showTitle = showTitle;
    this.description = description;
    this.duration = duration;
    this.imageUrl = imageUrl;
    this.imageTitle = imageTitle;
    this.vendor = vendor;
    this.mediaType = mediaType;
  }
}

export default Media;
