/**
 * @ignore
 * @class LocalStorage
 *
 * The LocalStorage class provides a simple way to manipulate the local storage.
 */
class LocalStorage {
  /**
   * Store a key value in a local storage identified by its namespace.
   * @param {String} storageName
   * @param {string} key
   * @param {*} value
   */
  static setItem(namespace, key, value) {
    if (!LocalStorage.storage) {
      return;
    }

    const storage = Object.assign({}, LocalStorage.getStorageByNamespace(namespace), {
      [key]: value,
    });

    LocalStorage.storage.setItem(namespace, JSON.stringify(storage));
  }


  /**
   * Get a local storage by its namespace.
   *
   * @param {String} name
   * @param {Object} defaultValue
   *
   * @returns {Object}
   */
  static getStorageByNamespace(namespace, defaultValue = {}) {
    if (!LocalStorage.storage) {
      return defaultValue;
    }

    const item = LocalStorage.storage.getItem(namespace);
    let itemValues;

    try {
      itemValues = LocalStorage.parseObject(item);
    } catch (error) {
      itemValues = defaultValue;
      LocalStorage.storage.setItem(namespace, JSON.stringify(itemValues));
    }

    return itemValues;
  }

  /**
   * Get a value from the local storage by its key.
   *
   * @param {String} storageNamespace
   * @param {String} key
   *
   * @returns {*}
   */
  static getItem(storageNamespace, key) {
    const storage = LocalStorage.getStorageByNamespace(storageNamespace);

    return storage[key];
  }

  /**
   * Parse strigyfied object.
   *
   * @param {String} stringyfiedObject
   *
   * @throws {Error}
   * @returns {Object}
   */
  static parseObject(stringyfiedObject) {
    const object = JSON.parse(stringyfiedObject);

    if (typeof object !== 'object' || object === null) {
      throw new Error('LocalStorage is not an Object');
    }

    return object;
  }

  /**
   * Get window.localStorage when available.
   */
  static get storage() {
    try {
      return localStorage;
    } catch (error) {
      return undefined;
    }
  }
}

export default LocalStorage;
